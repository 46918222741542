import React, { useCallback, useMemo, useState } from "react";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import theme from "./theme.json";
import { line } from "d3-shape";
import { Tooltip, TooltipItem } from "components/Dashboard/Tooltip";
import { useOrdinalColorScale } from "@nivo/colors";
import { BoxLegendSvg } from "@nivo/legends";
import {
  getMaxXValue,
  getMaxYValueWithHeadroom,
  getMinXValue,
} from "helpers/dashboardUtilities";

interface IData {
  id: string | number;
  [key: string]: any;
}

interface ScatterChartProps {
  id: string;
  keys: any[];
  attrs: any;
  settings: any;
  data: IData[];
  standardData: any[];
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
/**
 * Line Chart
 * @see https://nivo.rocks/line/
 * @returns
 */
export const ScatterChart = ({
  id,
  keys = [],
  attrs,
  settings,
  data = [],
  standardData = [],
}: ScatterChartProps) => {
  const [hiddenIds, setHiddenIds] = useState<string | number[]>([]);

  const colors = !!settings?.colors
    ? (Array.from(settings?.colors) as any)
    : null;
  const getColor = useOrdinalColorScale(colors, "serieId");

  const legendData = useMemo(
    () =>
      data.map((serie) => ({
        id: serie.id,
        label: keys.find((k) => k.id === serie.id).title,
        color: getColor({ serieId: serie.id }),
        hidden: hiddenIds.includes(serie.id as never),
      })),
    [data, getColor, hiddenIds, keys]
  );

  const filteredData = useMemo(
    () => data.filter((serie) => !hiddenIds.includes(serie.id as never)),
    [data, hiddenIds]
  );

  const StandardLineLayer = (props: any) => {
    const maxXValue = getMaxXValue(filteredData);
    const minXValue = getMinXValue(filteredData);

    const lineGenerator = line()
      .x((d: any) => props.xScale(d.x))
      .y((d: any) => props.yScale(d.y));

    let result = [];
    for (const [index, item] of standardData.entries()) {
      const itemData = item.data.map((d: any) => ({
        x: d.x,
        y: d.y,
      }));

      const filteredItemData = itemData.filter(
        (d: any) => d.x >= minXValue && d.x <= maxXValue
      );

      const standardsLine = lineGenerator(filteredItemData as any);
      if (!standardsLine) {
        continue;
      }

      result.push(
        <path
          key={item.id}
          d={standardsLine}
          fill="none"
          stroke={`rgba(0, 0, 0, ${0.3 + 0.3 * index})`}
          strokeDasharray="5,5"
          strokeWidth={2}
        />
      );
    }

    return <g>{result}</g>;
  };

  const toggleSerie = useCallback((id: any) => {
    setHiddenIds((state: any) =>
      state.indexOf(id) > -1
        ? state.filter((item: any) => item !== id)
        : [...state, id]
    );
  }, []);

  const Legends = () => {
    return (
      <BoxLegendSvg
        // key={i}
        anchor="top-left"
        direction="row"
        justify={false}
        translateX={-30}
        translateY={-30}
        itemsSpacing={0}
        itemDirection="left-to-right"
        itemWidth={125}
        itemHeight={20}
        // itemOpacity={0.85}
        symbolSize={14}
        symbolShape="circle"
        symbolBorderColor="rgba(0, 0, 0, .5)"
        toggleSerie={toggleSerie as any}
        effects={[
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1,
            },
          },
        ]}
        containerWidth={100}
        containerHeight={100}
        data={legendData}
      />
    );
  };

  return (
    <div className="relative h-64 w-full">
      <ResponsiveScatterPlot
        data={filteredData as any}
        theme={theme}
        animate={true}
        // blendMode="multiply"
        margin={{
          top: 50,
          right: 20,
          bottom: settings?.showBottomAxisLabel ? 60 : 40,
          left: settings?.showLeftAxisLabel ? 60 : 40,
        }}
        xScale={{
          type: "linear",
          min: "auto",
          max: "auto",
        }}
        yScale={{
          type: "linear",
          min: "auto",
          max: getMaxYValueWithHeadroom(filteredData),
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: settings?.showBottomAxisLabel
            ? attrs.axis?.bottom?.legend
            : undefined,
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 3,
          tickRotation: 0,
          legend: settings?.showLeftAxisLabel ? attrs.axis?.left?.legend : null,
          legendOffset: -50,
          legendPosition: "middle",
        }}
        colors={colors}
        useMesh={true}
        // legends={[
        //   {
        //     anchor: "top-left",
        //     direction: "row",
        //     justify: false,
        //     translateX: -30,
        //     translateY: -30,
        //     itemsSpacing: 0,
        //     itemDirection: "left-to-right",
        //     itemWidth: 125,
        //     itemHeight: 20,
        //     // itemOpacity: 0.85,
        //     symbolSize: 14,
        //     symbolShape: "circle",
        //     symbolBorderColor: "rgba(0, 0, 0, .5)",
        //     // toggleSerie: toggleSerie as any,
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemBackground: "rgba(0, 0, 0, .03)",
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
        tooltip={({ node }) => {
          const tooltipMetrics = (node.data as any).tooltip;
          const title = keys.find((k) => k.id === node.serieId).title;

          return (
            <Tooltip key={node.serieId} color={node.color}>
              <TooltipItem key={title} label={title} value={node.formattedY} />
              {Object.entries(tooltipMetrics)?.map(([key, value]) => (
                <TooltipItem key={key} label={key} value={value as any} />
              ))}
            </Tooltip>
          );
        }}
        layers={[
          "grid",
          "axes",
          StandardLineLayer,
          "nodes",
          "markers",
          "mesh",
          Legends,
          // "legends",
        ]}
      />
    </div>
  );
};
