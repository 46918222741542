import { isNull, isNullEmptyOrWhitespace } from "./stringUtilities";
import { DateTime } from "luxon";
import { API_TIMEZONE } from "constants.js";

/**
 * Convert and format a Unix Timestamp to date string | Date.
 * @param {number} unixTimestamp
 * @param {object}          [options]
 * @param {boolean}         [asDate]      - True/false should return result as date type. Default: `false`.
 * @param {boolean}         [includeTime] - If `asDate` is `false`. True/false should return result with time string.
 * @param {boolean}         [isUTC]       - True/false timestamp should be treated as UTC datetime.
 * @returns {string|Date}   The date string formatted as ISO string, yyyy-mm-dd hh:mm:ss. Or the `Date`, if `asDate` is true.
 */
export const dateFromUnixTimestamp = (
  unixTimestamp,
  { asDate = false, includeTime = true } = {}
) => {
  if (!unixTimestamp) return unixTimestamp;

  if (isNaN(unixTimestamp)) throw new Error("unixTimestamp must be a number");

  unixTimestamp = parseInt(unixTimestamp);

  if (unixTimestamp.toString().length === 10) unixTimestamp *= 1000; // convert 10 to 13 digit Unix timestamp

  const date = new Date(unixTimestamp);

  if (asDate) return date;

  return dateToISO(date.getTime(), {
    format: includeTime ? "dateTime" : "date",
  });
};

/**
 * Extract date from database date string containing Unix timestamp.
 * @param {string} date - String containing Unix timestamp in the format of "/Date(1606435200000)/"
 * @returns {string|Date}  The date string formatted as dd mmm yyyy hh:mm:ss.
 */
export const dateFromDotNetDateString = (
  date,
  { asDate = false, includeTime = true, normalise = true } = {}
) => {
  if (isNullEmptyOrWhitespace(date)) return null;
  let nTimeSpan;

  // DELETE temp test
  if (isDotNetDateString(date)) {
    const timeSpan = date.match(/(-)?[0-9]+/);
    if (!timeSpan) return date;

    nTimeSpan = parseInt(timeSpan[0]);
  } else {
    nTimeSpan = date;
  }

  // Normalise Database epoch to UTC time
  const newDate = new Date(nTimeSpan);
  nTimeSpan = normalise ? normaliseDate(newDate).getTime() : newDate.getTime();

  return dateFromUnixTimestamp(nTimeSpan, { asDate, includeTime });
};

export const isDotNetDateString = (_string) => {
  return /^\/Date\([0-9]+\)\/$/i.test(_string);
};

export const isUKDateString = (_string) => {
  return /^(?:[0-9]|[0-2][0-9]|3[0-1])\/(?:[1-9]|0[1-9]|1[0-2])\/(?:[0-9][0-9]|[1-2][0,9][0-9][0-9])$/.test(
    _string
  );
};

export const isSQLDateString = (_string) => {
  return /^(20\d{2}(?:-|\/)(?:(?:0[1-9])|(?:1[0-2]))(?:-|\/)(?:(?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))|20\d{2}(?:-|\/)(?:(?:0[1-9])|(?:1[0-2]))(?:-|\/)(?:(?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))(?:\s)(?:(?:[0-1][0-9])|(?:2[0-3])):(?:[0-5][0-9]):(?:[0-5][0-9]))$/.test(
    _string
  );
}

/**
 * Extract date from database date string containing Unix timestamp.
 * @param {string} date - String containing Unix timestamp in the format of "/Date(1606435200000)/"
 * @returns {string|Date}  The date string formatted as dd mmm yyyy hh:mm:ss.
 */
export const dateFromUKDateString = (
  date,
  { asDate = false, includeTime = true, normalise = true } = {}
) => {
  if (!date) return date;

  const _date = new Date(
    date.split("/")[2],
    date.split("/")[1] - 1,
    date.split("/")[0]
  );

  return dateFromUnixTimestamp(_date.getTime(), { asDate, includeTime });
};

/**
 * Convert Unix Timestamp/Epoch to .Net ticks.
 * @param {int|string} unixTimestamp - The Unix Timestamp to convert to .Net ticks.
 * @returns Numerical .Net ticks.
 */
export const epochToDotNetTicks = (unixTimestamp) => {
  if (!unixTimestamp) return unixTimestamp;

  unixTimestamp = isNaN(unixTimestamp)
    ? parseInt(unixTimestamp)
    : unixTimestamp;

  const epochTicks = 621355968000000000; // the number of .net ticks at the unix epoch (00:00 on 1 Jan 1970)
  const ticksPerMillisecond = 10000; // 10000 .net ticks per millisecond

  // const utcSecondsSinceEpoch = calcUTCSecondsSinceEpoch(unixTimestamp);

  return epochTicks + unixTimestamp * ticksPerMillisecond; // calculate the total number of .net ticks for your date
};

/**
 * Get the last X days including today.
 * @returns {Date[]}  Array of dates for the last X days including today.
 */
export const startOfLastXDays = (numDays = 20) => {
  const result = [];
  let date = DateTime.local().startOf("day");
  result.push(date.toJSDate());

  while (result.length - 1 < numDays) {
    date = date.minus({ days: 1 });
    result.push(date.toJSDate());
  }

  return result;
};

/**
 * Normalise date to UTC time.
 * @param {Date} date Date to normalise.
 * @returns Normalised date.
 */
export const normaliseDate = (date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  );
};

/**
 * Denormalise date from UTC to DB server timezone.
 * @param {Date} date Date to normalise.
 * @returns {Date} Normalised date.
 */
export const denormaliseDate = (date) => {
  let _date = convertTimezone(date, "Europe/London");
  return new Date(date.getTime() + _date.getTimezoneOffset() * 60000);
};

export const convertTimezone = (date, timezone) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: timezone,
    })
  );
};

/**
 * Convert date to locale string.
 * @param {Date|string} date - Date to convert to locale 
 * @param {object} options  - Options to format the date.
 * @returns {string}  The date string formatted as dd mmm yyyy hh:mm:ss.
 */
export const dateToString = (
  date,
  { includeWeekday, includeTime, dateFormat } = {
    includeWeekday: false,
    includeTime: false,
    dateFormat: "numeric",
  }
) => {
  if (isNull(date)) return null;
  let result;

  if (date instanceof Date === false) {
    date = new Date(date);
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  result = `${padNumber(date.getDate())} ${
    months[date.getMonth()]
  } ${date.getFullYear()}`;

  if (includeWeekday) {
    result = `${days[date.getDay()]}, ${result}`;
  }

  if (includeTime) {
    result = `${result} ${padNumber(date.getHours())}:${padNumber(
      date.getMinutes()
    )}:${padNumber(date.getSeconds())}`;
  }

  return result;
};

/**
 * Format UnixTimestamp to DB format.
 * @param {string|number} unixTimestamp - UnixTimestamp
 * @param {{ format: string }}  - date|dateTime format option
 * @returns {string}  - Date formatted as yyyy-mm-dd
 */
export const dateToISO = (unixTimestamp, { format = "date" } = {}) => {
  if (!unixTimestamp) return unixTimestamp;

  const date = dateFromUnixTimestamp(unixTimestamp, {
    asDate: true,
    includeTime: false,
  });

  const year = date.getFullYear();
  const month = padNumber(date.getMonth() + 1, 2);
  const day = padNumber(date.getDate(), 2);

  let result = `${year}-${month}-${day}`;
  if (format === "dateTime") {
    const hours = padNumber(date.getHours(), 2);
    const minutes = padNumber(date.getMinutes(), 2);
    const seconds = padNumber(date.getSeconds(), 2);
    result += ` ${hours}:${minutes}:${seconds}`;
  }

  return result;
};

/**
 * Calculate the bird age in weekly decimal.
 * @param {Date|undefined} dateHatched
 * @param {number} ageAtPlacement
 * @param {Date?} dateNow
 * @returns {float} The bird age in weekly decimal. I.e. 10.1 = week 10, week day 1
 */
export const calculateBirdAge = (dateHatched, offsetDays = 0, dateNow) => {
  if (dateHatched === undefined) return { days: 0, weeks: 0 };
  if (!dateNow) dateNow = localDate();
  const daysSinceHatched = dateDiffInDays(dateHatched, dateNow);
  const birdAgeDays = Math.ceil(daysSinceHatched + offsetDays);
  const birdAgeWeeks = Math.floor(birdAgeDays / 7);
  const birdAgeWeeksModulo = birdAgeDays % 7;

  return { days: birdAgeDays, weeks: birdAgeWeeks + birdAgeWeeksModulo / 10 };
};

//#region Private

/**
 * Add specified number of pad characters to the string. E.g. 00002
 * @param {number} num
 * @param {number} numOfDigits
 * @param {string} padChar
 * @returns {string}  num with padded characters. E.g. 00002
 */
export function padNumber(num, numOfDigits = 2, padChar = "0") {
  if (isNaN(num)) return num;
  return num.toString().padStart(numOfDigits, padChar);
}

/**
 * Calculate the number of days between two dates.
 * @param {Date|undefined} date1
 * @param {Date|undefined} date2
 * @returns {float} The number of days between two dates.
 */
export function dateDiffInDays(date1, date2) {
  if (isNull(date1) || isNull(date2)) return;
  const _date1 = DateTime.fromISO(date1.toISOString());
  const _date2 = DateTime.fromISO(date2.toISOString());
  // round to the nearest whole number
  return _date2.diff(DateTime.fromISO(_date1), "days").toObject().days;
}

// /**
//  * Fetch the number of seconds since the epoch in UTC.
//  * @param {Date} datetime - Local date & time
//  * @returns The number of seconds since the epoch in UTC.
//  */
// function calcUTCSecondsSinceEpoch(datetime) {
//   if (!datetime) return datetime;

//   const utcMilllisecondsSinceEpoch =
//     datetime.getTime() + datetime.getTimezoneOffset() * 60 * 1000;
//   return Math.round(utcMilllisecondsSinceEpoch);
// }

//#endregion

/**
 * Get the Date of the previous day.
 * @param {Date} todays date
 * @returns {Date}  The date of the previous day.
 */
export function getPrevDay(date) {
  if (!date) return null;

  return DateTime.fromJSDate(date).minus({ days: 1 }).toJSDate();
}

/**
 * Gets the day of the year
 * @param {*} date
 * @returns {Number}  The day of the year
 */
export function dayOfYear(date) {
  return DateTime.fromJSDate(date).ordinal;
}

/**
 * Get the date for a given day of year
 * @param {Number} dayOfYear Integer for day of the year
 * @param {Number} year  Year to calculate day from
 * @returns {Date} The date object
 */
export function dateFromDayOfYear(dayOfYear, { year } = {}) {
  let result = DateTime.local();
  if (year) {
    result = result.set({ year: year });
  }
  result = result.startOf("year").plus({ days: dayOfYear - 1 });
  return result.toJSDate();
}

/**
 * Calculate the start of the current week.
 * @param {Date} date The date object
 * @param {{ offset: Number }} param1 Options
 * @returns {Date}
 */
export function startOfWeekFromDate(date, { offset = 0 } = {}) {
  return DateTime.fromJSDate(date)
    .startOf("week")
    .plus({ days: offset })
    .toJSDate();
}

/**
 * Returns a clone date object with a specified amount of time added.
 * @param {Date} date
 * @param {number} offset
 * @param {DurationLike} unit
 * @returns {Date}
 */
export function dateAdd(date, offset, unit) {
  return DateTime.fromJSDate(date)
    .plus({ [unit]: offset })
    .toJSDate();
}

/**
 * Returns a cloned date object with a specified amount of time subtracted.
 * @param {Date} date
 * @param {number} offset
 * @param {DurationLike} unit
 * @returns {Date}
 */
export function dateSubtract(date, offset, unit) {
  return DateTime.fromJSDate(date)
    .minus({ [unit]: offset })
    .toJSDate();
}

/**
 * Returns a clone date object with a set unit of time.
 * @param {Date} date
 * @param {DateObjectUnits} values
 * @returns {Date}
 */
export function dateSet(date, values) {
  return DateTime.fromJSDate(date).set(values).toJSDate();
}

/**
 * Calculate the elapse time between two dates.
 * @param {Date} date1
 * @param {Date} date2
 * @returns {number} The number of days between two dates.
 */
export function dateDiffInMilliseconds(
  date1,
  date2,
  { format = "string" } = {}
) {
  if (isNull(date1) || isNull(date2)) return;
  // round to the nearest whole number
  let _result = Math.ceil(date1.getTime() - date2.getTime());

  if (format === "string") {
    _result = timeSinceAccurate(_result);
  }

  return _result;
}

// /**
//  * Format milliseconds to time since string
//  * @param {number} milliseconds The number of milliseconds to convert to time since string.
//  * @returns {string}  A string formatted with time since
//  */
// export function timeSince(milliseconds) {
//   const _seconds = milliseconds / 1000;

//   let interval = Math.floor(_seconds / 31536000)

//   if (interval > 1) {
//     return interval + ' years'
//   }
//   interval = Math.floor(_seconds / 2592000)
//   if (interval > 1) {
//     return interval + ' months'
//   }
//   interval = Math.floor(_seconds / 86400)
//   if (interval > 1) {
//     return interval + ' days'
//   }
//   interval = Math.floor(_seconds / 3600)
//   if (interval > 1) {
//     return interval + ' hours'
//   }
//   interval = Math.floor(_seconds / 60)
//   if (interval > 1) {
//     return interval + ' minutes'
//   }
//   return Math.floor(_seconds) + ' seconds'
// }

/**
 * Format milliseconds to accurate time since string.
 * @param {number} milliseconds
 * @returns {string}  A string containing the number of days, hours, minutes, seconds since.
 */
function timeSinceAccurate(milliseconds) {
  const result = [];
  const seconds = Number(milliseconds) / 1000;
  const absSeconds = Math.abs(seconds);
  const dayCount = Math.floor(absSeconds / (3600 * 24));
  const hourCount = Math.floor((absSeconds % (3600 * 24)) / 3600);
  const minuteCount = Math.floor((absSeconds % 3600) / 60);
  const secondCount = Math.floor(absSeconds % 60);

  if (dayCount > 0) {
    result.push(`${dayCount} ${dayCount === 1 ? "day" : "days"}`);
  }
  if (hourCount > 0) {
    result.push(`${hourCount} ${hourCount === 1 ? "hour" : "hours"}`);
  }
  if (minuteCount > 0) {
    result.push(`${minuteCount} ${minuteCount === 1 ? "minute" : "minutes"}`);
  }
  if (secondCount > 0) {
    result.push(`${secondCount} ${secondCount === 1 ? "second" : "seconds"}`);
  }

  return `${seconds < 0 ? "- " : ""}${result.join(", ")}`;
}

//#region new conversions

/**
 * Create a local DateTime
 * @param {number?} year The calendar year. If omitted (as in, call local() with no arguments), the current time will be used
 * @param {number?} month The month, 1-indexed
 * @param {number?} day The day of the month, 1-indexed
 * @param {number?} hour  The hour of the day, in 24-hour time
 * @param {number?} minute  The minute of the hour, meaning a number between 0 and 59
 * @param {number?} second  The second of the minute, meaning a number between 0 and 59
 * @param {DateTimeJSOptions} opts The millisecond of the second, meaning a number between 0 and 999
 * @returns {Date}
 */
export function localDate(...params) {
  return DateTime.local(...params).toJSDate();
}

/**
 * Local date from DB Unix string, e.g. "/Date(1606435200000)/"
 * @param {string} dbUnixTimestamp
 * @returns
 */
export function localDateFromDBUnix(dbUnixTimestamp) {
  if (isNullEmptyOrWhitespace(dbUnixTimestamp)) return;

  // Extract timestamp from DB format "/Date(1606435200000)/"
  const timeSpan = dbUnixTimestamp.match(/(-)?[0-9]+/);

  return localDateFromUnix(parseInt(timeSpan[0]));
}

/**
 * Local date from Unix timestamp, e.g. 1606435200000
 * @param {string|number|null|undefined} unixTimestamp
 * @returns
 */
export function localDateFromUnix(unixTimestamp) {
  if (isNullEmptyOrWhitespace(unixTimestamp)) return;
  unixTimestamp = parseInt(unixTimestamp);

  let result = DateTime.fromMillis(unixTimestamp);

  return result.toJSDate();
}

/**
 * Local date from string format
 * @param {*} stringDate
 * @param {*} format
 * @see format https://moment.github.io/luxon/#/parsing?id=table-of-tokens
 * @returns
 */
export function localDateFromFormat(stringDate, format = "") {
  if (isNullEmptyOrWhitespace(stringDate)) return;

  return DateTime.fromFormat(stringDate, format).toJSDate();
}

export function localDateFromSQL(string) {
  if (isNullEmptyOrWhitespace(string)) return;

  return DateTime.fromSQL(string).toJSDate();
}

/**
 * 
 * @param {Date|undefined} date 
 * @param {unknown} param1 
 * @returns 
 */
export function localDateToSQL(
  date,
  { convertTimezoneToServer = false, ...options } = {}
) {
  if (isNull(date)) return;

  let result = DateTime.fromJSDate(date);

  if (convertTimezoneToServer) {
    result = result.setZone(API_TIMEZONE);
  }

  return result.toSQL({ ...options });
}

export function localDateToSQLDate(date) {
  if (isNull(date)) return;

  return DateTime.fromJSDate(date).toSQLDate();
}

/**
 * Date to string format.
 * @param {*} date
 * @param {*} format Format date to string. Default: yyyy-MM-dd HH:mm:ss
 * @returns
 */
export function localDateToFormat(date, format = "yyyy-MM-dd HH:mm:ss") {
  if (isNull(date)) return;

  return DateTime.fromJSDate(date).toFormat(format);
}

/**
 * Calculate the start of {unit}.
 * @param {Date} date The date object
 * @param {DateTimeUnit}  unit The unit to go to the beginning of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
 * @returns {Date}
 */
export function startOfFromDate(date, unit) {
  return DateTime.fromJSDate(date).startOf(unit).toJSDate();
}

/**
 * Calculate the end of {unit}.
 * @param {Date} date The date object
 * @param {DateTimeUnit}  unit The unit to go to the end of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
 * @returns {Date}
 */
 export function endOfFromDate(date, unit) {
  return DateTime.fromJSDate(date).endOf(unit).toJSDate();
}

/**
 * Ideally this would not be needed but because the server is not running from UTC
 * and has a number of different date formats we have no choice.
 * Native: Server date string as local date, no conversions. Not typically used but contains the most truthful local conversion of the returned server value.
 * Normalised: Server date converted to same time on local device. Heavily used throughout to localise the app to the users timezone.
 *  E.g. Server: 2022-01-20 17:51:22 (Europe/London), Server: 2022-01-20 17:51:22 (Eastern Standard TIme)
 * Localised: Local date equivalent of server date. Useful when comparing local dates to return server dates. E.g. Localised: 2022-01-20 2022 19:00:00 GMT-0500 (Eastern Standard Time), Server: 2022-01-21 00:00:00 (Europe/London)
 * @important If the server timezone changes we would need to change this method
 * @returns {{ native: Date, normalised: Date, localised: Date, dateString: String, timeString: String }}  Date string in SQL format. I.e. YYYY-MM-DD hh:mm:ss
 */
export function sqlDateObjectFromServerTZ(serverDateString) {
  if (isNullEmptyOrWhitespace(serverDateString)) return;

  let newDate, nativeDate;

  if (isDotNetDateString(serverDateString)) {
    // /Date(1234567891234)/
    const timeSpan = parseInt(serverDateString.match(/(-)?[0-9]+/)[0]);

    // Get local date from epoch
    // and convert to server timezone
    nativeDate = DateTime.fromMillis(timeSpan);
    newDate = nativeDate.setZone(API_TIMEZONE);
    // Convert timezone back to local from string to gain/lose offset
    newDate = DateTime.fromSQL(
      newDate.toSQL({ includeZone: false, includeOffset: false }),
      { zone: "local", setZone: true }
    );
  } else if (isUKDateString(serverDateString)) {
    // 20/12/2021
    nativeDate = DateTime.fromFormat(serverDateString, "dd/MM/yyyy");
    newDate = nativeDate;
  } else {
    // 2021-12-20 00:00:00
    nativeDate = DateTime.fromSQL(serverDateString);
    newDate = nativeDate;
  }

  const localisedDate = DateTime.fromSQL(
    newDate.toSQL({ includeZone: false, includeOffset: false }),
    { zone: API_TIMEZONE, setZone: true }
  );

  return {
    native: nativeDate.toJSDate(),
    normalised: normaliseServerToLocalDate(newDate.toJSDate()),
    localised: localisedDate.toJSDate(),
    dateString: newDate.setZone(API_TIMEZONE).toSQLDate(),
    timeString: newDate
      .setZone(API_TIMEZONE)
      .toSQLTime({ includeZone: false, includeOffset: false }),
  };
}

/**
 * Normalise server date to local date
 * @param {Date} localDate
 * @returns {Date}
 */
export function normaliseServerToLocalDate(localDate) {
  if (isNull(localDate)) return;

  // Get DateTime object
  const dateTime = DateTime.fromJSDate(localDate);

  // Set to server zone
  const serverDate = dateTime
    .setZone(API_TIMEZONE)
    .toSQL({ includeZone: true, includeOffset: true });

  // Convert back to local zone to gain the offset
  const newLocalDate = DateTime.fromSQL(serverDate, {
    zone: "local",
    setZone: true,
  });

  return newLocalDate.toJSDate();
}

/**
 * Normalise date object to 
 * @param {Date} date 
 * @returns 
 */
export function dateNormalise(date) {
  console.log(date, DateTime(date).setZone(API_TIMEZONE).toJSDate())
  return DateTime(date).setZone(API_TIMEZONE).toJSDate()
}

//#endregion
