import { PlusCircleIcon } from "assets/icons";
import classNames from "classnames";
import { Modal, Select } from "components/core";
import { useState } from "react";

export default function AddChart({ onAddChart, className, chartTypes, ...other }) {
  const [open, setOpen] = useState(false);
  const [selectedChartType, setSelectedChartType] = useState(null);

  function handleClickAddChart() {
    setOpen(true);
  }

  function handleSaveChart() {
    onAddChart(selectedChartType);
    setOpen(false);
  }

  return (
    <>
      <div
        {...other}
        className={classNames(
          className,
          "flex justify-center items-center h-12 w-full rounded-md cursor-pointer border-2 border-dashed border-gray-200 text-gray-400 hover:border-primary hover:text-primary"
        )}
        onClick={handleClickAddChart}
      >
        <PlusCircleIcon className="h-6 w-6 mr-2" aria-hidden="true" /> Add Chart
      </div>
      <Modal
        title="Add a chart"
        open={open}
        onOpen={(_open) => setOpen(_open)}
        onSave={() => handleSaveChart()}
      >
        <div className="py-2">
          <Select
            id="chart-type-select"
            label="Chart Type"
            labelPosition="inset"
            setValue={setSelectedChartType}
            value={selectedChartType}
            listOptions={chartTypes.map((item) => ({
              Id: item.id,
              Text: item.title,
              Value: item.id,
            }))}
          />
        </div>
      </Modal>
    </>
  );
}
