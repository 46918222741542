import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import RouteElementWrapper from './RouteElementWrapper';
import LoginPage from "pages/user/login";
import HomePage from "pages/index";
import Error404Page from "pages/Error404";
import RequireAuthRouteElementWrapper from './RequireAuthRouteElementWrapper';
import { FormListPage } from './FormListPage';
import { FormPage } from './FormPage';
import DebugPage from "pages/Debug";
import Error500 from "pages/Error500";
import RootLayout from './RootLayout';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route 
      path="/" 
      element={<RootLayout />}
      errorElement={<Route path="500" element={<RouteElementWrapper><Error500 /></RouteElementWrapper>} />}
    >
      <Route
        path="/user/login"
        element={
          <RouteElementWrapper>
            <LoginPage />
          </RouteElementWrapper>
        }
      />
      <Route
        path="/"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <HomePage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormListPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormListPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId/:view"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId/:view/:id"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId/:view/:parentFormId/:parentId"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/:module/:moduleFeatureGroup/:formId/:view/:id/:parentFormId/:parentId"
        element={
          <RouteElementWrapper>
            <RequireAuthRouteElementWrapper>
              <FormPage />
            </RequireAuthRouteElementWrapper>
          </RouteElementWrapper>
        }
      />
      <Route
        path="/debug"
        element={
          <RequireAuthRouteElementWrapper>
            <DebugPage />
          </RequireAuthRouteElementWrapper>
        }
      />
      <Route path="500" element={<RouteElementWrapper><Error500 /></RouteElementWrapper>} />
      <Route path="*" element={<RouteElementWrapper><Error404Page /></RouteElementWrapper>} />
    </Route>
  )
);

export default function CustomRoutes() {
  return (
    <RouterProvider router={router} />
  )
}