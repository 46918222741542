import { DATA_STATUS } from "constants.js";
import { dateDiffInDays } from "helpers/dateUtilities";

export default function ListItemStatus({
  dataStatus,
  date,
  dateToday,
  loaded = true,
}) {
  if (!loaded) {
    return <div className="animate-pulse h-2 w-20 bg-gray-300 rounded"></div>;
  }

  if (
    dataStatus === DATA_STATUS.PENDING_INCOMPLETE ||
    dataStatus === DATA_STATUS.PENDING_COMPLETE
  ) {
    return <span className="text-warning-500">Pending</span>;
  } else if (dataStatus === DATA_STATUS.COMPLETE) {
    return <span className="text-success-500">Complete</span>;
  } else if (dateDiffInDays(date, dateToday) >= 1) {
    return <span className="text-danger-500">Overdue</span>;
  } else {
    return <span className="text-gray-500">Incomplete</span>;
  }
}
