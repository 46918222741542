import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Input, Button } from "components/core";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  CalendarIcon,
  XIcon,
} from "assets/icons";
import {
  dateToString,
  localDate,
  dateSubtract,
  dateAdd,
  dateSet,
  localDateToSQLDate,
  localDateFromSQL,
  endOfFromDate,
  startOfFromDate,
} from "helpers/dateUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { usePopper } from "react-popper";
import { Popover } from "@headlessui/react";
import useCalcTrigger from "hooks/useCalcTrigger";

export function Datepicker({
  label,
  labelPosition,
  value,
  setValue,
  setValid,
  required = true,
  validate,
  startDate,
  endDate,
  render,
  defaultValue,
  disableCalcTrigger,
}) {
  useCalcTrigger(value, setValue, disableCalcTrigger);

  const [focusedDate, setFocusedDate] = useState(localDate());
  const [blankdays, setBlankdays] = useState([]);
  const [no_of_days, setNo_of_days] = useState([]);
  const wrapperRef = useRef(null);

  let [referenceElement, setReferenceElement] = useState(undefined);
  let [popperElement, setPopperElement] = useState(undefined);
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  const MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  //#region Callbacks

  /**
   * Handle click on prev month button
   */
  const handleClickPrevMonth = () => {
    setFocusedDate((prevState) => {
      const newfocusedDate = dateSubtract(prevState, 1, "months");
      if (newfocusedDate.toString() === prevState.toString()) return prevState; // Avoid updating state

      return newfocusedDate;
    });
  };

  /**
   * Handle click on prev month button
   */
  const handleClickNextMonth = () => {
    setFocusedDate((prevState) => {
      const newfocusedDate = dateAdd(prevState, 1, "months");
      if (newfocusedDate.toString() === prevState.toString()) return prevState; // Avoid updating state

      return newfocusedDate;
    });
  };

  /**
   * Handle click on date
   * @param {number} date Selected day of the currently focused month
   */
  const handleClickDate = (date, close) => {
    if (!isWithinDateRange(date)) return;

    const selectedDate = getDateValue(date);
    setValue(localDateToSQLDate(selectedDate));
    close();
  };

  //#endregion

  //#region Side-effects

  /**
   * Set num of days in month
   */
  useEffect(() => {
    getNoOfDays(focusedDate);
  }, [focusedDate]);

  //#endregion

  /**
   * Does date match the current calendar date.
   * @param {Date} date
   * @returns {boolean} True/false the date is today
   */
  const isToday = (date) => {
    const currentDateTime = localDate();
    const testDate = dateSet(focusedDate, {
      day: date,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return localDateToSQLDate(currentDateTime) === localDateToSQLDate(testDate)
      ? true
      : false;
  };

  /**
   * Does date match the current selected date.
   * @param {Date} date
   * @returns {boolean} True/false the date is today
   */
  const isSelectedDate = (date) => {
    if (!value) return false;

    const testDate = dateSet(focusedDate, {
      day: date,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return value === localDateToSQLDate(testDate) ? true : false;
  };

  /**
   * Convert day to Date and set Date state
   * @param {number} date
   */
  const getDateValue = (date) => {
    const newSelectedDate = dateSet(focusedDate, {
      day: date,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return newSelectedDate;
  };

  const getNoOfDays = (date) => {
    let daysInMonth = endOfFromDate(date, "month").getDate();

    // find where to start calendar day of week
    let dayOfWeek = startOfFromDate(date, "month").getDay();
    let blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setBlankdays(blankdaysArray);
    setNo_of_days(daysArray);
  };

  /**
   * True/false date is within start and end date range
   * @param {number} date - Numerical day of the month
   * @returns
   */
  const isWithinDateRange = (date) => {
    let _inRange = true;

    const testDate = localDate(
      focusedDate.getFullYear(),
      focusedDate.getMonth() + 1,
      date,
      0,
      0,
      0,
      0
    );

    if (startDate && testDate.getTime() < startDate.getTime()) {
      _inRange = false;
    }

    if (endDate && testDate.getTime() > endDate.getTime()) {
      _inRange = false;
    }

    return _inRange;
  };

  /**
   * Get day classes
   */
  const getDayClasses = (date) => {
    const classes = [
      "text-center text-sm rounded-full w-10 h-10 flex justify-center items-center transition ease-in-out duration-100",
    ];

    if (isWithinDateRange(date)) {
      classes.push("cursor-pointer hover:bg-primary-lighter");
    } else {
      classes.push("text-gray-300");
    }

    if (isSelectedDate(date)) {
      classes.push("bg-primary text-white");
    }

    if (isToday(date)) {
      classes.push("border border-primary");
    }

    return classes.join(" ");
  };

  /**
   * Format value state as DD MMM YYYY
   */
  function renderDisplayDate() {
    if (isNullEmptyOrWhitespace(value)) return value;

    return dateToString(localDateFromSQL(value));
  }

  /**
   * Format value state as DD MMM YYYY
   */
   function renderDate() {
    if (isNullEmptyOrWhitespace(value) || value.length <= 10) return value;

    return value?.slice(0, 10);
  }

  // Prevent dom element rendering
  if (render === false) {
    // Render 'Input' to trigger validation
    return (
      <Input
        id="date-input"
        type="hidden"
        name="date"
        setValue={setValue}
        setValid={setValid}
        required={required}
        value={value}
        disableCalcTrigger={true}
      />
    );
  }

  const portalContainer = document.querySelector("#root");

  return (
    <div ref={wrapperRef}>
      <div className="relative cursor-pointer">
        <Popover>
          {({ open }) => (
            <>
              <Popover.Button as="span" ref={setReferenceElement}>
                <Input
                  id="date-input"
                  type="hidden"
                  name="date"
                  setValue={setValue}
                  setValid={setValid}
                  required={required}
                  value={renderDate()}
                  defaultValue={defaultValue}
                  disableCalcTrigger={true}
                />
                <div className="relative">
                  <Input
                    id="select-date-input"
                    type="text"
                    label={label}
                    labelPosition={labelPosition}
                    required={required}
                    readOnly
                    placeholder="Select date"
                    value={renderDisplayDate()}
                    style={{ cursor: "inherit" }}
                    addonRight={
                      <CalendarIcon className="h-6 w-6 text-gray-400" />
                    }
                    disableCalcTrigger={true}
                  />
                </div>
              </Popover.Button>
              {!isNullEmptyOrWhitespace(value) && (
                <div
                  className="absolute right-10 bottom-3.5 cursor-pointer group"
                  onClick={() => setValue("")}
                >
                  <XIcon
                    className="h-5 w-5 text-gray-400 group-hover:text-primary"
                    title="clear value"
                    aria-hidden="true"
                  />
                </div>
              )}
              {open && portalContainer &&
                ReactDOM.createPortal(
                  <Popover.Panel
                    ref={(ref) => setPopperElement(ref)}
                    style={styles.popper}
                    className="mt-3 px-2 w-screen max-w-xs sm:px-0 z-10"
                    {...attributes.popper}
                    static
                  >
                    {({ close }) => (
                      <div
                        className={`bg-white rounded-lg shadow-md border p-4 absolute top-0 left-0 z-20 w-80`}
                        // style={{ width: "17rem" }}
                      >
                        <div className="flex justify-between items-center mb-2">
                          <div>
                            <span className="text-lg font-bold text-gray-800">
                              {MONTH_NAMES[focusedDate.getMonth()]}
                            </span>
                            <span className="ml-1 text-lg text-gray-600 font-normal">
                              {focusedDate.getFullYear()}
                            </span>
                          </div>
                          <div>
                            <Button
                              type="button"
                              theme="text"
                              size="small"
                              className={`transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full`}
                              // disabled={focusedDate.getMonth() === 0 ? true : false}
                              onClick={handleClickPrevMonth}
                              aria-label="previous month"
                            >
                              <ChevronLeftIcon className="h-6 w-6 text-gray-500 inline-flex" />
                            </Button>
                            <Button
                              type="button"
                              theme="text"
                              size="small"
                              className={`transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full`}
                              // disabled={focusedDate.getMonth() === 11 ? true : false}
                              onClick={handleClickNextMonth}
                              aria-label="next month"
                            >
                              <ChevronRightIcon className="h-6 w-6 text-gray-500 inline-flex" />
                            </Button>
                          </div>
                        </div>

                        {/* Render day names */}
                        <div className="flex flex-wrap mb-3 -mx-1">
                          {DAYS.map((day, index) => (
                            <div
                              key={index}
                              style={{ width: "14.26%" }}
                              className="px-1"
                            >
                              <div className="text-gray-800 font-medium text-center text-xs">
                                {day}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Render dates in month */}
                        <div className="flex flex-wrap -mx-1">
                          {blankdays.map((day, index) => (
                            <div
                              key={index}
                              style={{ width: "14.28%" }}
                              className="text-center border p-1 border-transparent text-sm"
                            ></div>
                          ))}
                          {no_of_days.map((date, index) => (
                            <div
                              key={index}
                              style={{ width: "14.28%" }}
                              className="px-1 mb-1"
                            >
                              <div
                                data-cy={`date-${date}`}
                                onClick={() => handleClickDate(date, close)}
                                className={getDayClasses(date)}
                              >
                                {date}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Popover.Panel>,
                  document.querySelector("#root")
                )}
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}

Datepicker.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(["top", "left", "inset"]),
  required: PropTypes.bool,
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  value: PropTypes.string,
  render: PropTypes.bool,
};
