import React from "react";
import PropTypes from "prop-types";

const Addon = ({ children, position, theme = "", interactable = false }) => {
  const classes = [
    "absolute inset-y-0 flex items-center text-xs",
  ];

  if (!interactable) {
    // Disallow clicking on icon
    classes.push("pointer-events-none");
  }

  // Position
  if (position === "left") {
    classes.push("left-0 rounded-l-md");
  } else {
    classes.push("right-0 rounded-r-md");
  }

  // Theme
  if (theme === "gray") {
    classes.push("bg-gray-50 border border-gray-300");
  }

  return (
    <div className={classes.join(" ")}>
      <span className="text-gray-500 px-3">{children}</span>
    </div>
  );
};

Addon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  position: PropTypes.oneOf(["left", "right"]),
  theme: PropTypes.oneOf(["gray"]),
};

export default Addon;
