import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Datepicker,
  MultiSelect,
  TableChart,
} from "components/core";
import { AppDataContext, useFarms } from "context/AppDataProvider";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { dateToString, localDateFromSQL } from "helpers/dateUtilities";
import classNames from "classnames";
import { XIcon } from "assets/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { AUDIT_STATUS, LOADED_STATUS } from "constants.js";
import DescriptionListSkeleton from "components/core/Lists/DescriptionListSkeleton";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import Breadcrumb from "components/Breadcrumb";
import Card from "components/Card";
import SectionTitle from "components/SectionTitle";
import { useActiveMenu } from "hooks/useActiveMenu";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";

const limit = 25;

const contentTypeMap = new Map([
  // ["due", "date"],
  ["audit date", "date"],
  ["severity", "status"],
  ["audit score", "number"],
  ["max score", "number"],
]);

const useFetchData = (
  queryStatus,
  queryFieldStaff,
  queryFarmCode,
  queryAuditDateFrom,
  queryAuditDateTo,
  queryAuditType,
  limit,
  orderBy,
  orderByDirection,
  loadedSavedFilterData,
  initialData = [],
  initialLoadedStatus = undefined,
  initialHasMoreRecords = true
) => {
  const [data, setData] = useState(initialData);
  const [keys, setKeys] = React.useState([]);
  const [loadedStatus, setLoadedStatus] = useState(initialLoadedStatus);
  const [hasMoreRecords, setHasMoreRecords] = useState(initialHasMoreRecords);

  const abortControllerRef = useRef(undefined);
  const currentPageRef = useRef(1);

  // ... (rest of the code inside fetchData)

  const fetchInitialData = async () => {
    currentPageRef.current = 1;
    setHasMoreRecords(true);
    fetchData(false);
  };

  const fetchData = async (isLoadMore = false) => {
    if (!isLoadMore) {
      setLoadedStatus((prevState) => ({
        ...prevState,
        data: LOADED_STATUS.LOADING,
      }));
    }

    const offset = (currentPageRef.current - 1) * limit;

    try {
      const response = await fetch(
        `/api/nonconformances-get?farmId=${queryFarmCode}&audit=${queryAuditType}&fromDate=${queryAuditDateFrom}&toDate=${queryAuditDateTo}&status=${queryStatus}&fieldStaff=${queryFieldStaff}&limit=${limit}&offset=${offset}&order=${orderBy}&orderDirection=${orderByDirection}`,
        {
          signal: abortControllerRef.current?.signal,
          method: "GET",
        }
      );

      if (abortControllerRef.current?.signal?.aborted) {
        /**
         * Signal aborted, do nothing
         */
        return;
      }

      if (response.ok) {
        const json = await response.json();

        json.forEach((item) => {
          if (item.Due) {
            const newDueDate = !isNullEmptyOrWhitespace(item.Due)
              ? localDateFromSQL(item.Due)
              : item.Due;

            if (newDueDate instanceof Date) {
              if (newDueDate.getTime() < new Date().getTime()) {
                item.Due = (
                  <div className="text-danger-600">
                    {dateToString(newDueDate)}
                  </div>
                );
              } else {
                item.Due = <div>{dateToString(newDueDate)}</div>;
              }
            }
          }

          if (item.NCNID) {
            item.id = item.NCNID;
            delete item.NCNID;
          }

          // Set key to prevent duplicates
          item.key = `${item.id}-${item.ParentQuestionID}`;
        });

        setHasMoreRecords(json.length < limit ? false : true);

        const newData = json ?? [];
        if (isLoadMore) {
          setData((prevState) => [...prevState, ...newData]);
        } else {
          setData(newData);
          const _firstRecord = json[0] ?? {};
          const _disallowedKeys = [
            "id",
            "key",
            "onclick",
            "ncnstatus",
            "parentquestionid",
            "ncnseverity",
            "ncnpwaid",
            "auditstatus",
            "ncnformname",
            "auditformname",
            "modulename",
            "auditpwaid",
            "auditformname",
          ];
          const _keys = Object.entries(_firstRecord).filter(
            ([key]) => !_disallowedKeys.includes(key.toLowerCase())
          );
          const _uniqueKeys = [
            ...new Set(
              _keys.map(([key, value]) => ({
                id: key,
                title: key,
                type: contentTypeMap.get(key?.toLowerCase()) ?? "string",
              }))
            ),
          ];
          setKeys(_uniqueKeys);
        }

        setLoadedStatus((prevState) => ({
          ...prevState,
          data: LOADED_STATUS.LOADED,
        }));
      } else {
        console.error("error");
        setLoadedStatus((prevState) => ({
          ...prevState,
          data: LOADED_STATUS.ERROR,
        }));
      }
    } catch (error) {
      if (abortControllerRef.current?.signal?.aborted) {
        /**
         * Signal aborted, do nothing
         */
        return;
      }
      console.error(error);

      setLoadedStatus((prevState) => ({
        ...prevState,
        data: LOADED_STATUS.ERROR,
      }));
    }
  };

  const loadMoreData = async () => {
    // Prevent loading more records before previous request has finished
    if (loadedStatus?.data === LOADED_STATUS.LOADING) return;

    currentPageRef.current++;
    fetchData(true);
  };

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    if (loadedSavedFilterData) {
      // We need to prevent fetch the data if the filter data is not loaded yet
      fetchData(false);
    }

    return () => {
      abortControllerRef.current.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedSavedFilterData]);

  return { data, keys, loadedStatus, refetchData: fetchInitialData, loadMoreData, hasMoreRecords };
};

const useFetchFieldData = (
  initialLoadedStatus = undefined
) => {
  const { farms } = useFarms();

  const abortControllerRef = useRef(undefined);

  const [statusListOptions, setStatusListOptions] = React.useState([]);
  const [fieldStaffListOptions, setFieldStaffListOptions] = React.useState([]);
  const [farmListOptions, setFarmListOptions] = React.useState([]);
  const [loadedStatus, setLoadedStatus] = useState(initialLoadedStatus);

  const fetchData = async () => {
    const { signal } = abortControllerRef.current;

    try {
      const response = await fetch(`/api/overviewfilters-get?formId=nonconformance`, {
        signal,
        method: "GET",
      });
      if (signal.aborted) return;

      if (response.ok) {
        const json = await response.json();

        const _statusListOptions =
          Object.entries(AUDIT_STATUS).map(([key, value]) => {
            return {
              Id: value,
              Text: key,
              Value: value,
            };
          }) ?? [];
        setStatusListOptions(_statusListOptions);

        const _fieldStaffListOptions =
          json
            ?.filter((lo) => lo.ListName?.toLowerCase() === "fieldstaffregion")
            ?.map((lo) => ({
              Id: lo.Id,
              Text: lo.Text,
              Value: lo.Id,
            })) ?? [];
        setFieldStaffListOptions(_fieldStaffListOptions);
      } else {
        console.error("error");
      }

      setLoadedStatus(LOADED_STATUS.LOADED);
    } catch (error) {
      if (signal.aborted) return;
      console.error(error);

      setLoadedStatus(LOADED_STATUS.ERROR);
    }
  };

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    fetchData();

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  useEffect(() => {
    const _farmListOptions = farms.map((f) => ({
      Id: f.FarmCode,
      Text: `${f.FarmCode} - ${f.FarmName}`,
      Value: f.FarmCode,
    }));
    setFarmListOptions(_farmListOptions);
  }, [farms]);

  return { statusListOptions, fieldStaffListOptions, farmListOptions, loadedStatus };
};

const ListPage = () => {
  const navigate = useNavigate();
  const { forms } = useContext(AppDataContext);
  const { activeMenu } = useActiveMenu();
  const [queryStatus, setQueryStatus] = React.useState(AUDIT_STATUS.OPEN);
  const [queryFieldStaff, setQueryFieldStaff] = React.useState("");
  const [queryFarmCode, setQueryFarmCode] = React.useState("");
  const [queryAuditDateFrom, setQueryAuditDateFrom] = React.useState("");
  const [queryAuditDateTo, setQueryAuditDateTo] = React.useState("");
  const [queryAuditType, setQueryAuditType] = React.useState("");
  const [loadedSavedFilterData, setLoadedSavedFilterData] = React.useState(false);

  const orderByRef = useRef("");
  const orderByDirectionRef = useRef("");

  const { statusListOptions, fieldStaffListOptions, farmListOptions, loadedStatus: fieldDataLoadedStatus } = useFetchFieldData(
  );

  const { data, keys, loadedStatus, refetchData, loadMoreData, hasMoreRecords } = useFetchData(
    queryStatus,
    queryFieldStaff,
    queryFarmCode,
    queryAuditDateFrom,
    queryAuditDateTo,
    queryAuditType,
    limit,
    orderByRef.current,
    orderByDirectionRef.current,
    loadedSavedFilterData
  );

  const formListOptions = React.useMemo(() => {
    if (!forms) return [];

    return forms
      .filter(
        (form) =>
          form.FormType.toLowerCase() === "audit" &&
          !form.FormName.toLowerCase().startsWith("ncn_")
      )
      .map((form) => {
        return {
          Id: form.FormName,
          Text: form.FormTitle,
          Value: form.FormName,
        };
      });
  }, [forms]);

  const tryRestorePreviousFilters = () => {
    const previousFilters = sessionStorage.getItem("ncnFilters");
    if (previousFilters) {
      const {
        meta: { order, orderByDirection },
        data: {
          queryStatus,
          queryFieldStaff,
          queryFarmCode,
          queryAuditDateFrom,
          queryAuditDateTo,
          queryAuditType,
        },
      } = JSON.parse(previousFilters);

      if (order) {
        orderByRef.current = order;
      }

      if (orderByDirection) {
        orderByDirectionRef.current = orderByDirection;
      }

      if (queryStatus) {
        setQueryStatus(queryStatus);
      }

      if (queryFieldStaff) {
        setQueryFieldStaff(queryFieldStaff);
      }

      if (queryFarmCode) {
        setQueryFarmCode(queryFarmCode);
      }

      if (queryAuditDateFrom) {
        setQueryAuditDateFrom(queryAuditDateFrom);
      }

      if (queryAuditDateTo) {
        setQueryAuditDateTo(queryAuditDateTo);
      }

      if (queryAuditType) {
        setQueryAuditType(queryAuditType);
      }
    }

    /**
     * Clear session storage
     * Note: We'll not actually clear it as if the form is submitted automatically
     * upon page load, the filters will be lost
     */
    // sessionStorage.removeItem("ncnFilters");

    setLoadedSavedFilterData(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    /**
     * Save to session storage for retrieve upon page refresh/return
     */
    sessionStorage.setItem(
      "ncnFilters",
      JSON.stringify({
        meta: {
          // page: currentPageRef.current, // Best not use this with infinite scroll
          order: orderByRef.current,
          orderByDirection: orderByDirectionRef.current,
        },
        data: {
          queryStatus,
          queryFieldStaff,
          queryFarmCode,
          queryAuditDateFrom,
          queryAuditDateTo,
          queryAuditType,
        },
      })
    );

    refetchData();
  };

  const handleSortTable = (key, direction) => {
    orderByRef.current = key;
    orderByDirectionRef.current = direction;
    refetchData();
  };

  const handleOnClickRow = (item) => {
    return navigate(
      buildFormPageUrl(
        activeMenu,
        item.NCNFormName,
        item.AuditPWAID,
        item.AuditFormName,
        item.id,
        undefined,
        {
          farmId: item["Farm Code"],
          houseId: item.House?.toString() ?? "1",
        }
      )
    );
  };

  useEffect(() => {
    tryRestorePreviousFilters();
  }, []);

  return (
    <main className="flex flex-col flex-grow min-h-full">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          key="breadcrumb"
          showHome={false}
          showFarm={false}
          farmRequired={false}
          houseRequired={false}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>Audit Non-Conformances</SectionTitle>
        </div>
        <div className="col-span-full">
          <Card>
            {fieldDataLoadedStatus === LOADED_STATUS.LOADED ? (
              <Filters
                queryStatus={queryStatus}
                setQueryStatus={setQueryStatus}
                queryFieldStaff={queryFieldStaff}
                setQueryFieldStaff={setQueryFieldStaff}
                queryFarmCode={queryFarmCode}
                setQueryFarmCode={setQueryFarmCode}
                queryAuditDateFrom={queryAuditDateFrom}
                setQueryAuditDateFrom={setQueryAuditDateFrom}
                queryAuditDateTo={queryAuditDateTo}
                setQueryAuditDateTo={setQueryAuditDateTo}
                statusListOptions={statusListOptions}
                fieldStaffListOptions={fieldStaffListOptions}
                farmListOptions={farmListOptions}
                formListOptions={formListOptions}
                setQueryAuditType={setQueryAuditType}
                queryAuditType={queryAuditType}
                onSubmit={handleSubmit}
              />
            ) : fieldDataLoadedStatus === LOADED_STATUS.ERROR ? (
              <Alert theme="danger">
                An error occured while loading the filters. Please refresh and
                try again. If the issue continues please contact the technical
                support team.
              </Alert>
            ) : (
              <FieldsetSkeleton />
            )}
          </Card>
        </div>
        <div className="col-span-full">
          <Card>
            {loadedStatus?.data === LOADED_STATUS.LOADED ? (
              <InfiniteScroll
                className="infinite-scroll"
                style={{ overflow: "visible" }}
                dataLength={data.length}
                next={loadMoreData}
                hasMore={hasMoreRecords}
                loader={<DescriptionListSkeleton />}
                endMessage={
                  <p className="text-center text-gray-700 py-4">
                    End of records
                  </p>
                }
              >
                <TableChart
                  keys={keys}
                  data={data}
                  onClickRow={handleOnClickRow}
                  title="Non Conformance Overview"
                  settings={{
                    showConfig: false,
                    showPagination: false,
                  }}
                  onSortBy={handleSortTable}
                  sortBy={{
                    key: orderByRef.current,
                    direction: orderByDirectionRef.current,
                  }}
                />
              </InfiniteScroll>
            ) : loadedStatus?.data === LOADED_STATUS.ERROR ? (
              <Alert theme="danger">
                An error occured while loading the records. Please refresh and
                try again. If the issue continues please contact the technical
                support team.
              </Alert>
            ) : (
              <DescriptionListSkeleton />
            )}
          </Card>
        </div>
      </div>
    </main>
  );
};

function Filters({
  queryStatus,
  setQueryStatus,
  queryFieldStaff,
  setQueryFieldStaff,
  queryFarmCode,
  setQueryFarmCode,
  queryAuditDateFrom,
  setQueryAuditDateFrom,
  queryAuditDateTo,
  setQueryAuditDateTo,
  statusListOptions,
  fieldStaffListOptions,
  farmListOptions,
  formListOptions,
  setQueryAuditType,
  queryAuditType,
  className,
  onSubmit,
  ...other
}) {
  return (
    <div
      className={classNames(
        "grid grid-cols-2 tablet:grid-cols-3 gap-4",
        className
      )}
      {...other}
    >
      <div>
        <MultiSelect
          id="filter-farms"
          label="Farm(s)"
          labelPosition="inset"
          listOptions={farmListOptions}
          value={queryFarmCode}
          setValue={setQueryFarmCode}
          required={false}
          placeholder="Search by Farm Code"
          showSearch={true}
          disableCalcTrigger={true}
        />
      </div>
      <div>
        <MultiSelect
          id="filter-audit-type"
          label="Audit Type"
          labelPosition="inset"
          listOptions={formListOptions}
          value={queryAuditType}
          setValue={setQueryAuditType}
          required={false}
          placeholder="Search by Audit Type"
          showSearch={true}
          disableCalcTrigger={true}
        />
      </div>
      <div className="relative">
        <Datepicker
          id="filter-audit-date-from"
          label="Audit Date From"
          labelPosition="inset"
          placeholder="Search by Audit Date from"
          value={queryAuditDateFrom}
          setValue={setQueryAuditDateFrom}
          required={false}
          disableCalcTrigger={true}
        />
        {!isNullEmptyOrWhitespace(queryAuditDateFrom) && (
          <div
            className="absolute right-10 bottom-4 cursor-pointer group"
            onClick={() => setQueryAuditDateFrom("")}
          >
            <XIcon
              className="h-5 w-5 text-gray-400 group-hover:text-primary"
              title="clear value"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div className="relative">
        <Datepicker
          id="filter-audit-date-to"
          label="Audit Date To"
          labelPosition="inset"
          placeholder="Search by Audit Date to"
          value={queryAuditDateTo}
          setValue={setQueryAuditDateTo}
          required={false}
          disableCalcTrigger={true}
        />
        {!isNullEmptyOrWhitespace(queryAuditDateTo) && (
          <div
            className="absolute right-10 bottom-3.5 cursor-pointer group"
            onClick={() => setQueryAuditDateTo("")}
          >
            <XIcon
              className="h-5 w-5 text-gray-400 group-hover:text-primary"
              title="clear value"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <div>
        <MultiSelect
          id="filter-status"
          label="Status"
          labelPosition="inset"
          listOptions={statusListOptions}
          value={queryStatus ?? statusListOptions[0].Value}
          setValue={setQueryStatus}
          required={false}
          disableCalcTrigger={true}
        />
      </div>
      <div>
        <MultiSelect
          id="filter-field-staff"
          label="Field Staff"
          labelPosition="inset"
          listOptions={fieldStaffListOptions}
          value={queryFieldStaff}
          setValue={setQueryFieldStaff}
          required={false}
          showSearch={true}
          disableCalcTrigger={true}
        />
      </div>
      <Button onClick={onSubmit} theme="primary">
        Apply Filter
      </Button>
    </div>
  );
}

export default ListPage;
