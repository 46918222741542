import React from "react";
import { PropTypes } from "prop-types";
import { Label } from "components/core";
import Addon from "./Addon";
import classNames from "classnames";
import useCalcTrigger from "hooks/useCalcTrigger";

const Text = ({
  label,
  id,
  value,
  setValue,
  isFullWidth = false,
  disabled = false,
  labelPosition = "top",
  addonLeft,
  addonRight,
  className = "",
  type,
  render,
  labelSize,
  disableCalcTrigger,
  ...other
}) => {
  useCalcTrigger(value, setValue, disableCalcTrigger); // To act like the input onChange event

  const classes = ["px-4 py-0"];

  // Addons
  if (addonLeft) {
    classes.push("pl-10");
  }
  if (addonRight) {
    classes.push("pr-10");
  }

  if (type === "hidden") {
    return null;
  }

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${className} ${
        labelPosition === "left" ? "grid grid-cols-2 gap-4" : "relative"
      }`}
    >
      {label && (
        <Label
          id={id}
          text={label}
          position={labelPosition}
          addonPosition={addonLeft ? "left" : "right"}
          size={labelSize}
        />
      )}
      <div className={`mt-1`}>
        <div className={`relative ${labelPosition === "inset" ? "py-3" : ""}`}>
          {addonLeft && <Addon position="left">{addonLeft}</Addon>}
          <div
            className={classNames(
              "block w-full tablet:text-sm",
              labelPosition === "left" ? "px-4 py-0" : ""
            )}
          >
            {value}
          </div>
          {addonRight && <Addon position="right">{addonRight}</Addon>}
        </div>
      </div>
    </div>
  );
};

Text.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  labelPosition: PropTypes.string,
  labelSize: PropTypes.oneOf(["large", "small", undefined]),
  addonLeft: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  addonRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { Text };
