import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHT_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true, // required for React Router v6
    extensions: [reactPlugin]
  },
});

// Measure performance and capture exceptions from user devices
appInsights.loadAppInsights();
// appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

export { appInsights as default, SeverityLevel, reactPlugin };