import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Label } from "components/core";
import classNames from "classnames";
import useCalcTrigger from "hooks/useCalcTrigger";

/**
 * 
 * @param {{[key:string]: any}} props 
 * @returns 
 */
export function Toggle({
  id,
  label,
  labelPosition = "top",
  value,
  uncheckedValue = 0,
  checkedValue = 1,
  setValue,
  validate,
  setValid,
  render,
  disableCalcTrigger,
  className = "",
}) {
  useCalcTrigger(value, setValue, disableCalcTrigger);

  // const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  // const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(value?.length > 0);

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = (value) => {
    setValue(
      value?.toString() === checkedValue.toString()
        ? uncheckedValue
        : checkedValue
    );
    setHasValue(value?.length > 0);
  };

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    if (validate) return validate(value);
  };

  //#endregion

  //#region Side-effects

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult);

    const complete = true; // Toggle is always complete, on/off
    if (setValid) setValid(!validationResult, value, false, complete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  //#endregion

  const isChecked = () => {
    return value?.toString() === checkedValue?.toString() ? true : false;
  };

  // Prevent dom element rendering
  if (render === false) {
    return (
      <Input
        id={`${id}-hidden`}
        type="hidden"
        setValue={setValue}
        value={value}
        disableCalcTrigger={true}
      />
    );
  }

  return (
    <div
      className={classNames(
        className,
        labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative",
        labelPosition === "inset" ? "pt-4" : ""
      )}
    >
      {label && (
        <Label
          id={id}
          text={label}
          required={null}
          focused={false}
          hasValue={hasValue}
          position={labelPosition}
          className={classNames(labelPosition === "inset" ? "-ml-4 -mt-3" : "")}
          // erroneous={touched && error && !focused}
        />
      )}
      <div className={classNames("inline-flex items-center", label ? "col-span-2" : "col-span-3")}>
        <div
          className={`${
            isChecked()
              ? "bg-primary bg-gradient-to-r from-primary to-secondary"
              : "bg-gray-300 bg-gradient-to-r from-gra-300 to-gray-400"
          } border-1 border-gray-300 rounded-full shadow-md inline-flex h-6 w-14 col-span-2`}
        >
          <Input
            id={`${id}-hidden`}
            type="hidden"
            setValue={setValue}
            value={value}
            disableCalcTrigger={true}
          />
          <button
            id={id}
            type="button"
            size="none"
            className="border-2 border-transparent cursor-pointer rounded-full transition-colors ease-in-out duration-200 p-0 focus:outline-none focus:ring-0 relative inline-flex flex-grow justify-start"
            onClick={() => setInputValue(value)}
            aria-checked="false"
            role="switch"
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${isChecked() ? "translate-x-full" : "translate-x-0"}
            pointer-events-none inline-block h-m-content h-full w-1/2 rounded-full bg-white shadow-lg ring-0 transition ease-in-out duration-200`}
            />
          </button>
        </div>
        <div className="ml-2 text-xs text-gray-500">
          {isChecked()
            ? checkedValue.toString() === "1"
              ? "Yes"
              : checkedValue
            : uncheckedValue.toString() === "0"
            ? "No"
            : uncheckedValue}
        </div>
      </div>
    </div>
  );
}

Toggle.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  uncheckedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  checkedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  setValue: PropTypes.func,
  children: PropTypes.any,
  validate: PropTypes.func,
  setValid: PropTypes.func,
  render: PropTypes.bool,
};
