import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import BreadcrumbItemSkeleton from "./BreadcrumbItemSkeleton";
import { Button } from "components/core";
import { BreadcrumbDivider } from "./BreadcrumbDivider";

const BreadcrumbItem = ({
  title,
  icon = undefined,
  onClick,
  subtitle,
  showDivider = true,
  loaded = true,
  showOptions = false,
  onShowOptions = () => {},
  onHideOptions = () => {},
  options = [],
  optionsHeading = "",
  optionsFilteredByMeta = {},
}) => {
  const liRef = useRef(undefined);
  const prevShowOptions = useRef(showOptions);
  
  useEffect(() => {
    if (showOptions && !prevShowOptions.current) {
      onShowOptions(liRef.current);
    }

    if (showOptions !== prevShowOptions.current) {
      prevShowOptions.current = showOptions;
    }
  }, [showOptions, onShowOptions]);

  if (!loaded) {
    return <BreadcrumbItemSkeleton showDivider={showDivider} />;
  }

  return (
    <li data-cy="breadcrumb-item" ref={liRef} className="flex whitespace-nowrap ">
      <div className="flex items-center">
        <Button
          className="mx-4 py-1 text-left text-sm text-gray-600 hover:text-gray-700 focus:outline-none"
          onClick={onClick}
          options={options}
          showOptions={showOptions}
          onShowOptions={() => !!onShowOptions && onShowOptions(liRef.current)}
          onHideOptions={onHideOptions}
          theme="text"
          optionsHeading={optionsHeading}
          optionsFilteredByMeta={optionsFilteredByMeta}
          optionsClassName="w-full tablet:max-w-sm"
          showStats={true}
          showSearch={true}
          disabled={onClick === undefined && !options?.length ? true : false}
        >
          <div className="flex items-center space-x-2">
            {!!icon && <div>{icon}</div>}
            {(title || subtitle) && (
              <div>
                {title && (
                  <div className="text-sm font-medium uppercase text-primary hover:text-primary-dark w-full block truncate ">
                    {title}
                  </div>
                )}
                {subtitle && (
                  <div className="text-xs w-full truncate ">
                    {subtitle}
                  </div>
                )}
              </div>
            )}
          </div>
        </Button>
        {showDivider && (
          <BreadcrumbDivider />
        )}
      </div>
    </li>
  );
};

BreadcrumbItem.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.node,
  onClick: PropTypes.func,
};

export default BreadcrumbItem;
