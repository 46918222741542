import React from "react";

export default function ButtonSkeleton({
  isFullWidth = false,
  theme = "",
  size = "",
  className = "",
  ...other
}) {
  const classArr = [];

  // Theme
  if (theme === "primary") {
    classArr.push("bg-primary");
  } else if (theme === "secondary") {
    classArr.push("bg-secondary");
  } else {
    classArr.push("bg-gray-300");
  }

  // Size
  if (size === "small") {
    classArr.push("w-16 h-8");
  } else if (size === "large") {
    classArr.push("w-24 h-12");
  } else {
    classArr.push("w-20 h-10");
  }

  // Full-width
  if (isFullWidth) {
    classArr.push("w-full");
  }

  return (
    <div className={`animate-pulse ${className}`}>
      <div className={`${classArr.join(" ")} rounded`}></div>
    </div>
  );
}
