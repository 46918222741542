import { CHART_TYPE } from "constants.js";
import { v4 as uuid } from "uuid";
import {
  isNull,
  isNullEmptyOrWhitespace,
  isNumeric,
} from "helpers/stringUtilities";
import { parseJsonLogic } from "./formsUtilities";
import { calculateTextColorFromBackgroundColor, roundNaturally } from "./mathUtilities";
import { compareStringOrNumber } from "./comparisionUtilities";

/**
 * Format data for specific chart type
 * @param {import("components/Dashboard/types").IChart} chart
 * @param {any[]}  data  - Raw form data.
 * @param {{ id: String, type: String }}  chart  - Chart object of chart to format data for.
 */
export const formatChartData = (chart, data, standards) => {
  let result = {
    data: [],
    standardData: [],
  };
  if (!data?.length) return result;

  // Filter 'age' data by selected datasources
  const allowedDataSourcesSet = new Set();
  for (const key of chart.keys) {
    const dataSource = key.id.includes(":") ? key.id.split(":")[0] : undefined;
    if (!!dataSource) allowedDataSourcesSet.add(dataSource);
  }
  const allowedDataSources = Array.from(allowedDataSourcesSet);

  // Data
  const chartKeys = chart.keys.map((key) => key.id);
  const tooltipKeys = chart.tooltipKeys?.map((k) => k.id) ?? [];
  const operandKeys = getOperandKeysFromChartKeys(chart.keys);
  const eventsKeys = getEventKeysFromChartKeys(chart.keys);
  const filteredData = filterDataByChartKeys(
    data,
    chartKeys,
    tooltipKeys,
    operandKeys,
    eventsKeys,
    allowedDataSources
  );

  // Index by first key
  const chartIndexBy = chartKeys.length > 0 ? chartKeys[0] : null;

  // Standards
  const standardIndexBy = chartIndexBy
    // replace datasource
    ?.replace(/^(.*:)(.*)$/, "$2");

  // if (
  //   !isNullEmptyOrWhitespace(standardIndexBy) &&
  //   standards?.length &&
  //   standardIndexBy in standards[0] === false
  // ) {
  //   debugger
  //   // Standard index by should exist in 'standards' data
  //   throw new Error(`${standardIndexBy} does not exist in standards`);
  // }
  const chartStandardKeys = chart.standardKeys?.map((k) => k.id) ?? [];
  const filteredStandards = filterStandardsByStandardKeys(
    standards,
    chartStandardKeys,
    standardIndexBy
  );

  if (chart.type === CHART_TYPE.BAR) {
    // Chart data
    result.data = filteredData.map((obj) => {
      return {
        ...obj,
        tooltip: Object.fromEntries(
          chart.tooltipKeys?.map((k) => {
            return [k.title, obj[k.id]];
          }) ?? []
        ),
        id: uuid(), // react 'key'
      };
    });

    // Sort data
    const sortByKey = chart.settings?.sortByKey ?? chartIndexBy;
    let sortByDirection = chart.settings?.sortByDirection ?? "desc";
    sortedDataInPlace(result.data, sortByKey, sortByDirection);

    if (chart.settings?.recordCount) {
      result.data = result.data.slice(0, chart.settings.recordCount);
    }

    if (chart.settings?.layout === "horizontal") {
      // Fix bug in nivo horizontal bar chart, decending sort goes up instead of down
      result.data.reverse();
    }

    // Remove any values/properties we don't want to show here
    // E.g. newData.forEach((x) => { delete x["Daily Birds Alive"]; });
  } else if (chart.type === CHART_TYPE.LINE) {
    // LINE CHART
    /**
     * Line Chart data, which must conform to this structure:
     * Array<{
     *   id:   string | number
     *   data: Array<{
     *     x: number | string | Date
     *     y: number | string | Date
     *   }>
     * }>
     * @see: https://nivo.rocks/line/
     */

    filteredData.sort((a, b) => a[chartIndexBy] - b[chartIndexBy]);

    // Chart data
    for (const key of chart.keys.filter((k, i) => i > 0)) {
      const flatValues = filteredData.flatMap((x) => ({
        x: x[chartIndexBy],
        y: x[key.id],
        tooltip: Object.fromEntries(
          chart.tooltipKeys?.map((k) => {
            return [k.title, x[k.id]];
          }) ?? []
        ),
      }));

      // Filter out null/empty values to avoid breaks in chart
      result.data.push({
        id: key.id,
        data: flatValues.filter((d) => !isNullEmptyOrWhitespace(d.y))
      });
    }

    // Standard data
    for (const key of chartStandardKeys) {
      const flatValues = filteredStandards.flatMap((x) => {
        return {
          x: x[standardIndexBy],
          y: x[key],
        };
      });

      result.standardData.push({
        id: key,
        data: flatValues,
      });
    }
  } else if (chart.type === CHART_TYPE.SCATTER) {
    filteredData.sort((a, b) => a[chartIndexBy] - b[chartIndexBy]);

    // Chart data
    for (const key of chart.keys.filter((k, i) => i > 0)) {
      const flatValues = filteredData.flatMap((x) => ({
        x: x[chartIndexBy],
        y: x[key.id],
        tooltip: Object.fromEntries(
          chart.tooltipKeys?.map((k) => {
            return [k.title, x[k.id]];
          }) ?? []
        ),
      }));

      result.data.push({
        id: key.id,
        data: flatValues,
      });
    }

    // Standard data
    for (const key of chartStandardKeys) {
      const flatValues = filteredStandards.flatMap((x) => {
        return {
          x: x[standardIndexBy],
          y: x[key],
        };
      });

      result.standardData.push({
        id: key,
        data: flatValues,
      });
    }
  } else if (chart.type === CHART_TYPE.TABLE) {
    // key e.g. _diff_mortality_vs_2
    // get all keys that start with _diff_
    result.data = filteredData.map((record) => {
      const newRecord = {
        ...record,
        id: record.id ?? uuid(), // react 'key'
      };

      for (const key of chart.keys) {
        // remove __{number} from record key
        const recordIndex = key.id.replace(/__(\d+)$/, "");

        if (key?.operands?.length) {
          newRecord[key.id] = record[recordIndex];

          const { numericalResult, conditionalResult } = parseExpression(
            key,
            newRecord
          );
          const hasConditionalResult = !isNullEmptyOrWhitespace(
            conditionalResult
          );

          const backgroundColor = conditionalResult;
          let color = calculateTextColorFromBackgroundColor(backgroundColor);

          newRecord[key.id] = {
            style: hasConditionalResult ? { backgroundColor, color, padding: "2px 4px", borderRadius: "0.2rem" } : {},
            value: numericalResult,
          };
        } else {
          newRecord[key.id] = record[recordIndex];
        }
      }

      return newRecord;
    });

    if (chart.settings?.recordCount) {
      result.data = result.data.slice(0, chart.settings.recordCount);
    }

    // Remove any values/properties we don't want to show here
    // E.g. newData.forEach((x) => { delete x["Daily Birds Alive"]; });
  } else if (chart.type === CHART_TYPE.METRIC) {
    result.data = filteredData
      .map((r) => ({
        ...r,
        id: uuid(), // Ensures that each record set displays on chart, regardless of match index
      }))
      .slice(-1); // Fetch only the last record
  } else if (chart.type === CHART_TYPE.TREND) {
    result.data = filteredData
      .map((r) => ({
        ...r,
        id: uuid(), // Ensures that each record set displays on chart, regardless of match index
      }))
      .slice(-2) // last too records only
      .reverse();
  }

  return result;
};

function filterDataByChartKeys(
  data,
  chartKeys,
  tooltipKeys,
  operandKeys,
  eventKeys,
  allowedDataSources
) {
  const filteredData = [];
  for (const obj of data) {
    const newProperties = Object.keys(obj)
      ?.filter((key) => {
        if (chartKeys.includes(key)) return true;
        if (tooltipKeys.includes(key)) return true;
        if (operandKeys.includes(key)) return true;
        if (eventKeys.includes(key)) return true;

        return false
      })
      .reduce((res, key) => {
        res[key] = obj[key].value;
        return res;
      }, {});

    // does property have applicable datasource properties
    const hasDataSourceProperty =
      allowedDataSources.length === 0 ||
      Object.keys(newProperties).some((property) =>
        allowedDataSources.includes(property.split(":")[0])
      );

    if (hasDataSourceProperty && Object.keys(newProperties).length > 0) {
      filteredData.push(newProperties);
    }
  }

  return filteredData;
}

function filterStandardsByStandardKeys(
  standards,
  chartStandardKeys,
  standardIndexBy
) {
  const filteredStandards = [];
  for (const obj of standards) {
    const newProperties = Object.keys(obj)
      ?.filter(
        (key) => chartStandardKeys.includes(key) || key === standardIndexBy
      )
      .reduce((res, key) => {
        res[key] = obj[key].value;
        return res;
      }, {});

    if (Object.keys(newProperties).length > 0) {
      filteredStandards.push(newProperties);
    }
  }
  return filteredStandards;
}

/**
 * Convert JSON object to metrics format
 */
export function extractMetricsFromJsonObjs(jsonObjs) {
  if (isNull(jsonObjs)) return jsonObjs;

  // Build array of distinct metrics
  // It's important to go through full array as some objects contain different properties
  const disallowedKeys = [];
  const metricsMap = new Map();
  for (const obj of jsonObjs) {
    for (const key in obj) {
      const isDisallowedKey = disallowedKeys.includes(key);
      if (!isDisallowedKey && !metricsMap.has(key)) {
        metricsMap.set(key, {
          id: key,
          title: obj[key].title,
        });
      }
    }
  }

  return Array.from(metricsMap.values());
}

export function formatChartAttrs(chart) {
  let _newAttrs = { ...chart.attrs };
  if (chart.type === CHART_TYPE.BAR || chart.type === CHART_TYPE.LINE) {
    _newAttrs = {
      ..._newAttrs,
      axis: {
        ..._newAttrs.axis,
        bottom: {
          ..._newAttrs.axis?.bottom,
          legend: chart.keys.length > 0 ? chart.keys[0].title : null,
        },
        left: {
          ..._newAttrs.axis?.left,
          legend: chart.keys.length > 1 ? chart.keys[1].title : null,
        },
        right: {
          ..._newAttrs.axis?.right,
          legend: chart.keys.length > 2 ? chart.keys[2].title : null,
        },
      },
    };
  }

  return _newAttrs;
}

export function filterUserDashboardsByFarm(dashboards, user, farm) {
  if (!dashboards?.length) {
    return undefined;
  }

  const result = dashboards.filter((d) => {
    // filter by userGroups
    if (!isNullEmptyOrWhitespace(d.userGroups)) {
      const allowedUserGroups = d.userGroups
        .split(",")
        .map((ug) => ug.toLowerCase());
      if (
        !allowedUserGroups.includes(
          user.PermissionLevel?.toString().toLowerCase()
        )
      ) {
        return false;
      }
    }

    // filter by farm group
    if (!isNullEmptyOrWhitespace(d.farmGroups)) {
      const allowedFarmGroups = d.farmGroups
        .split(",")
        .map((fg) => fg.toLowerCase());
      if (!allowedFarmGroups.includes(farm.FarmGroup.toLowerCase())) {
        return false;
      }
    }

    return true;
  });

  return result;
}

export function filterUserDashboardsByMenuId(dashboards, user, farms, menuId) {
  if (
    !dashboards?.length ||
    !farms?.length ||
    user?.PermissionLevel === undefined ||
    menuId === undefined
  ) {
    return undefined;
  }

  const result = dashboards.filter((d) => {
    // filter by menu id
    if (!isNullEmptyOrWhitespace(menuId)) {
      if (d.menuId?.toString() !== menuId?.toString()) {
        return false;
      }
    }

    // filter by userGroups
    if (!isNullEmptyOrWhitespace(d.userGroups)) {
      const allowedUserGroups = d.userGroups
        .split(",")
        .map((ug) => ug.toLowerCase());
      if (
        !allowedUserGroups.includes(
          user.PermissionLevel?.toString().toLowerCase()
        )
      ) {
        return false;
      }
    }

    // filter by farm groups
    // Keep this at the bottom, if possible, as it is the most expensive operation
    if (!isNullEmptyOrWhitespace(d.farmGroups)) {
      const userFarmGroups = farms.reduce((acc, farm) => {
        const normalisedFarmGroup = farm.FarmGroup?.toLowerCase();
        if (normalisedFarmGroup && !acc.includes(normalisedFarmGroup)) {
          acc.push(normalisedFarmGroup);
        }
        return acc;
      }, []);

      const allowedFarmGroups = d.farmGroups
        .split(",")
        .map((fg) => fg.toLowerCase());
      if (!allowedFarmGroups.some((fg) => userFarmGroups.includes(fg))) {
        return false;
      }
    }

    return true;
  });

  return result.length > 0 ? result : undefined;
}

/**
 * 
 * @param {import("components/Dashboard/types").IDashboard[] | undefined} dashboards 
 * @param {string | undefined} id 
 * @returns {import("components/Dashboard/types").IDashboard | undefined}
 */
export function getDashboardById(dashboards, id) {
  if (!dashboards?.length) {
    return undefined;
  }

  const result = dashboards.find((d) => d.id === id);

  return result;
}

export function getMaxXValue(data) {
  const maxArray = data.map((data) =>
    data.data.reduce((max, p) => {
      const x = Number(p.x);
      return x > max ? x : max;
    }, data.data[0]?.x ?? Infinity)
  );

  const max = Math.max(...maxArray);

  return max;
}

export function getMinXValue(data) {
  const minArray = data.map((data) =>
    data.data.reduce((min, p) => {
      const x = Number(p.x);
      return x < min ? x : min;
    }, data.data[0]?.x ?? 0)
  );

  const min = Math.min(...minArray);

  return min;
}

export function getMaxYValueWithHeadroom(data) {
  const max = getMaxYValue(data);

  return max * 1.2;
}

export function getMaxYValue(data) {
  const maxArray = data.map((d) =>
    d.data.reduce((max, p) => {
      const y = Number(p.y);
      return y > max ? y : max;
    }, d.data[0]?.y ?? Infinity)
  );

  const max = Math.max(...maxArray);

  return max;
}

export function getMinYValue(data) {
  const minArray = data.map((data) =>
    data.data.reduce((min, p) => {
      const y = Number(p.y);
      return y < min ? y : min;
    }, data.data[0]?.y ?? 0)
  );

  const min = Math.min(...minArray);

  return min;
}

export function createChartId(existingChartKeys, metric) {
  let result = metric?.id;

  // if key already exists, add increment to the end of the key
  if (existingChartKeys.find((k) => k.id === metric.id)) {
    result = `${metric.id}__${
      existingChartKeys.filter((k) => stripChartIdIncrement(k.id) === metric.id)
        .length + 1
    }`;
  }

  return result;
}

export function stripChartIdIncrement(chartId) {
  return chartId?.replace(/__(\d+)$/, "");
}

function parseExpression(key, record) {
  const { operands } = key;

  const comparisonOperands = operands?.filter((o) =>
    ["<", ">", "<=", ">=", "==", "between"].includes(o.operator)
  );

  const numericalOperands = operands?.filter((o) =>
    ["+", "*", "-", "/"].includes(o.operator)
  );

  const recordValue = record[key.id];

  // Numerical operators
  let numericalResult = recordValue;
  if (isNumeric(recordValue)) {
    for (const operand of numericalOperands) {
      let operandValue1 = operand.values[0].value;

      const operandValueType1 = operand.values[0].type;

      if (isNullEmptyOrWhitespace(operandValue1)) {
        continue;
      }

      if (operandValueType1 === "metric") {
        operandValue1 = { var: operandValue1 };
      }

      const expression = {
        [operand.operator]: [numericalResult, operandValue1],
      };

      // prettier-ignore
      // console.log("expression", JSON.stringify(expression));

      numericalResult = parseJsonLogic(expression, record);
    }
  }

  numericalResult = Number(roundNaturally(numericalResult, 2));

  // prettier-ignore
  // console.log(key.id, recordValue, adjustedRecordValue)

  // Conditional operators
  let conditionalExpression = [];
  let conditionalResult = undefined;
  for (const operand of comparisonOperands) {
    if (isNullEmptyOrWhitespace(operand.values[0].value)) {
      continue;
    }

    const operandValue1 = generateOperandValueJSONLogic(
      operand.values[0],
      operand.tolerance,
      "+", // force the tolerance to be added to the upper value
      operand.toleranceUnit,
    );
    
    let expression = {
      [operand.operator]: [numericalResult, operandValue1],
    };

    if (operand.operator === "between" ) {
      // Between is a special case where we need to add an upper bound
      const operandValue2 = generateOperandValueJSONLogic(
        operand.values[1],
        operand.tolerance,
        "+", // force the tolerance to be added to the upper value
        operand.toleranceUnit,
      );

      expression = {
        "<=": [operandValue1, numericalResult, operandValue2],
      };
    } else if (operand.operator === "==") {
      // Equal to is a special case where we need to add an upper bound to cater for tolerances, we instead treat it as a between
      const operandValue2 = generateOperandValueJSONLogic(
        operand.values[0],
        operand.tolerance,
        "+", // force the tolerance to be added to the upper value
        operand.toleranceUnit,
      );

      expression = {
        "<=": [operandValue1, numericalResult, operandValue2],
      };
    }

    conditionalExpression.push(expression);
    conditionalExpression.push(operand.color);
  }

  if (conditionalExpression.length) {
    conditionalExpression = { if: conditionalExpression };

    conditionalResult = parseJsonLogic(conditionalExpression, record);

    // prettier-ignore
    // console.log("conditionalExpression", JSON.stringify(conditionalExpression), "conditionalResult", conditionalResult, "record", JSON.stringify(record))
  }

  return {
    conditionalResult,
    numericalResult,
  };
}

function generateOperandToleranceJSONLogic(
  value,
  tolerance = 0,
  toleranceUnit = undefined
) {
  let result = toleranceUnit === "percentage" ? {"*": [value, {"/": [Number(tolerance), 100]}]} : Number(tolerance);

  return result;
}

function generateOperandValueJSONLogic(operandValue, toleranceValue, toleranceOperator, toleranceUnit) {
  let result = operandValue.type === "metric" ? { var: Number(operandValue.value) } : Number(operandValue.value);

  const toleranceJSONLogic = generateOperandToleranceJSONLogic(result, toleranceValue, toleranceUnit);
  // console.log("result", result, "toleranceValue", toleranceValue, "toleranceOperator", toleranceOperator, "toleranceJSONLogic", toleranceJSONLogic)

  if (toleranceValue !== 0) {
    result = {
      [toleranceOperator]: [result, toleranceJSONLogic],
    };
  }

  return result;
}

function getOperandKeysFromChartKeys(chartKeys) {
  const result = [];

  for (const key of chartKeys) {
    if (key.type === "expression") {
      
      for (const operand of key.operands) {
        
        for (const value of operand.values) {

          if (value.type === "metric" && !result.includes(value.value)) {
            result.push(value.value);
          }

        }

      }

    }
    
  }

  return result;
}

function getEventKeysFromChartKeys(chartKeys) {
  const result = new Set();

  for (const key of chartKeys) {

    for (const event of key?.events ?? []) {

      for (const arg of event.args) {

        if (!result.has(arg)) {
          result.add(arg);
        }

      }

    }
    
  }

  return Array.from(result.values()) ?? [];
}

function sortedDataInPlace(data, key, direction) {
  if (!key) return data;

  data.sort((a, b) => {
    const aValue = a[key]?.value ? a[key].value : a[key];
    const bValue = b[key]?.value ? b[key].value : b[key];

    return compareStringOrNumber(aValue, bValue)
  });

  if (direction === "desc") {
    data.reverse();
  }

  return data;
}