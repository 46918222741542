import React from "react";

export function Badge({ className = "", icon, iconPosition, children, theme = '', ...other }) {
  const classesArr = ["inline-flex items-center px-2 py-0.5 rounded-full leading-none border", className];

  // Theme
  if (theme === 'clean') {
    classesArr.push("");
  } else if (theme === 'success') {
    classesArr.push("border-success-400 bg-success-50 text-success-600 ");
  } else if (theme === 'warning') {
    classesArr.push("border-warning-400 bg-warning-50 text-warning-600 ");
  } else if (theme === 'danger') {
    classesArr.push("border-danger-400 bg-danger-50 text-danger-600 ");
  } else if (theme === 'primary') {
    classesArr.push("border-primary-lighter bg-primary-lightest text-primary");
  } else if (theme === 'secondary') {
    classesArr.push("border-secondary-lighter bg-secondary-lightest text-secondary-light ");
  } else {
    classesArr.push("border-gray-200 bg-gray-100 text-gray-500 ");
  }

  return (
    <div {...other} className={classesArr.join(" ")}>
      {iconPosition !== "right" && !!icon && <div className="ml-1">{icon}</div>}
      <div className="flex-grow text-center">{children}</div>
      {iconPosition === "right" && !!icon && <div className="ml-1">{icon}</div>}
    </div>
  );
}
