import classNames from "classnames";
import Card from "components/Card";
import { Alert, Button, ButtonClicked, Fieldset } from "components/core";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { DATA_STATUS } from "constants.js";
import { AppDataContext } from "context/AppDataProvider";
import { useNotifications } from "context/NotificationProvider";
import { buildFormValues } from "helpers/formsUtilities";
import {
  getFormDataStatus,
  getPenDataFromFormData,
  hasPendingFileSubmission,
  hasSavedFiles,
  IFileUploadValue,
  IFormField,
  IFormValue,
  IFormValueDataSources,
  IPenData,
  IPenFormValid,
  reduceFormDataToObject,
} from "helpers/formUtilities";
import { fetchMediaByID } from "helpers/mediaUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useActiveMenu } from "hooks/useActiveMenu";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormField from "./FormField";
import { v4 as uuid } from "uuid";
import { deepClone } from "helpers/dataUtilities";
import { buildListPageUrl } from "helpers/redirectUtilities";

interface MediaUploadFormProps {
  className?: string;
  setPageTitle: (pageTitle: string | React.ReactNode) => void;
}

interface IMediaPostResponse {
  d: {
    failed: {
      url: string;
      filename: string;
      errorMessage: string;
    }[];
  };
}

export interface IMediaFormFormValues {
  PenValues: [
    {
      Pen: IPenData["Pen"];
      Values: IPenData["Values"];
    }
  ];
}

export default function MediaUploadForm({
  className,
  setPageTitle,
}: MediaUploadFormProps) {
  const { formId, id } = useParams();
  const { forms } = useContext(AppDataContext);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const { activeMenu } = useActiveMenu();
  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;

  const [isProcessingButtonVisible, setIsProcessingButtonVisible] =
    useState<Boolean>(false);
  const [formValid, setFormValid] = useState<IPenFormValid[]>([]);
  const [formValues, setFormValues] = useState<
    IMediaFormFormValues | undefined
  >(undefined);

  const abortControllerRef = useRef<AbortController | undefined>(undefined);

  const form = useMemo(() => {
    if (!forms?.length) {
      // Return undefined to show loading state
      return undefined;
    }

    const filteredForms = forms.find(
      (f) =>
        f.FormName?.toLowerCase() === formId?.toLowerCase() &&
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName?.toLowerCase() === module?.toLowerCase()
    );

    // Return null to show error state
    return filteredForms ?? null;
  }, [forms, module, moduleFeatureGroup, formId]);

  const dataStatus = useMemo<Number | undefined>(
    () => getFormDataStatus(formValid),
    [formValid]
  );

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      if (id) {
        fetchMediaByID(id, abortControllerRef.current.signal)
          .then((data) => {
            const newFormValues: IMediaFormFormValues = {
              PenValues: [
                {
                  Pen: "1",
                  Values: [],
                },
              ],
            };

            const record = data?.d?.[0];
            if (record === undefined) {
              setFormValues(newFormValues);
              return;
            }

            const penValues: IFormValue[] = Object.entries(record).map(
              ([key, value]) => {
                return {
                  Ref: key,
                  Value: value as any,
                };
              }
            );
            newFormValues.PenValues[0].Values = penValues;

            setFormValues(newFormValues);
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              return;
            }
            addNotification({
              title: "Error",
              theme: "error",
              description: error.message,
            });
          });
      }

      return () => {
        abortControllerRef.current?.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Set page title
   */
  useEffect(() => {
    const pageTitle =
      form?.FormTitle !== undefined ? form?.FormTitle : undefined;
    setPageTitle(`File Upload - ${pageTitle}`);
  }, [form?.FormTitle, setPageTitle]);

  const handleOnChangeValue = (
    field: IFormField,
    penId: string,
    value: IFormValue["Value"]
  ) => {
    if (!field?.Ref || !penId)
      return new Error("'ref' and 'pen' are required properties.");

    setFormValues((prevState) => {
      const dataSources: IFormValueDataSources = {
        this: prevState ?? {},
        // _custom: {
        //   farmId: farmId,
        //   houseId: houseId,
        // },
        _field: field,
      };

      try {
        const newState = buildFormValues(
          field,
          penId,
          value,
          form,
          dataSources,
          undefined,
          undefined,
          0,
          undefined
        );

        return newState;
      } catch (error) {
        console.error(error);
      }

      return prevState as any;
    });
  };

  const handleOnValidate = (
    fieldId: string, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    field: IFormField,
    penNumber: string,
    valid: boolean,
    complete: boolean,
    required: boolean
  ) => {
    setFormValid((prevState) => {
      const newFormValid = prevState.filter(
        (fv) =>
          !(
            fv.Ref === fieldId &&
            (isNullEmptyOrWhitespace(field.QuestionGroup) ||
              fv.QuestionGroup === field.QuestionGroup) &&
            fv.Pen.toString() === penNumber.toString()
          )
      );
      newFormValid.push({
        Ref: fieldId,
        QuestionGroup: field.QuestionGroup,
        Pen: penNumber,
        Valid: valid,
        Complete: complete,
        Required: required,
      });

      return newFormValid;
    });
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (dataStatus === DATA_STATUS.ERROR) {
      addNotification({
        title: "Invalid",
        description: "The form contains invalid data. Please fix and try again.",
        theme: "error",
      });

      return;
    } else if (formValues === undefined || dataStatus !== DATA_STATUS.COMPLETE) {
      addNotification({
        title: "Form is not complete",
        description: "Please fill out all required fields",
        theme: "error",
      });

      return;
    }

    setIsProcessingButtonVisible(true);

    const clonedFormValues = deepClone(formValues);

    const formValueObj = reduceFormDataToObject(clonedFormValues);

    async function uploadNewMedia() {
	    // Build FormData as part of multipart form post
	    const formData = new FormData();
	    const formName = form?.FormName ?? "unknown";
	    const recordId = id;
	
	    for (const [index, value] of Object.entries(formValueObj)) {
	      if (hasPendingFileSubmission(value)) {
	        const fileUploadValue = value as IFileUploadValue;
	        const pendingFiles = fileUploadValue.pending;
	        const fileUrls: string[] = [];
	
	        for (const file of pendingFiles as File[]) {
	          const fileExt = file.name.split(".").pop();
	          const fileID = uuid();
	          const newFileName = `${fileID}.${fileExt}`
	          const newFilePath = `${formName}/${newFileName}`;
	          formData.append("files", file, newFilePath);
	          fileUrls.push(newFilePath);
	        }
	
	        // Replace pending files with fileIDs
	        // These will then be replaced on the server with the correct file url
	        formValueObj[index] = {
	          ...fileUploadValue,
	          pending: fileUrls,
	        };
	      }
	    }
	
	    if (recordId !== undefined) {
	      formValueObj.id = recordId;
	    }
	    formValueObj.formName = formName;
	
	    // Append data
	    const blob = new Blob([JSON.stringify(formValueObj)], {
	      type: "application/json",
	    });
	    formData.append("data", blob);
	
	    try {
	      const response = await fetch("/api/media-post", {
	        method: "POST",
	        body: formData,
	      });
	
	      if (!response.ok) {
	        throw new Error(response.statusText);
	      }
	
	      const responseBody: IMediaPostResponse = await response.json();
	
	      const errors = responseBody.d.failed.reduce((acc: string[], curr) => {
          if (curr.errorMessage) {
	          acc.push(curr.errorMessage);
          } else {
            acc.push(`File ${curr.filename} failed to upload.`);
          }
	        return acc;
	      }, []);
	
	      if (errors.length > 0) {
	        addNotification({
	          title: "Upload failed",
	          theme: "error",
	          description: errors.join("\r\n\r\n"),
	        });          
	      } else {
	        addNotification({
	          title: "Success",
	          theme: "success",
	          description:
	            "Your changes have been saved.",
	        });
	
	        return navigate(buildListPageUrl(activeMenu));
	      }
	    } catch (error) {
	      console.error(error);
	
	      addNotification({
	        title: "Error submitting data",
	        theme: "error",
	        description:
	          "An error occurred while submitting the form data. Please try again. If the problem persists, please contact our support team.",
	      });
	    } finally {
	      setIsProcessingButtonVisible(false);
	    }
    }
    await uploadNewMedia();
  };

  const handleOnDelete = async () => {
    if (id === undefined) {
      return;
    }

    if (
      window.confirm(
        "Are you sure you wish to delete this record? This action cannot be undone."
      )
    ) {

      const clonedFormValues = deepClone(formValues);

      const formValueObj = reduceFormDataToObject(clonedFormValues);

      const filesToDelete: string[] = [];
      for (const value of Object.values(formValueObj)) {
        if (hasSavedFiles(value)) {
          const fileUploadValue = value as IFileUploadValue;
          const savedFilesArray = fileUploadValue.saved;
          filesToDelete.push(...savedFilesArray);
        }
      }

      try {
        const response = await fetch(`/api/media-delete`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
            filesToDelete,
          }),
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const responseBody: IMediaPostResponse = await response.json();

        const errors = responseBody.d.failed.reduce((acc: string[], curr) => {
          if (curr.errorMessage) {
	          acc.push(curr.errorMessage);
          } else {
            acc.push(`File ${curr.url} failed to delete.`);
          }
	        return acc;
	      }, []);

        if (errors.length > 0) {
          addNotification({
            title: "Failed",
            theme: "error",
            description: errors.join("\r\n\r\n"),
          });          
        } else {
          addNotification({
            title: "Success",
            theme: "success",
            description:
              "Record has been deleted.",
          });
  
          return navigate(buildListPageUrl(activeMenu));
        }
      } catch (error) {
        console.error(error);

        addNotification({
          title: "Error deleting record",
          theme: "error",
          description:
            "An error occurred while deleting the record. Please try again. If the problem persists, please contact our support team.",
        });
      }
    }
  };

  const handleClickCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    navigate(-1);
  };

  const isLoading = 
    form === undefined || 
    (
      !isNullEmptyOrWhitespace(id) && 
      isNullEmptyOrWhitespace(formValues?.PenValues?.[0]?.Values)
    );

  if (isLoading) {
    // form is loading
    return <FieldsetSkeleton />;
  }

  if (form === null) {
    // form does not exist
    return <Alert theme="danger">Media upload form not found.</Alert>;
  }

  return (
    <Card>
      <Fieldset
        title={form?.FormTitle}
        text={undefined}
        content={form?.FormSubTitle}
      >
        <div className={classNames("grid grid-cols-4 gap-4", className)}>
          {form.FormFields.map((ff) => (
            <FormField
              key={ff.Ref}
              {...{
                id: ff.Ref,
                field: ff,
                penNumber: "1",
                setFieldValue: handleOnChangeValue,
                setFieldValid: handleOnValidate,
                formValues: getPenDataFromFormData("1", formValues as any),
              }}
            />
          ))}

          <div className="col-span-full">
            <div className="flex">
              <div className="flex-grow">
                <Button
                  className="justify-self-start"
                  type="button"
                  onClick={handleClickCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="justify-self-start"
                  type="button"
                  theme="danger"
                  onClick={handleOnDelete}
                >
                  Delete
                </Button>
              </div>
              <div>
                {isProcessingButtonVisible ? (
                  <ButtonClicked />
                ) : (
                  <Button theme="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fieldset>
    </Card>
  );
}
