import { useEffect, useMemo, useRef, useState, useDeferredValue } from "react";
import PropTypes from "prop-types";
import { ChevronUpIcon, ChevronDownIcon, XIcon } from "assets/icons";
import { Pagination } from "components/core";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import classNames from "classnames";
import { dateToString } from "helpers/dateUtilities";
import { BadgeSeverity } from "components/forms/BadgeSeverity";
import { compareStringOrNumber } from "helpers/comparisionUtilities";
import { useModal } from "context/ModalProvider";

const recordsPerPageOptions = [
  {
    value: 5,
    text: "5",
  },
  {
    value: 10,
    text: "10",
  },
  {
    value: 25,
    text: "25",
  },
  {
    value: 50,
    text: "50",
  },
  {
    value: 100,
    text: "100",
  },
];

function sortedDataInPlace(data, key, direction) {
  if (!key) return data;

  data.sort((a, b) => {
    const aValue = a[key]?.value ? a[key].value : a[key];
    const bValue = b[key]?.value ? b[key].value : b[key];

    return compareStringOrNumber(aValue, bValue)
  });

  if (direction === "desc") {
    data.reverse();
  }

  return data;
}

/**
 * TableChart
 * @param {Object} props
 * @param {String} props.id
 * @param {Array} props.keys
 * @param {String} props.keys.id
 * @param {String} props.keys.title
 * @param {Array} props.data
 * @param {Object} props.data
 */
export const TableChart = (props) => {
  const _sortable = props.settings?.sortable ?? true;
  const _showConfig = props.settings?.showConfig ?? true;

  const { setShowModal } = useModal();
  const [filter, setFilter] = useState("");
  const deferredFilter = useDeferredValue(filter);
  const [sortBy, setSortBy] = useState({
    key: props?.settings?.sortByKey,
    direction: props?.settings?.sortByDirection,
  });
  const [recordsPerPage, setRecordsPerPage] = useState(
    props.settings?.recordsPerPage ?? props.settings?.showPagination === false
      ? undefined
      : 5
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showPagination, setShowPagination] = useState(
    props.settings?.showPagination ?? true
  );
  const newScrollbarRef = useRef(undefined);
  const tableRef = useRef(undefined);
  const nativeScrollbarRef = useRef(undefined);

  const filteredData = useMemo(() => {
    const newFilteredData = props.data.filter((record) => {
      if (isNullEmptyOrWhitespace(deferredFilter)) return true;

      return Object.values(record).some((value) => {
        if (typeof value === "object" && value.value) {
          const valueAsString = value.value.toString();
          return valueAsString.toLowerCase().includes(deferredFilter.toLowerCase());
        }

        const valueAsString = value.toString();
        return valueAsString.toLowerCase().includes(deferredFilter.toLowerCase())
      });
    });

    return sortedDataInPlace(newFilteredData, sortBy.key, sortBy.direction);
  }, [props.data, sortBy?.key, sortBy?.direction, deferredFilter]);

  //#region Side-effects

  useEffect(() => {
    function setScrollbarTopWidth() {
      if (newScrollbarRef.current && tableRef.current) {
        //set scrollbar width to same as table width
        newScrollbarRef.current.style.width =
          tableRef.current.clientWidth + "px";
      }
    }

    function setNewScrollbarPosition() {
      if (newScrollbarRef.current && nativeScrollbarRef.current) {
        //set scrollbar position to same as table position
        nativeScrollbarRef.current.scrollLeft =
          newScrollbarRef.current.parentNode.scrollLeft;
      }
    }

    function setNativeScrollbarPosition() {
      if (newScrollbarRef.current && nativeScrollbarRef.current) {
        //set scrollbar position to same as table position
        newScrollbarRef.current.parentNode.scrollLeft =
          nativeScrollbarRef.current.scrollLeft;
      }
    }

    setScrollbarTopWidth();
    document.addEventListener("resize", () => {
      setScrollbarTopWidth();
    });

    newScrollbarRef.current.parentNode.addEventListener("scroll", () => {
      setNewScrollbarPosition();
    });

    nativeScrollbarRef.current.addEventListener("scroll", () => {
      setNativeScrollbarPosition();
    });
  }, []);

  useEffect(() => {
    if (props.settings?.showPagination !== undefined) {
      setShowPagination(props.settings.showPagination);
    }
  }, [props.settings?.showPagination]);

  /**
   * Set total pages
   */
  useDeepCompareEffectNoCheck(() => {
    if (!showPagination) return;

    const normalisedFilteredDataCount = filteredData?.length || 0;
    let newTotalPages =
      Math.ceil(normalisedFilteredDataCount / recordsPerPage) || 1;
    newTotalPages = newTotalPages > 0 ? newTotalPages : 1; // Ensure it is never zero
    setTotalPages(newTotalPages);
    setCurrentPage(1);
  }, [filteredData, recordsPerPage]);

  /**
   * Listen for records per page change
   */
  useEffect(() => {
    if (!showPagination) return;
    if (!props.settings?.recordsPerPage) return;

    setRecordsPerPage((prevState) =>
      prevState !== props.settings?.recordsPerPage
        ? props.settings.recordsPerPage
        : prevState
    );
  }, [props.settings?.recordsPerPage, showPagination]);

  //#endregion

  function onClickSortByKey(key) {
    const direction = sortBy.direction === "asc" ? "desc" : "asc";
    setSortBy({ key, direction });

    if (props.onSortBy) {
      props.onSortBy(key, direction);
    }
  }

  function onChangePage(page) {
    setCurrentPage(page);
  }

  function onChangeRecordsPerPage(value) {
    setRecordsPerPage(parseInt(value));
    setCurrentPage(1);
  }

  function onSelectedImage(imgSrc) {
    setShowModal(true, <img src={imgSrc} alt={imgSrc} />);
  }

  function getPageFirstRecordIndex() {
    if (!showPagination) return 0;
    return getPageEndRecordIndex() - recordsPerPage;
  }

  function getPageEndRecordIndex() {
    if (!showPagination) return filteredData.length;
    return currentPage * recordsPerPage;
  }

  function getPageRecordIndices() {
    return [getPageFirstRecordIndex(), getPageEndRecordIndex()];
  }

  return (
    <div className="flex flex-col relative">
      {/* Header */}
      {_showConfig && (
        <div className="flex flex-col tablet:flex-row py-2 laptop:py-4 space-x-4 items-center print:hidden">
          <div className="flex space-x-2 grow">
            {showPagination && (
              <SelectRecordsPerPage
                recordsPerPage={recordsPerPage}
                onChangeRecordsPerPage={onChangeRecordsPerPage}
              />
            )}
            <InputFilterBy filter={filter} setFilter={setFilter} />
          </div>
        </div>
      )}
      {/* Body */}
      <div className="w-full overflow-x-auto hidden tablet:block">
        <div ref={newScrollbarRef} className="h-px"></div>
      </div>
      <div
        ref={nativeScrollbarRef}
        className="overflow-x-auto print:overflow-visible print:m-0 print:p-0"
      >
        <div className="align-middle inline-block min-w-full print:m-0 print:p-0 print:block">
          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg print:overflow-visible print:m-0 print:p-0">
            <table
              ref={tableRef}
              className="min-w-full divide-y divide-gray-200 print:max-w-full"
            >
              <thead className="bg-gray-50">
                <tr>
                  {props.keys.map((key) => (
                    <th
                      key={key.title}
                      scope="col"
                      className={classNames(
                        "px-3 py-3.5 text-sm font-medium text-gray-700 print:px-2 min-w-[100px]",
                        key?.type === "number" ? "text-right" : undefined,
                        key?.type === "expression" ? "text-right" : undefined,
                        key?.type === "percent" ? "text-right" : undefined,
                        // key?.type === "currency" ? "text-right" : undefined,
                        key?.type === "" ? "text-left" : undefined
                      )}
                      onClick={() => onClickSortByKey(key.id)}
                    >
                      <div
                        className={classNames(
                          "inline-flex items-center",
                          _sortable && "cursor-pointer hover:text-primary"
                        )}
                      >
                        <div>{key.title}</div>
                        {_sortable &&
                          key.id === sortBy.key &&
                          sortBy.direction &&
                          (sortBy.direction === "asc" ? (
                            <div className="ml-2 flex-none text-gray-400 font-medium">
                              <ChevronUpIcon className="h-5 w-5" />
                            </div>
                          ) : (
                            <div className="ml-2 flex-none text-gray-400">
                              <ChevronDownIcon className="h-5 w-5" />
                            </div>
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 text-sm text-gray-900">
                {filteredData
                  ?.slice(...getPageRecordIndices())
                  ?.map((record, index) => (
                    <tr
                      key={record.key ?? record.id}
                      className={classNames(
                        index % 2 === 0 ? "bg-white" : "bg-gray-50",
                        record.onClick
                          ? "cursor-pointer hover:bg-gray-100"
                          : "",
                        props.onClickRow ? "cursor-pointer" : "",
                        "page-break"
                      )}
                      onClick={() => props.onClickRow && props.onClickRow(record)}
                    >
                      {props.keys.map((key, index) => {
                        const onClickEvent = key.events?.find(
                          (e) => e.type === "onclick"
                        );

                        return (
                          <TableRowCell
                            key={`${key.id}-${record.key ?? record.id}`}
                            index={index}
                            chartKey={key}
                            id={key.id}
                            record={record}
                            onSelectedImage={onSelectedImage}
                            onClick={
                              onClickEvent
                                ? () => {
                                    props.eventFunctions[onClickEvent.func](
                                      onClickEvent.title,
                                      record,
                                      onClickEvent.args
                                    );
                                  }
                                : undefined
                            }
                          />
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Footer */}
      {showPagination && totalPages > 1 && (
        <div className="p-4 flex-1 flex space-x-4 items-center print:hidden">
          <Pagination
            recordCount={filteredData.length}
            recordsPerPage={recordsPerPage}
            totalPages={totalPages}
            currentPage={currentPage}
            onChangePage={onChangePage}
          />
        </div>
      )}
    </div>
  );
};

function InputFilterBy({ filter, setFilter }) {
  return (
    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label
        htmlFor="table-filter"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Filter by...
      </label>
      <div className="relative">
        <input
          type="text"
          name="filterBy"
          id="filterBy"
          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm mt-1"
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          autoComplete="off"
        />
        {!!filter && (
          <div
            className="absolute inset-y-0 right-0 flex items-center cursor-pointer"
            onClick={() => setFilter("")}
          >
            <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  );
}

function SelectRecordsPerPage({ recordsPerPage, onChangeRecordsPerPage }) {
  return (
    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label
        htmlFor="table-filter"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Records per page...
      </label>
      <select
        id="table-records-per-page"
        className="block w-full border-0 py-0 px-2 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm mt-1"
        value={recordsPerPage}
        onChange={(ev) => onChangeRecordsPerPage(ev.target.value)}
        style={{ minWidth: "180px" }}
      >
        {recordsPerPageOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
}

function TableRowCell({
  id,
  index,
  record,
  relatedData,
  chartKey,
  onSelectedImage,
  onClick,
}) {
  if (record[id] instanceof Function) return null;

  if (
    !isNull(id) &&
    !isNull(record) &&
    id === "images" &&
    record[id]?.length > 0
  ) {
    return (
      <td
        key={id}
        className={classNames(
          index === 0 ? "pl-4 pr-2" : "px-2",
          "whitespace-nowrap text-sm text-gray-900",
          record[id]?.length > 1 ? "py-2" : "py-4",
          onClick ? "cursor-pointer hover:bg-gray-100" : ""
        )}
      >
        <div className="shrink-0 sm:mt-0 sm:ml-5">
          <div className="flex overflow-hidden -space-x-1">
            {record[id].map((img) => (
              <img
                key={img}
                className="inline-block h-8 w-8 rounded-full border border-gray-300 ring-2 ring-white cursor-pointer hover:border-primary relative hover:z-10"
                src={img}
                alt={img}
                onClick={() => onSelectedImage(img)}
              />
            ))}
          </div>
        </div>
      </td>
    );
  }

  function formatValue(value) {
    let result = value;

    if (isNullEmptyOrWhitespace(result)) {
      result = "-";
    } else if (chartKey?.type === "date") {
      result = dateToString(result);
    } else if (chartKey?.type === "status" && value?.Text !== undefined) {
      result = (
        <BadgeSeverity severity={value?.Severity}>{value.Text}</BadgeSeverity>
      );
    } else if (chartKey?.type === "expression") {
      result = <span style={{ ...value.style }}>{value.value}</span>;
    } else if (chartKey?.type === "percent") {
      result = `${value.replace("%", "")}%`;
    } /* else if (chartKey?.type === "currency") {
      // TODO: we currently don't have a way to know the currency symbol
      result = value
    } */

    return (
      <div
        className={classNames(
          chartKey?.type === "number" ? "text-right" : undefined,
          chartKey?.type === "expression" ? "text-right" : undefined,
          chartKey?.type === "percent" ? "text-right" : undefined,
          chartKey?.type === "currency" ? "text-right" : undefined,
          chartKey?.type === "date" ? "whitespace-nowrap" : "",
          typeof value === "string" && value.length > 100 ? "whitespace-normal" : "whitespace-nowrap"
        )}
      >
        {result}
      </div>
    );
  }

  return (
    <td
      key={id}
      className={classNames(
        index === 0 ? "pl-4 pr-2" : "px-3",
        "py-4 print:whitespace-normal print:pl-2 align-top",
        onClick ? "cursor-pointer hover:text-primary hover:underline" : ""
      )}
      onClick={(ev) => {
        onClick && onClick(ev, record);
      }}
    >
      {formatValue(record[id])}
      {relatedData &&
        Object.values(relatedData).map((data) => (
          <div>{JSON.stringify(data)}</div>
        ))}
    </td>
  );
}

TableChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  sortBy: PropTypes.shape({
    key: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
};
