import { Select } from "components/core";
import { IForm } from "helpers/formUtilities";

interface ReportFormSelectorProps {
  selectedFormId: string | null;
  onSelectReportId: (id: any) => void;
  forms: IForm[];
}

const ReportFormSelector: React.FC<ReportFormSelectorProps> = ({
  selectedFormId,
  onSelectReportId,
  forms: filteredForms,
}) => {
  return (
    <Select
      label="Report"
      listOptions={filteredForms.map(
        (form: { FormTitle: string; FormName: string }) => ({
          Id: form.FormName,
          Text: form.FormTitle,
          Value: form.FormName,
        })
      )}
      id="report"
      hint={undefined}
      value={selectedFormId}
      setValue={onSelectReportId}
      labelPosition="inset"
    />
  );
};

export default ReportFormSelector;
