import { createContext, useContext, useEffect, useRef } from "react";
import { useUser } from "context/AppDataProvider";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const { onSignIn, onSignOut } = useUser();

  const abortControllerRef = useRef(undefined);

  //#region side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //#endregion

  /**
   *
   * @param {*} username
   * @param {*} password
   * @param {*} callback
   * @returns
   */
  const signin = async (username, password, callback) => {
    const { signal } = abortControllerRef.current;

    try {
      const response = await fetch(`/api/signin`, {
        signal,
        method: "POST",
        body: JSON.stringify({ username, password }),
      });
      const responseBody = await response.json();
      if (signal.aborted)
        return;

      if (responseBody.isAuthenticated) {
        onSignIn(responseBody.user);
      }
      if (callback)
        callback(responseBody);
    } catch (err) {
      if (signal.aborted)
        return;

      console.error(err);
    }
  };

  const signout = async (cb) => {
    const { signal } = abortControllerRef.current;
    try {
      const res = await fetch(`/api/signout`, {
        signal,
        method: "POST",
      });
      const json = await res.json();
      if (signal.aborted)
        return;
      if (json.status === 200) {
        onSignOut();
      }
      if (cb)
        cb(json);
    } catch (err) {
      if (signal.aborted)
        return;

      console.error(err);
    }
  };

  return {
    signin,
    signout,
  };
}
