import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListItem } from "components/core";
import { dateToString } from "helpers/dateUtilities";
import ListItemIcon from "./ListItemIcon";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { FlyoutMenu } from "components/core/FlyoutMenu";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { DATA_STATUS } from "constants.js";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useAppConfig } from "context/AppDataProvider";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function ListItemHistorical({
  farmId,
  houseId,
  formValues,
  childFormValues,
  form,
  dateToday,
  children,
  className,
  ...other
}) {
  let navigate = useNavigate();
  const { activeMenu } = useActiveMenu();
  const { config } = useAppConfig();

  const [displayValues, setDisplayValues] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [listItems, setListItems] = useState([]);

  //#region Callbacks

  /**
   * Handle list item click
   */
  const handleClick = (
    id,
    formName,
    parentId = undefined,
    parentFormId = undefined,
    view = undefined
  ) => {
    return navigate(
      buildFormPageUrl(
        activeMenu,
        formName,
        parentId,
        parentFormId,
        id,
        view
      )
    );
  };

  function getRecordID(data) {
    if (isNullEmptyOrWhitespace(data)) return null;

    return data.ID ?? getRecordPWAID(data);
  }

  function getRecordPWAID(data) {
    return data?.PenValues?.find(
      (pv) => pv.Pen.toString() === "1"
    )?.Values?.find((fv) => fv.Ref.toLowerCase() === "pwaid")?.Value;
  }

  //#endregion

  //#region Side-effects

  /**
   * Set columns
   */
  useEffect(() => {
    if (!form?.FormFields?.length || isNullEmptyOrWhitespace(formValues))
      return;

    const pen1Values = formValues?.PenValues?.find(
      (pv) => pv.Pen.toString() === "1"
    )?.Values;

    const values = [];
    form.FormFields.forEach((ff) => {
      // E.g. { "list": { "position": 1 }}
      if (!isNullEmptyOrWhitespace(ff.Display?.list?.position)) {
        const formValue = pen1Values?.find(
          (fv) => fv.Ref.toLowerCase() === ff.Ref.toLowerCase()
        );
        if (!!formValue) {
          if (!!ff.ListOptions?.length) {
            // Has list options show 'Text' rather than value
            const mappedValue = ff.ListOptions.find(
              (o) => o.Value.toString() === formValue.Value.toString()
            );
            if (mappedValue)
              values.push({ label: ff.Name, value: mappedValue.Text });
          } else {
            values.push({ label: ff.Name, value: formValue.Value });
          }
        }
      }
    });

    setDisplayValues(values);
  }, [form, formValues]);

  /**
   * Set loaded
   */
  useEffect(() => {
    if (!formValues?.DateApplies) return;

    setLoaded((currentState) => (currentState === false ? true : currentState));
  }, [formValues?.DateApplies]);

  /**
   * Set list items
   */
  useDeepCompareEffect(() => {
    if (
      form?.FormType?.toLowerCase() !== "audit" ||
      isNullEmptyOrWhitespace(formValues?.FormName) ||
      isNullEmptyOrWhitespace(formValues?.Status)
    )
      return;

    const _listItems = [];
    if (
      config.enableNonconformance === "true" &&
      (formValues.Status === DATA_STATUS.COMPLETE ||
        formValues.Status === DATA_STATUS.PENDING_COMPLETE)
    ) {
      const recordId = getRecordID(formValues);
      const recordPWAID = getRecordPWAID(formValues);

      _listItems.push({
        id: `${recordId}_nonconformance`,
        formId: "nonconformance",
        name: "Non-Conformance",
        onClick: () =>
          handleClick(
            recordId,
            `ncn_${form.FormName}`,
            recordPWAID,
            formValues.FormName
          ),
      });

      _listItems.push({
        id: `${recordId}_report`,
        formId: formValues.FormName,
        name: "Report",
        onClick: () =>
          handleClick(
            recordId,
            formValues.FormName,
            null,
            null,
            "report"
          ),
      });
    }

    setListItems(_listItems);
  }, [
    childFormValues,
    formValues,
    form?.FormType,
    form?.FormName,
    config?.enableNonconformance,
  ]);

  //#endregion

  if (!loaded) {
    return (
      <ListItem className="animate-pulse" aria-label="daily">
        <div className="h-10 w-10 mr-2 rounded-full bg-gray-300"></div>
        <div className="flex-grow">
          <div className="mb-0.5 h-4 w-1/4 bg-gray-300 rounded"></div>
          <div className="text-xs text-gray-500">
            <div className="flex">
              <div className="h-2 w-1/2 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      </ListItem>
    );
  }

  if (
    config.enableNonconformance === "true" &&
    form?.FormType?.toLowerCase() === "audit"
  ) {
    return (
      <FlyoutMenu
        key="flyout-menu"
        {...other}
        as={ListItemFlyMenu}
        className={classNames(className, "block justify-end cursor-pointer")}
        buttonAs="div"
        placement="bottom-end"
        buttonContent={(open) => (
          <div
            className={classNames(
              open ? "text-gray-900" : "text-gray-500",
              "min-w-0 flex-1 flex items-center flex-grow"
            )}
          >
            <ListItemIcon
              key="statusIcon"
              dataStatus={formValues?.Status}
              date={formValues._DateApplies.normalised}
              dateToday={dateToday}
              loaded={!!formValues?.Status}
            />
            <div className="flex-grow">
              <div className="tablet:flex tablet:space-x-2">
                {form?.FormTitle ? (
                  <div className="font-medium">{form.FormTitle}</div>
                ) : (
                  <div className="animate-pulse flex space-x-4 items-center">
                    <div className="h-3 w-16 bg-gray-300 rounded-full"></div>
                  </div>
                )}
                <div className="text-sm text-gray-900" data-cy="date-range">
                  {dateToString(formValues._DateApplies.normalised, {
                    includeTime: false,
                  })}
                </div>
              </div>
              <div className="flex text-sm">
                <ListItemStatus
                  dataStatus={formValues?.Status}
                  dateToday={dateToday}
                />
              </div>
              {formValues?._LastModified && (
                <div className="text-xs text-gray-400">
                  <div className="flex space-x-2">
                    <div>
                      Updated:{" "}
                      {dateToString(formValues?._LastModified.localised, {
                        includeTime: true,
                      })}
                    </div>
                    {displayValues.map((dv) => (
                      <div key={dv.label}>
                        {dv.label}: {dv.value}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <DotsVerticalIcon
              key="dotsIcon"
              className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </div>
        )}
      >
        <List size="small">
          <ListItem
            key="edit"
            onClick={() =>
              handleClick(
                getRecordID(formValues),
                form.FormName,
              )
            }
          >
            Edit {form?.FormTitle}
          </ListItem>
          {listItems.map((li) => (
            <ListItem key={li.id} onClick={li.onClick}>{li.name}</ListItem>
          ))}
        </List>
      </FlyoutMenu>
    );
  } else {
    return (
      <ListItem
        className={classNames(className)}
        onClick={() =>
          handleClick(
            getRecordID(formValues),
            form.FormName
          )
        }
        {...other}
      >
        <div className="min-w-0 flex-1 flex items-center">
          <ListItemIcon
            key="statusIcon"
            dataStatus={formValues?.Status}
            date={formValues._DateApplies.normalised}
            dateToday={dateToday}
            loaded={!!formValues?.Status}
          />
          <div className="flex-grow">
            <div className="tablet:flex tablet:space-x-2">
              {form?.FormTitle ? (
                <div className="font-medium">{form.FormTitle}</div>
              ) : (
                <div className="animate-pulse flex space-x-4 items-center">
                  <div className="h-3 w-16 bg-gray-300 rounded-full"></div>
                </div>
              )}
              <div className="text-sm text-gray-900" data-cy="date-range">
                {dateToString(formValues._DateApplies.normalised, {
                  includeTime: false,
                })}
              </div>
            </div>
            <div className="flex text-sm">
              <ListItemStatus
                dataStatus={formValues?.Status}
                dateToday={dateToday}
              />
            </div>
            <div className="mb-0.5">
              {dateToString(formValues._DateApplies.normalised, {
                includeTime: false,
              })}
            </div>
            {formValues?._LastModified && (
              <div className="text-xs text-gray-400">
                <div className="flex space-x-2">
                  <div>
                    Updated:{" "}
                    {dateToString(formValues?._LastModified.localised, {
                      includeTime: true,
                    })}
                  </div>
                  {displayValues.map((dv) => (
                    <div key={dv.label}>
                      {dv.label}: {dv.value}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </ListItem>
    );
  }
}


const ListItemFlyMenu = React.forwardRef((prop, ref) => {
  return <ListItem innerRef={ref} {...prop} />;
});