import React from "react";
export default function ScheduleItemSkeleton() {
  return (
    <div className="flex items-center px-2 py-2">
      <div className="h-10 w-10 mr-2 rounded-full bg-gray-300" />
      <div className="flex-grow">
        <div className="mb-0.5 h-4 w-1/4 bg-gray-300 rounded" />
        <div className="text-xs text-gray-500">
          <div className="flex">
            <div className="h-2 w-1/2 bg-gray-300 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
}
