import Breadcrumb from "components/Breadcrumb";
import { List, ListItem } from "components/core";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AppDataContext } from "context/AppDataProvider";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import ButtonNew from "components/Historical/ButtonNew";
import Card from "components/Card";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function ListPage() {
  const navigate = useNavigate();
  const { activeMenu } = useActiveMenu();

  const { dashboards, refetchDashboards } = useContext(AppDataContext);

  /**
   * Refetch dashboards to update cache
   */
  useEffect(() => {
    refetchDashboards();
  }, [refetchDashboards]);

  //#region Event Handlers

  function handleButtonNewClick(formType, formName) {
    return navigate(buildFormPageUrl(activeMenu, formName));
  }

  //#endregion

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          showHome={false}
          menuRequired={true}
          farmRequired={false}
          houseRequired={true}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              Dashboards
            </div>
            <ButtonNew
              className="justify-end"
              onClick={handleButtonNewClick}
              options={
                [{
                  FormName: "builder",
                  FormTitle: "New Dashboard",
                  FormType: activeMenu?.ModuleFeatureGroup,
                }]
              }
            />
          </div>
        </div>
        <div className="col-span-full">
          <Card>
            {dashboards?.length > 0 ? (
              <List theme="striped" size="small" className="-mx-4">
                {dashboards.map((dashboard) => (
                  <DashboardListItem
                    key={dashboard.id}
                    dashboard={dashboard}
                    formId="core"
                  />
                ))}
              </List>
            ) : (
              <div className="flex flex-grow items-center justify-center">
                <div className="flex-grow items-center justify-center text-sm text-center italic">
                  <div className="p-2">
                    <div>
                      <p>No records found.</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </main>
  );
}

const DashboardListItem = ({ dashboard, formId, ...other }) => {
  const navigate = useNavigate();
  const { activeMenu } = useActiveMenu();

  //#region Handlers

  /**
   * Handle list item click
   */
  const handleClick = (id) => {
    return navigate(
      buildFormPageUrl(activeMenu, formId, null, null, id)
    );
  };

  //#endregion

  return (
    <ListItem {...other} onClick={() => handleClick(dashboard.id)}>
      <div className="min-w-0 flex-1 flex items-center">
        {/* <ListItemIcon
          dataStatus={formValues?.Status}
          date={formValues.DateApplies}
          dateToday={dateToday}
          loaded={!!formValues?.Status}
        /> */}
        <div className="flex-grow">
          <div className="mb-0.5 text-primary font-medium">
            {dashboard.title}
          </div>

          <div className="text-xs text-gray-500">
            <div className="flex space-x-2">
              {dashboard.farmGroups && (
                <div>Farm group(s): {dashboard.farmGroups}</div>
              )}
              {dashboard.dataSources && (
                <div>Data source(s): {dashboard.dataSources}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};
