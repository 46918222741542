import React from "react";
import { Label } from "components/core";
import classNames from "classnames";

const getActiveThemeClass = (activeTheme) => {
  // eslint-disable-next-line default-case
  switch (activeTheme) {
    case "primary":
      return "bg-primary bg-gradient-to-r from-primary to-secondary border-primary ring-4 ring-primary/10 hover:bg-primary-dark text-white focus:ring-primary z-10";
    case "secondary":
      return "bg-secondary bg-gradient-to-r from-secondary-lightest to-secondary-lighter border-secondary-light ring-4 ring-secondary-light/10 hover:bg-secondary-dark text-secondary z-10";
    case "success":
      return "bg-success-50 bg-gradient-to-r from-success-50 via-success-50 to-success-100 border-success-400 ring-4 ring-success-500/10 hover:bg-success-50 text-success-600 z-10";
    case "warning":
      return "bg-warning-50 bg-gradient-to-r from-warning-50 via-warning-50 to-warning-100 border-warning-400 ring-4 ring-warning-500/10 hover:bg-warning-50 text-warning-600 0 z-10";
    case "danger":
      return "bg-danger-50 bg-gradient-to-r from-danger-50 via-danger-50 to-danger-100 border-danger-400 ring-4 ring-danger-500/10 hover:bg-danger-50 text-danger-600 z-10";
    case "gray":
      return "bg-gray-100 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 border-gray-400 ring-4 ring-gray-500/10 hover:bg-gray-100 text-gray-600 z-10";
    default:
      return "bg-white border-gray-400 ring-4 ring-gray-500/10 text-gray-700 hover:bg-gray-50 focus:border-gray-400 z-10";
  }
}

export function ButtonGroup({
  id,
  label,
  labelSize,
  labelPosition,
  required,
  listOptions,
  active,
  onClick,
}) {

  return (
    <div>
      {label && (
        <Label
          id={id}
          text={label}
          position={labelPosition}
          size={labelSize}
          required={required}
        />
      )}
      <span className="isolate inline-flex rounded-md shadow-sm mt-2">
        {listOptions?.map(({ Id, Text, Value, activeTheme, icon, iconPosition }, index) => (
          // <Button
          //   {...other}
          //   key={Id}
          //   aria-label={Text}
          //   theme={active === Value ? activeTheme : "gray"}
          //   onClick={() => onClick(Value)}
          // >
          //   {Text}
          // </Button>

            <button
              type="button"
              onClick={() => onClick(Value)}
              className={
                classNames(
                  index !== 0 ? '-ml-px' : '',
                  index === 0 ? 'rounded-l-md' : '',
                  index === listOptions.length - 1 ? 'rounded-r-md' : '',
                  active === Value ? getActiveThemeClass(activeTheme) : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:border-gray-400 focus:ring-gray-400',
                  "relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none"
                )
              }
            >
              {iconPosition !== "right" && icon}
              {Text}
              {iconPosition === "right" && icon}
            </button>
        ))}
      </span>
    </div>
  );
}
