import React, { useState, useEffect, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import { Label } from "components/core";
import Addon from "./Addon";
import { ExclamationCircleIcon, XIcon } from "assets/icons";
import { SelectOption } from "./SelectOption";
import { isNullEmptyOrWhitespace, isNumeric } from "helpers/stringUtilities";
import { padNumber } from "helpers/dateUtilities";
import useCalcTrigger from "hooks/useCalcTrigger";

function formatValue(value) {
  if (isNullEmptyOrWhitespace(value)) return value;

  const timeSplit = value.split(":");
  const hour = padNumber(timeSplit[0]);
  const minute = padNumber(timeSplit[1]);

  return `${hour}:${minute}`;
}

const TimePicker = ({
  label,
  id,
  hint,
  validate,
  value,
  setValue,
  setValid,
  required = true,
  isFullWidth = false,
  disabled = false,
  labelPosition = "top",
  addonLeft,
  addonRight,
  dependencies = [],
  theme,
  defaultValue,
  render,
  minuteStep = 5,
  labelSize,
  disableCalcTrigger,
  ...other
}) => {
  useCalcTrigger(formatValue(value), setValue, disableCalcTrigger);

  const hasDefaultValueRef = useRef(false);

  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const [focused, setFocused] = useState(false);

  const [hours, setHours] = useState(
    value?.length
      ? value.split(":").map((t) => (isNumeric(t) ? t : ""))
      : ""
  );
  const [minutes, setMinutes] = useState(
    value?.length
      ? value.split(":").map((t) => (isNumeric(t) ? t : ""))
      : ""
  );
  const hasValue = value?.length > 0;

  const inputErrorClasses = ["border-danger-600"];
  const inputClasses = [
    "rounded-md pt-3 pb-3",
    "border",
    "focus:ring-4 focus:ring-offset-0",
    `${disabled ? "disabled:opacity-50" : ""}`,
  ];
  const errorIconClasses = [];

  // Addons
  if (addonLeft) {
    inputClasses.push("pl-10");
  }
  if (addonRight) {
    inputClasses.push("pr-10");
  }

  // Theme
  if (theme === "darkgray") {
    inputClasses.push(
      "bg-gray-900 border-gray-500 text-gray-400 focus:border-gray-400 focus:ring-gray-800"
    );
    errorIconClasses.push("bg-gray-900");
  } else {
    inputClasses.push(
      "bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
    );
    errorIconClasses.push("bg-white");
  }

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      setValue(value);
    },
    [setValue]
  );

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    if (validate) return validate(value);
  };

  /**
   * Handle change hours
   * @param {string|number} _hours
   */
  const handleChangeHours = (_hours) => {
    const _minutes = minutes || "00";

    setInputValue(`${_hours}:${_minutes}`);
  };

  /**
   * Handle change minutes
   * @param {string|number} _minutes
   */
  const handleChangeMinutes = (_minutes) => {
    const _hours = hours || "00";

    setInputValue(`${_hours}:${_minutes}`);
  };

  //#endregion

  //#region Side-effects

  useEffect(() => {
    const [hours, minutes] = value?.length
      ? value.split(":").map((t) => (isNumeric(t) ? t : ""))
      : ["", ""];

    setHours(hours);
    setMinutes(minutes);
  }, [value]);

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult);

    const complete = required && isNullEmptyOrWhitespace(value) ? false : true;
    if (setValid) setValid(!validationResult, value, required, complete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value, required]);

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (
      touched ||
      !defaultValue ||
      !setInputValue ||
      hasDefaultValueRef.current
    )
      return;

    if (isNullEmptyOrWhitespace(value)) {
      setInputValue(defaultValue);
      hasDefaultValueRef.current = true;
    }
  }, [value, defaultValue, setInputValue, touched]);

  //#endregion

  const clearValue = () => {
    setInputValue("");
    setHours("");
    setMinutes("");
  };

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${
        labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative"
      }`}
    >
      <Label
        id={id}
        text={label}
        required={required}
        focused={focused}
        hasValue={hasValue}
        position={labelPosition}
        theme={theme}
        erroneous={touched && error && !focused}
        size={labelSize}
      />
      <div className={`mt-1 ${labelPosition === "left" ? "col-span-2" : ""}`}>
        <div
          className={`block w-full tablet:text-sm relative ${inputClasses.join(
            " "
          )} ${
            touched && error && !focused ? inputErrorClasses.join(" ") : ""
          }`}
        >
          {addonLeft && <Addon position="left">{addonLeft}</Addon>}
          <div className="inline-flex items-center">
            <select
              data-cy="input-hour"
              id={id}
              className={`block w-full tablet:text-sm border-0 mx-0 pr-4 py-0 appearance-none bg-none bg-transparent focus:border-0 focus:outline-none focus:ring-0 cursor-pointer`}
              value={hours}
              onBlur={() => {
                setTouched(true);
                setFocused(false);
              }}
              onChange={(ev) => handleChangeHours(ev.target.value)}
              onFocus={() => setFocused(true)}
              aria-invalid={error}
              disabled={disabled}
              {...other}
            >
              {isNullEmptyOrWhitespace(hours) && (
                <SelectOption>--</SelectOption>
              )}
              {Array.from({ length: 24 }, (_, i) => i).map((h) => (
                <SelectOption key={`option-${h}`} value={padNumber(h, 2)}>
                  {padNumber(h, 2)}
                </SelectOption>
              ))}
            </select>
            <span className="">:</span>
            <select
              data-cy="input-minute"
              id={id}
              className={`block w-full tablet:text-sm border-0 mx-0 pr-4 py-0 appearance-none bg-none bg-transparent focus:border-0 focus:outline-none focus:ring-0 cursor-pointer`}
              value={minutes}
              onBlur={() => {
                setTouched(true);
                setFocused(false);
              }}
              onChange={(ev) => handleChangeMinutes(ev.target.value)}
              onFocus={() => setFocused(true)}
              aria-invalid={error}
              disabled={disabled}
              {...other}
            >
              {isNullEmptyOrWhitespace(minutes) && (
                <SelectOption>--</SelectOption>
              )}
              {Array.from(
                { length: 60 / minuteStep },
                (_, i) => i * minuteStep
              ).map((m) => (
                <SelectOption key={`option-${m}`} value={padNumber(m, 2)}>
                  {padNumber(m, 2)}
                </SelectOption>
              ))}
            </select>
          </div>
          {addonRight && <Addon position="right">{addonRight}</Addon>}
          {touched && error && !focused && (
            <div className="absolute -top-2 right-2 pointer-events-none bg-white px-1">
              <ExclamationCircleIcon className="h-5 w-5 text-danger-500" />
            </div>
          )}
          {!isNullEmptyOrWhitespace(value) && (
            <div
              className="absolute right-10 bottom-3.5 cursor-pointer group"
              onClick={clearValue}
            >
              <XIcon
                className="h-5 w-5 text-gray-400 group-hover:text-primary"
                title="clear value"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {touched && error && !focused ? (
          <p className="mt-2 text-xs text-danger-600" id="email-error">
            {error}
          </p>
        ) : (
          hint && (
            <p className="mt-2 text-xs text-gray-500" id="email-error">
              {hint}
            </p>
          )
        )}
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hint: PropTypes.string,
  validate: PropTypes.func,
  type: PropTypes.oneOf([
    "text",
    "email",
    "password",
    "number",
    "url",
    "date",
    "datetime-local",
    "month",
    "week",
    "time",
    "search",
    "tel",
    "checkbox",
    "radio",
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  labelPosition: PropTypes.string,
  addonLeft: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  addonRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dependencies: PropTypes.array,
  render: PropTypes.bool,
  minuteStep: PropTypes.number,
};

export { TimePicker };
