import { Alert, Input, MultiSelect, Select } from "components/core";
import { IDashboard } from "../types";
import { useState, useRef, useEffect, useMemo } from "react";
import { IForm } from "helpers/formUtilities";
import { useMenus } from "context/AppDataProvider";
import { IMenuItem } from "components/Sidebar";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";

interface IDashboardFormProps {
  dashboard: IDashboard | undefined
  forms: IForm[];
  onChangeTitle: (title: string) => void;
  onChangeFarmGroups: (farmGroups: string) => void;
  onChangeDataSource: (datasource: string) => void;
  onChangeSortOrder: (sortOrder: number) => void;
  onChangeMenu: (menuId: string) => void;
}

async function fetchFarmGroups(signal?: AbortSignal) {
  let result: { data: any; error: string } = { data: null, error: "" };

  try {
    const response = await fetch(`/api/farmgroups-get`, {
      signal,
      method: "GET",
    });
    if (response.ok) {
      const responseData = await response.json();

      result.data = responseData;
    } else {
      result.error = "An error occurred while fetching farm groups.";
    }
  } catch (err) {
    if (!signal?.aborted) {
      console.error(err);

      result.error = "An error occurred while fetching farm groups.";
    }
  }
  
  return result;
}

export function DashboardForm(props: IDashboardFormProps) {
  const { menus } = useMenus();

  const [farmGroups, setFarmGroups] = useState<string[] | undefined | null>(
    undefined
  );

  const [error, setError] = useState<string>("");

  const abortControllerRef = useRef<AbortController | undefined>(undefined);

  const datasourceListOptions = props.forms.map((form) => ({
    Id: form.FormName,
    Text: form.FormTitle,
    Value: form.FormName,
  }));

  const farmGroupListOptions = farmGroups?.map((fg) => ({
    Id: fg,
    Text: fg,
    Value: fg,
  }));

  // TODO: Implement user groups
  // const userGroupListOptions = userGroups.map((fg) => ({
  //   Id: fg,
  //   Text: fg,
  //   Value: fg,
  // }));

  const menuListOptions = useMemo(() => {
    const result = [];
    for (const parentMenu of menus.filter((m) => m.Children)) {
      const children = parentMenu.Children as IMenuItem[];

      for (const menu of children) {
        result.push({
          Id: menu.ID,
          Text: `${parentMenu.Title} > ${menu.Title}`,
          Value: menu.ID,
        });
      }
    }

    return result;
  }, [menus]);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    fetchFarmGroups(abortControllerRef.current.signal).then((result) => {
      if (result.error) {
        setError(result.error);
      } else {
        setFarmGroups(result.data);
      }
    });

    return () => {
      if (abortControllerRef.current) abortControllerRef.current.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = farmGroupListOptions === undefined;

  if (isLoading) {
    return (
      <div className="space-y-4 mt-4">
        <FieldsetSkeleton />
      </div>
    );
  }

  return (
    <div className="space-y-4 mt-4">
      {error && <Alert theme="danger">{error}</Alert>}
      <Input
        id="title"
        label="Dashboard Title"
        placeholder="Enter a title for the dashboard"
        type="text"
        value={props.dashboard?.title ?? ""}
        labelPosition="top"
        setValue={props.onChangeTitle}
        disableCalcTrigger={true}
      />
      <Select
        id="datasource-select"
        label="Data Source"
        listOptions={datasourceListOptions}
        hint={undefined}
        value={props.dashboard?.dataSources ?? ""}
        setValue={props.onChangeDataSource}
        labelPosition="inset"
      />
      <Input
        id="sortOrder"
        label="Sort order"
        type="number"
        value={props.dashboard?.sortOrder}
        placeholder="0"
        labelPosition="top"
        setValue={props.onChangeSortOrder}
        disableCalcTrigger={true}
      />

      <div className="p-4 border border-gray-300 rounded-md space-y-4">
        <div className="text-sm font-medium">Filter by...</div>
        <p className="text-xs italic text-gray-500">
          The following selections will dictate where the dashboard will appear.
        </p>

        <MultiSelect
          label="Farm group(s)"
          id="farmgroups-multiselect"
          setValue={props.onChangeFarmGroups}
          value={props.dashboard?.farmGroups ?? ""}
          listOptions={farmGroupListOptions}
          disableCalcTrigger={true}
        />

        {menus?.length > 0 && (
          <Select
            label="Page(s)"
            id="menus-select"
            setValue={props.onChangeMenu}
            value={props.dashboard?.menuId ?? ""}
            listOptions={menuListOptions}
            disableCalcTrigger={true}
          />
        )}
      </div>
      
    </div>
  );
}
