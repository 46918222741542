import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

export function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== "test") {
      // Don't run during tests
      ReactGA.pageview(location.pathname);
    }
  }, [location]);
}
