import { Badge } from "components/core";
import { useEffect, useState } from "react";

export function BadgeSeverity({ severity, children, ...other }) {
  const [theme, setTheme] = useState(undefined);

  useEffect(() => {
    switch (severity) {
      case 4:
        setTheme("secondary");
        break;
      case 3:
        setTheme("danger");
        break;
      case 2:
        setTheme("warning");
        break;
      case 1:
        setTheme("success");
        break;
      case 0:
        setTheme("gray");
        break;
      default:
        setTheme(undefined);
    }
  }, [severity]);


  return (
    <Badge {...other} theme={theme}>
      {children}
    </Badge>
  );
}
