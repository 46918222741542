import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import {
  startOfLastXDays,
  dateDiffInDays,
  localDate,
} from "helpers/dateUtilities";
import { AppDataContext, useFarms } from "context/AppDataProvider";
import Breadcrumb from "components/Breadcrumb";
import ScheduleItem from "components/Schedule/ScheduleItem";
import ScheduleItemSkeleton from "components/Schedule/ScheduleItemSkeleton";
import useQuery from "hooks/useQuery";
import useDeepCompareEffect from "use-deep-compare-effect";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import SectionTitle from "components/SectionTitle";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function Schedule() {
  const { activeMenu } = useActiveMenu();
  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;
  const query = useQuery();

  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const { forms, schedules, fetchFormValues } =
    useContext(AppDataContext);
  const { farms } = useFarms();

  const [dates, setDates] = useState([]);
  const [entries, setEntries] = useState(undefined);
  const [schedule, setSchedule] = useState([]);

  const abortControllerRef = useRef(undefined);

  const filteredForms = useMemo(
    () =>
      forms.filter(
        (f) =>
          f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
          f.ModuleName?.toLowerCase() === module?.toLowerCase()
      ),
    [forms, module, moduleFeatureGroup]
  );

  const farm = useMemo(
    () => farms.find((f) => f.FarmCode.toLowerCase() === farmId?.toLowerCase()),
    [farms, farmId]
  );

  const placement = useMemo(() => {
    if (farm === undefined) {
      return undefined;
    }

    const house = farm.Houses.find(
      (h) => h.HouseNumber.toString().toLowerCase() === houseId?.toLowerCase()
    );
    if (house === undefined) {
      return undefined;
    }

    const pen = house.Pens.find((p) => !isNullEmptyOrWhitespace(p.Placement));

    return pen.Placement;
  }, [farm, houseId]);

  //#region side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController(); // Should always come first

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Set production entries
  useDeepCompareEffect(() => {
    if (!farmId || !houseId || !filteredForms?.length) {
      setEntries(undefined);
      return;
    }

    const getProductionData = () => {
      if (!farmId || !houseId || !fetchFormValues) return;

      const { signal } = abortControllerRef.current;

      const formIds = filteredForms
        .filter((f) => f.HasFormValues)
        .map((f) => ({
          formId: f.FormName,
          formType: f.FormType,
          moduleId: f.ModuleName,
        }));

      fetchFormValues(farmId, houseId, formIds, signal)
        .then((result) => {
          if (signal.aborted) return;

          setEntries(result?.flat() ?? []);
        })
        .catch((error) => {
          if (signal.aborted) return;

          console.error(error.message);
        });
    };

    getProductionData();
  }, [farmId, houseId, fetchFormValues, filteredForms]);

  // Set schedule
  useEffect(() => {
    if (!farmId || !houseId) return;

    setSchedule(
      schedules.filter(
        (schedule) =>
          schedule.FarmCode.toLowerCase() === farmId.toLowerCase() &&
          schedule.HouseNumber.toString() === houseId.toString()
      )
    );
  }, [farmId, houseId, schedules]);

  // Set dates
  useEffect(() => {
    let numDays = dateDiffInDays(
      placement?._DatePlaced?.normalised,
      localDate()
    );

    numDays = numDays > 0 ? Math.floor(numDays) : 0;

    if (numDays > 20) {
      numDays = 20;
    }

    setDates(startOfLastXDays(numDays));
  }, [placement]);

  // //#endregion

  // Only show 20 days production. Extra day is for previous values
  const datesToRender = dates.length > 20 ? dates.slice(0, 20) : dates;

  return (
    <main className="flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          showHome={false}
          farmRequired={true}
          houseRequired={true}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>{activeMenu?.Title !== undefined ? activeMenu.Title : <div className="w-1/3"><FieldSkeleton /></div> }</SectionTitle>
        </div>
        <div className="col-span-full space-y-4">
          {entries !== undefined ? (
            datesToRender.map((dateString) => (
              <ScheduleItem
                key={dateString}
                date={dateString}
                placement={placement}
                farm={farm}
                entries={entries}
                schedule={schedule}
              />
            ))
          ) : (
            <SchedulePageSkeleton />
          )}
        </div>
      </div>
    </main>
  );
}

// Current unused - may be useful in future release
// /**
//  * Get the data status by comparing the local and network data.
//  * @param {object} localData  - The data object stored locally.
//  * @param {object} networkData  - The data object fetched from network.
//  * @returns {DATA_STATUS} - The data status.
//  */
// export function getDataStatus(localData, networkData) {
//   if (!localData) {
//     // No pending data
//     // console.log(localData, networkData, networkData?.Status ?? DATA_STATUS.INCOMPLETE)
//     return networkData?.Status ?? DATA_STATUS.INCOMPLETE;
//   }

//   const networkTimestamp = !!networkData?.LastModified
//     ? dateFromDotNetDateString(networkData.LastModified, {
//         asDate: true,
//         normalise: false,
//       }).getTime()
//     : null;

//     console.log("networkTimestamp", networkTimestamp, networkData?._LastModified.native)

//   if (!networkTimestamp || localData.timestamp > networkTimestamp) {
//     // Pending request is latest data
//     return DATA_STATUS.PENDING;
//   }

//   // Pending data exists but is not the latest
//   // console.log(networkData, networkData?.Status ?? DATA_STATUS.INCOMPLETE)
//   return networkData?.Status ?? DATA_STATUS.INCOMPLETE;
// }

const SchedulePageSkeleton = () => {
  return (
    <>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
    </>
  );
};
