import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { db } from "../db";
import {
  startOfLastXDays,
  dateDiffInDays,
  localDateToSQLDate,
  sqlDateObjectFromServerTZ,
  localDate,
  localDateFromSQL,
  endOfFromDate,
  dateSubtract,
} from "helpers/dateUtilities";
import { isNullEmptyOrWhitespace, parseJSON } from "helpers/stringUtilities";
import {
  setCookie,
  deleteCookie,
  clearBrowserCache,
} from "helpers/storageUtilities";
import cookie from "cookie";
import { getPWAIDfromFormValuesRecord } from "helpers/formsUtilities";
import useDeepCompareEffect, { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { IForm, IListOption, IUser, parseCustomListVariable } from "helpers/formUtilities";
import { IFarm, IFarmHouse } from "helpers/farmUtilities";
import { IMenuItem } from "components/Sidebar";
import { IResponse } from "types";
import { DBFormData, mergeLocalDataWithNetworkData } from "db/FormData";

export interface IConfig {
  [key: string]: any;
}

export interface ISchedule {
  [key: string]: any;
}

export interface IAppDataContext {
  schedules: ISchedule[];
  // user: IUser | undefined;
  // isSignedIn: boolean;
  // onSignIn: (user: IUser) => void;
  // onSignOut: () => void;
  // offline: boolean;
  forms: IForm[];
  pageSubtitle: string;
  setPageSubtitle: (subtitle: string) => void;
  pageTitle: string;
  setPageTitle: (title: string) => void;
  fetchFormValues: (
    farmId: string | undefined,
    houseId: { toString: () => string },
    formParams: { formId: any; formType: any; moduleId: any }[],
    signal: AbortSignal | undefined
  ) => Promise<any[]>;
  dashboards: any[];
  refetchDashboards: () => void;
}

export const AppDataContext = createContext({} as IAppDataContext);

export interface IMenusContext {
  menus: IMenuItem[];
}

export interface IFarmsContext {
  farms: IFarm[];
}

export interface IUserContext {
  user: IUser | undefined;
  isSignedIn: boolean;
  onSignIn: (user: IUser) => void;
  onSignOut: () => void;
  offline: boolean;
}

const MenusContext = createContext({} as IMenusContext);
const FarmsContext = createContext({} as IFarmsContext);
const UserContext = createContext({} as IUserContext);
const AppConfigContext = createContext({} as IConfig);

interface AppDataProviderProps {
  children: React.ReactNode;
}

export function AppDataProvider({ children }: AppDataProviderProps) {
  const appInsights = useAppInsightsContext();

  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(() => {
    const cookies = cookie.parse(document.cookie);
    return cookies.signedIn === "true" ? true : false;
  });
  
  // Farms
  const [farms, setFarms] = useState<IFarm[]>([]);
  const [customListOptions, setCustomListOptions] = useState<{ [key: string]: IListOption[] }>({});


  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [offline, setOffline] = useState<boolean>(false);
  const [menus, setMenus] = useState<IMenuItem[]>([]);
  const [pageSubtitle, setPageSubtitle] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("");
  const [config, setConfig] = useState<IConfig>({});
  const [forms, setForms] = useState<IForm[]>([]);
  const [dashboards, setDashboards] = useState<any>([]);

  //#region callbacks

  const fetchFormValues = useCallback(
    async (
      formId: string,
      formType: string,
      moduleId: string,
      farm: IFarm,
      house: IFarmHouse,
      signal: AbortSignal | undefined
    ) => {
      formId = formId?.toLowerCase();
      formType = formType?.toLowerCase();
      moduleId = moduleId?.toLowerCase();
      let startDate: Date;
      let endDate = endOfFromDate(localDate(), "day");

      if (
        !["production", "weeklyproduction", "swab", "vaccine", "task"].includes(
          formId
        )
      ) {
        startDate = dateSubtract(endDate, 90, "day");
      } else {
        // Production/schedules/weeklyproduction dates are all based off of DatePlaced
        const placement = house.Pens.find(
          (p) => p.PenNumber.toString() === "1"
        )?.Placement;
        if (placement === undefined) {
          throw new Error(
            `No placement found for farm ${farm.FarmCode} house ${house.HouseNumber}`
          );
        }
        let numDays = dateDiffInDays(
          placement._DatePlaced.normalised,
          localDate()
        );

        numDays = numDays > 0 ? Math.floor(numDays) : 0;

        if (numDays > 20) {
          numDays = 20;
        }
        startDate = dateSubtract(endDate, numDays + 1, "day");
      }

      // Get all localData records between start and end dates.
      let localData = await db.formdata
        .where("DateApplies")
        .between(startDate.getTime(), endDate.getTime(), true, true)
        .toArray();

      // Uncomment to debug
      //   localData.filter(
      //     (ld) => {
      //     // prettier-ignore
      //     console.log(
      //       ld,
      //       "matching formId", ld.FormName === formId,
      //       "matching formType", ld.FormType === formType,
      //       "matching farmCode", ld.FarmCode === farm.FarmCode.toLowerCase(),
      //       "matching houseNumber", ld.HouseNumber === house.HouseNumber.toString()
      //     );

      //     return ld.FormName === formId &&
      //     ld.FormType === formType &&
      //     ld.FarmCode === farm.FarmCode.toLowerCase() &&
      //     ld.HouseNumber === house.HouseNumber.toString();
      //   }
      // );

      // unable to chain filter indexedDb when using `between`
      // Lets do it in memory
      localData = localData.filter(
        (ld: any) =>
          ld.FormName === formId &&
          ld.FormType === formType &&
          ld.FarmCode === farm.FarmCode.toLowerCase() &&
          ld.HouseNumber === house.HouseNumber.toString()
      );

      // Sort to ensure localDate is in chronological descending order
      localData.sort(
        (a: { LastModified: number }, b: { LastModified: number }) =>
          b.LastModified - a.LastModified
      );

      // prettier-ignore
      // console.log("localData", formId, localData?.[0]?.DateApplies, "startDate", startDate, startDate.getTime(), "endDate", endDate, endDate.getTime(), localData?.[0]?.DateApplies >= startDate.getTime() && localData?.[0]?.DateApplies <= endDate.getTime());

      let newNetworkData = [];

      const startDateSqlFormat = localDateToSQLDate(startDate);
      const endDateSqlFormat = localDateToSQLDate(endDate);

      try {
        const networkResponse = await fetch(
          `/api/formvalues-get?formId=${formId}&formType=${formType}&moduleId=${moduleId}&farmId=${farm.FarmCode.toLowerCase()}&startDate=${startDateSqlFormat}&endDate=${endDateSqlFormat}`,
          {
            signal,
            method: "GET",
          }
        );
        if (signal?.aborted) return;
        if (!networkResponse.ok) {
          throw new Error(
            `Response was not ok: ${networkResponse.status}. Reason: ${networkResponse.statusText}`
          );
        }
        const networkData = await networkResponse.json();

        // networkResponse contains data for each house...
        // let's filter out houses we aren't interested in.
        const houseFilteredNetworkData = networkData?.filter(
          (fv: { House: { toString: () => string } }) =>
            fv.House.toString() === house.HouseNumber.toString()
        );

        // Create new network data array replacing network data record with more up-to-date local data
        newNetworkData = houseFilteredNetworkData.map((networkEntity: any) => {
          // Add request data back onto response data,
          // useful for identifying form values.
          networkEntity.FormName = formId;
          networkEntity.FormType = formType;
          const dateApplies = localDateFromSQL(
            networkEntity.DateApplies
          ).getTime();
          const networkLastModifiedDate = sqlDateObjectFromServerTZ(
            networkEntity.LastModified
          );

          // Build an array of indices of all entries that match networkEntity in localData
          let localEntityIndices: any[] = [];
          const networkPWAID = getPWAIDfromFormValuesRecord(networkEntity);
          localData.forEach(
            (
              ld: DBFormData,
              index: any
            ) => {
              const localPWAID = ld.ID;

              // prettier-ignore
              // console.log(
              //   "localPWAID", localPWAID, "networkPWAID", networkPWAID, "recordID", ld.Data.ID, "networkEntityID", networkEntity.ID,
              //   "formId", formId, "formType", formType, "farmCode", farm.FarmCode, "houseNumber", house.HouseNumber, "dateApplies", new Date(dateApplies).toLocaleDateString(),
              //   localEntityIndices,
              //   localData?.[localEntityIndices[0]]?.LastModified > networkLastModifiedDate.localised.getTime(),
              //   localData?.[localEntityIndices[0]]?.LastModified > networkLastModifiedDate.localised.getTime()
              // );

              /**
               * - localPWAID: can be undefined | string.
               * - networkPWAID: can be undefined | string.
               * - ld.Data.ID: can be null | string | number.
               * - networkEntity.ID: can be null | string | number.
               * - localPWAID & networkPWAID value comparison should only take place when they both contain an actual value.
               * - ld.Data.ID & networkEntity.ID values should always be compared. The use of ?.toString() will convert null & undefined to undefined,
               *  this is required for comparison against forms with no ID, such as WeeklyProduction.
               */
              if (
            ((!isNullEmptyOrWhitespace(localPWAID) &&
              !isNullEmptyOrWhitespace(networkPWAID) &&
              localPWAID === networkPWAID) ||
              ld.Data.ID?.toString() === networkEntity.ID?.toString()) &&
            ld.FormName.toLowerCase() === formId &&
            ld.FormType.toLowerCase() === formType &&
            ld.FarmCode.toLowerCase() === farm.FarmCode.toLowerCase() &&
            ld.HouseNumber.toString() === house.HouseNumber.toString() &&
            ld.DateApplies === dateApplies
          ) {
            localEntityIndices.push(index);
          }
            }
          );

          // prettier-ignore
          // console.log(
          //   "formId", formId, "formType", formType, "farmCode", farm.FarmCode, "houseNumber", house.HouseNumber, "dateApplies", new Date(dateApplies).toLocaleDateString(),
          //   localEntityIndices,
          //   localData?.[localEntityIndices[0]]?.LastModified > networkLastModifiedDate.localised.getTime(),
          //   localData?.[localEntityIndices[0]]?.LastModified > networkLastModifiedDate.localised.getTime()
          // );

          if (localEntityIndices.length) {
            // Find matching local entity in list
            const matchingLocalEntity = localData[localEntityIndices[0]];
            if (
              matchingLocalEntity.LastModified >
              networkLastModifiedDate.localised.getTime()
            ) {
              // Latest matching entity
              return mergeLocalDataWithNetworkData(
                localData,
                localEntityIndices,
                networkEntity,
                matchingLocalEntity
              );
            }
          }

          // Lastly
          for (var i = localEntityIndices.length - 1; i >= 0; i--) {
            // Delete local entity from local DB
            db.formdata.delete(localData[localEntityIndices[i]].ID);
            // Remove stale matched entites from memory
            localData.splice(localEntityIndices[i], 1);
          }

          return networkEntity;
        });
      } catch (err: any) {
        if (signal?.aborted) return;

        console.error(err.message);

        appInsights.trackException({
          exception: err,
          properties: {
            formId: formId,
            formType: formType,
            moduleId: moduleId,
            farmId: farm?.FarmCode,
            HouseNumber: house?.HouseNumber,
            StartDate: startDateSqlFormat,
            EndDate: endDateSqlFormat,
          },
        });
      } finally {
        // Push remaining local entities into networkData that don't exist on network
        newNetworkData = newNetworkData.concat(
          localData
            .filter(
              (ld: { FormName: string; FormType: string }) =>
                ld.FormName.toLowerCase() === formId &&
                ld.FormType.toLowerCase() === formType
            )
            .map((ld: { Data: any }) => ld.Data)
        );

        // Change all server datetimes to local date object, making comparison easier going forward
        const localisedNetworkData = newNetworkData.map(
          (record: { DateApplies: any; LastModified: string }) => {
            return {
              ...record,
              _DateApplies: sqlDateObjectFromServerTZ(record.DateApplies),
              _LastModified:
                record.LastModified !== "0001-01-01 00:00:00"
                  ? sqlDateObjectFromServerTZ(record.LastModified)
                  : null,
            };
          }
        );

        return localisedNetworkData;
      }
    },
    [appInsights]
  );

  const fetchDashboards = useCallback(async () => {
    if (!isSignedIn || user?.UserName === undefined) {
      setDashboards([]);
      return;
    }

    const abortController = new AbortController();

    let result;

    try {
      // Fetch from web service
      const response = await fetch(`/api/dashboards-get`, {
        signal: abortController.signal,
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      result = await response.json();

      setDashboards(result?.d ?? []);
    } catch (err: any) {
      if (abortController.signal.aborted) return;

      console.error(err);

      appInsights.trackException({
        exception: err,
        properties: {
          User: user.UserName,
        },
      });
    }
  }, [isSignedIn, user?.UserName, appInsights]);

  /**
   * Fetch standards items for each farm house, save to browser indexedDb with expiry
   * @param {string}  farmId  - The farm code
   * @param {number}  houseId  - The house number
   * @returns {null|Promise}
   */
  const updateStandards = useCallback(
    async (
      farmGroup: string,
      birdType: string,
      birdSex: string,
      signal: AbortSignal,
      stdTypes = ""
    ) => {
      if (!farms?.length) return;
      farmGroup = farmGroup?.toLowerCase();
      birdType = birdType?.toLowerCase();
      birdSex = birdSex?.toLowerCase();
      stdTypes = stdTypes?.toLowerCase();

      // TODO temp fix, always refresh standards
      // // Fetch from cache, if exists and NOT expired
      // const existingStandard = await db.standards.get([
      //   farmGroup,
      //   birdType,
      //   birdSex,
      // ]);
      // if (existingStandard && existingStandard.expires > new Date().getTime()) {
      //   // Don't re-fetch, cache still valid
      //   return;
      // }

      // Fetch from web service
      return fetch(
        `/api/standards-get?farmGroup=${farmGroup}&birdType=${birdType}&birdSex=${birdSex}&stdTypes=${stdTypes}`,
        {
          signal,
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (signal?.aborted) return;

          const expires = localDate().getTime() + 86400 * 7 * 1000; // Expires in 1 week
          db.standards.put({
            farmGroup,
            birdType,
            birdSex,
            data,
            expires,
          });
        })
        .catch((error) => {
          if (signal?.aborted) return;
          console.error(error.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [farms]
  );

  /**
   * Fetch farm schedules.
   * @returns {null|Promise}
   */
  const updateSchedules = useCallback(
    async (signal: AbortSignal) => {
      if (!isSignedIn || signal?.aborted) return;

      // Fetch from web service
      return fetch("/api/schedules-get", {
        signal,
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (signal?.aborted || !data?.length) return;

          // Change all server datetimes to local date object, making comparison easier going forward
          const newSchedules = data.map((record: { CompletedDate: any }) => {
            return {
              ...record,
              _CompletedDate: sqlDateObjectFromServerTZ(record.CompletedDate),
            };
          });

          setSchedules(newSchedules);
        })
        .catch((error) => {
          if (signal?.aborted) return;
          console.error(error.message);
        });
    },
    [isSignedIn]
  );

  const fetchAllFormValues = useCallback(
    async (
      farmId: string | undefined,
      houseId: { toString: () => string },
      formParams: { formId: any; formType: any; moduleId: any }[],
      signal: AbortSignal | undefined
    ) => {
      if (!farms?.length || !formParams?.length) return [];

      let fetches: any[] = [];
      formParams.forEach(({ formId, formType, moduleId }) => {
        if (farmId === undefined) {
          throw new Error("farmId is undefined");
        }
        if (formId === undefined) {
          throw new Error("formId is undefined");
        }
        if (moduleId === undefined) {
          throw new Error("moduleId is undefined");
        }

        const _farmId = farmId.toLowerCase();
        const _formId = formId.toLowerCase();
        const _moduleId = moduleId.toString().toLowerCase();
        const _formType = formType?.toLowerCase() ?? "";
        const _houseId = houseId?.toString()?.toLowerCase();

        const farm = farms.find((f) => f.FarmCode.toLowerCase() === _farmId);
        if (farm === undefined) {
          throw new Error(`Farm ${_farmId} not found`);
        }
        const house = farm.Houses.find(
          (h) => h.HouseNumber.toString() === _houseId
        );
        if (house === undefined) {
          throw new Error(`House ${_houseId} not found`);
        }

        // Build array of fetchs
        fetches.push(
          fetchFormValues(
            _formId,
            _formType,
            _moduleId,
            farm,
            house,
            signal
          )
        );
      });

      return Promise.all(fetches);
    },
    [farms, fetchFormValues]
  );

  /**
   * Get user signed in status from 'signedIn' cookie.
   * @returns {boolean} True/false user 'signedIn' cookie exists.
   */
  const updateIsSignedInWithCookieStatus = () => {
    const cookies = cookie.parse(document.cookie);
    const _isSignedInCookie = cookies.signedIn === "true";
    // Careful to only change the state when we need to here
    setIsSignedIn((prevState) =>
      prevState !== _isSignedInCookie ? _isSignedInCookie : prevState
    );
  };

  /**
   * Handle network status change (online/offline)
   * @param {Event} ev
   */
  const handleNetworkChange = (ev: { type: string }) => {
    const online = ev.type === "online" ? true : false;
    setOffline(!online);

    if (online) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.controller?.postMessage({
          type: "REPLAY_REQUESTS",
        });
      }
    }
  };

  //#endregion

  //#region side-effects

  /**
   * Mount/Unmount
   */
  useEffect(() => {
    // Set initial signed in status
    updateIsSignedInWithCookieStatus();

    /**
     * Listen for 'signedIn' cookie expiry
     */
    const loggedInInterval = setInterval(
      updateIsSignedInWithCookieStatus,
      2000
    );

    return () => {
      clearInterval(loggedInInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch form templates
   */
  const isCustomListOptionsLoaded =
    customListOptions?.farms?.[0]?.Id !== undefined &&
    customListOptions?.houseNumbers?.[0]?.Id !== undefined &&
    customListOptions?.flockDates?.[0]?.Id !== undefined &&
    customListOptions?.flockHouses?.[0]?.Id !== undefined;
  useEffect(() => {
    if (!isSignedIn || user?.UserName === undefined || !isCustomListOptionsLoaded) {
      setForms([]);
      return;
    }

    const abortController = new AbortController();

    /**
     * Fetch forms
     */
    (async function fetchFormTemplates() {
      let result;

      try {
        // Fetch from web service
        const response = await fetch(`/api/forms-get`, {
          signal: abortController.signal,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        result = await response.json();
        const newForms = (result?.d ?? []) as IForm[];

        // Convert meta string to JSON
        newForms.forEach((form) => {
          form.FormFields.forEach((field) => {
            // Parse metadata
            const parsedDisplay = parseJSON(field.Display?.toString());

            if (!isNullEmptyOrWhitespace(field.Required)) {
              field.Required = parseJSON(field.Required as string);
            }

            // Set metadata as JSON
            field.Display = parsedDisplay as any;

            // Set custom logic list options
            if (!!field.List) {
              const regex = /\${([^{}]+)}/i;
              const matched = regex.exec(field.List);
              let newListOptions: IListOption[] = [];
              if (matched?.[1] !== undefined) {
                const { ref } = parseCustomListVariable(matched[1]);
                
                if (ref === "farms") {
                  newListOptions = customListOptions.farms;
                } else if (ref === "housenumbers") {
                  newListOptions =  customListOptions.houseNumbers;
                } else if (ref === "farm_flocks") {
                  newListOptions = customListOptions.flockDates;
                } else if (ref === "farm_flock_houses") {
                  newListOptions =  customListOptions.flockHouses;
                }

                // Remove empty list options
                field.ListOptions = newListOptions.filter(
                  (li) =>
                    !isNullEmptyOrWhitespace(li.Id) &&
                    !isNullEmptyOrWhitespace(li.Text) &&
                    !isNullEmptyOrWhitespace(li.Value)
                );
              }
            }
          });
        });

        if (abortController.signal.aborted) return;

        setForms(newForms);
      } catch (err: any) {
        if (abortController.signal.aborted) return;

        console.error(err);

        appInsights.trackException({
          exception: err,
          properties: {
            User: user.UserName,
          },
        });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, user?.UserName, appInsights, isCustomListOptionsLoaded]);

  /**
   * Fetch dashboards
   */
  useEffect(() => {
    fetchDashboards();
  }, [fetchDashboards]);

  /**
   * Fetch config data
   */
  useEffect(() => {
    if (!isSignedIn || user?.UserName === undefined) {
      setConfig({});
      return;
    }

    fetch("/api/config-get", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then((result) => {
        const newConfig = result ?? {};

        setConfig(newConfig);
      })
      .catch((err) => {
        console.error(err);

        appInsights.trackException({
          exception: err,
          properties: {
            User: user.UserName,
          },
        });
      });
  }, [isSignedIn, user?.UserName, appInsights]);

  /**
   * Clear user data on signout
   */
  useEffect(() => {
    if (isSignedIn) return;

    if (user) onSignOut();
  }, [isSignedIn, user]);

  /**
   * Fetch user data
   */
  useDeepCompareEffectNoCheck(() => {
    if (!isSignedIn || user?.UserName !== undefined) {
      // User is signed in & user object exists, don't fetch user data
      return;
    }

    const abortController = new AbortController();

    /**
     * Fetch user data and populate state.
     * @returns {null|Promise}
     */
    const getUserData = async () => {
      try {
        const response = await fetch(`/api/user-get`, {
          signal: abortController.signal,
          method: "GET",
        });
        if (!response.ok) {
          throw new Error(
            `Failed to fetch user data for logged in user. Server responded with status code ${response.status}. Reason: ${response.statusText}.`
          );
        }

        const responseBody = await response.json();

        if (!responseBody.isAuthenticated) {
          throw new Error(responseBody.message);
        }
        setUser(responseBody?.user);
      } catch (err: any) {
        if (abortController.signal.aborted) return;

        if (window?.location?.pathname !== "/500") {
          // User not found, redirect to 500 page
          window.location.href = "/500";
        }

        console.error(err);

        appInsights.trackException({
          exception: err,
        });
      }
    };

    getUserData();
  }, [isSignedIn, user, appInsights]);

  /**
   * Fetch menus data
   */
  useEffect(() => {
    if (!isSignedIn || user?.UserName === undefined) {
      // We need user?.UserName to allow use to filter restricted menus
      setMenus([]);
      return;
    }

    const abortController = new AbortController();

    /**
     * Fetch menus
     */
    const fetchMenus = async (signal: AbortSignal) => {
      return fetch("/api/menus-get", {
        signal,
        method: "GET",
      })
        .then((response) => response.json())
        .then((result) => {
          if (signal?.aborted || result === undefined) return;

          const menus = (result?.d ?? []) as IMenuItem[];

          const isSuperUser = user?.UserName?.toLowerCase() === "unitassup";
          const superUserOnlyViews = ["dashboardbuilder"];

          let newMenus = new Map();
          for (const menu of menus) {
            if (superUserOnlyViews.includes(menu.View) && !isSuperUser) {
              // retrict access to super user only pages
              continue;
            }

            if (menu.Level === "1") {
              // For every child menu, add it to the parent menu
              if (!newMenus.has(menu.ParentID)) {
                // Parent doesn't exist, create it
                const parent = menus.find(
                  (m: { ID: any }) => m.ID === menu.ParentID
                );
                newMenus.set(menu.ParentID, { ...parent, Children: [] });
              }

              newMenus.get(menu.ParentID).Children.push(menu);
            }
          }

          return Array.from(newMenus.values()) ?? [];
        })
        .catch((error) => {
          if (signal?.aborted) return;

          console.error("error", error);
        });
    };

    fetchMenus(abortController.signal).then((newMenus) => {
      if (abortController.signal.aborted) return;

      setMenus(newMenus ?? []);
    });

    return () => {
      abortController.abort();
    };
  }, [isSignedIn, user?.UserName]);

  /**
   * Fetch farms data
   */
  useEffect(() => {
    if (!isSignedIn) return;

    const abortController = new AbortController();
    const signal = abortController.signal;

    /**
     * Fetch user data and populate state.
     * @returns {null|Promise}
     */
    function getFarmsData() {
      return fetch(`/api/farms-get`, {
        signal: signal,
        method: "GET",
      })
        .then((res) => res.json())
        .then((response: IResponse) => {
          if (signal?.aborted) return;

          const responseBody = response?.d ?? [];
          const farms = (typeof responseBody === "string" ? parseJSON(responseBody) : responseBody) as IFarm[];

          // Change all server datetimes to local date object, making comparison easier going forward
          const newFarms = farms.map((record) => {
            return {
              ...record,
              Houses: record?.Houses.map((house) => {
                return {
                  ...house,
                  Pens: house?.Pens.map((pen) => {
                    return {
                      ...pen,
                      Placement: {
                        ...pen?.Placement,
                        _HatchDate: sqlDateObjectFromServerTZ(
                          pen?.Placement?.HatchDate
                        ),
                        _CropDate: sqlDateObjectFromServerTZ(
                          pen?.Placement?.CropDate
                        ),
                        _DatePlaced: sqlDateObjectFromServerTZ(
                          pen?.Placement?.DatePlaced
                        ),
                        _DepopDate: sqlDateObjectFromServerTZ(
                          pen?.Placement?.DepopDate
                        ),
                      },
                    };
                  }),
                };
              }),
            };
          });

          newFarms.sort((a, b) => a.FarmCode.localeCompare(b.FarmCode));

          setFarms(newFarms);

          // Set list options
          const customListOptions = {
            farms: [] as IListOption[],
            houseNumbers: [] as IListOption[],
            flockDates: [] as IListOption[],
            flockHouses: [] as IListOption[],
          };
          for (const farm of farms) {
            customListOptions.farms.push({
              Id: farm.FarmCode.toString(),
              Text: `${farm.FarmCode} - ${farm.FarmName}`,
              Value: farm.FarmCode.toString(),
            });

            for (const house of farm.Houses) {
              customListOptions.houseNumbers.push({
                Id: `${house.HouseNumber}`,
                Text: `House ${house.HouseNumber}`,
                Value: `${house.HouseNumber}`,
                Parent: farm.FarmCode.toString(),
              });
            }

            for (const flockDate of Object.keys(farm.Flocks)) {
              customListOptions.flockDates.push({
                Id: `${farm.FarmCode.toLowerCase()}_${flockDate}`,
                Text: flockDate.toString(),
                Value: flockDate.toString(),
                Parent: farm.FarmCode.toString(),
              });
            }

            for (const [flockDate, flocks] of Object.entries(farm.Flocks)) {
              for (const flock of flocks) {
                customListOptions.flockHouses.push({
                  Id: `${farm.FarmCode.toLowerCase()}_${flockDate}_${flock.HouseNumber.toString()}`,
                  Text: flock.HouseLabel.toString(),
                  Value: flock.HouseNumber.toString(),
                  Parent: [farm.FarmCode, flockDate],
                });
              }
            }
          }
          
          setCustomListOptions(customListOptions);
        })
        .catch((error) => {
          if (signal?.aborted) return;

          console.error(error);
        });
    }

    getFarmsData();
  }, [isSignedIn]);

  /**
   * Fetch standards
   */
  useEffect(() => {
    if (!farms?.length || !isSignedIn || !updateStandards) return;

    // Build array of standards request data
    const standardsRequestData: {
      FarmGroup: string;
      BirdType: string;
      BirdSex: string;
    }[] = [];
    farms.forEach((farm) => {
      farm.Houses.forEach((house) => {
        house.Pens.forEach((pen) => {
          if (pen.Placement?.BirdType) {
            standardsRequestData.push({
              FarmGroup: farm.FarmGroup,
              BirdType: pen.Placement.BirdType,
              BirdSex: pen.Placement.BirdSex,
            });
          }
        });
      });
    });

    // Remove duplicates
    const uniqueStandards = standardsRequestData.reduce(
      (
        acc: any[],
        standard: {
          FarmGroup: string;
          BirdType: string;
          BirdSex: string;
        }
      ) => {
        const hasStandard = !!acc.find(
          (uniqueStandard: any) =>
            uniqueStandard.FarmGroup === standard.FarmGroup &&
            uniqueStandard.BirdType === standard.BirdType &&
            uniqueStandard.BirdSex === standard.BirdSex
        );

        if (!hasStandard) {
          return [...acc, standard];
        }

        return acc;
      },
      []
    );

    const abortController = new AbortController();

    // Fetch standards data
    uniqueStandards.forEach((standard) =>
      updateStandards(
        standard.FarmGroup,
        standard.BirdType,
        standard.BirdSex,
        abortController.signal
      )
    );

    return () => {
      abortController.abort();
    };
  }, [farms, isSignedIn, updateStandards]);

  /**
   * Prefetch form values
   */
  useDeepCompareEffect(() => {
    if (!farms?.length || !isSignedIn || !fetchAllFormValues) return;
    if (farms.length > 1) return; // We don't know which farm the user wants so don't prefetch

    function getDateRange(datePlaced: Date) {
      var numDays = dateDiffInDays(datePlaced, localDate());
      if (numDays > 20) {
        numDays = 20;
      }

      return startOfLastXDays(numDays + 1); // +1 to allow fetching previous days data
    }

    function getAllFormIds() {
      return forms
        .filter((f) => f.HasFormValues)
        .map((form) => ({
          formId: form.FormName,
          formType: form.FormType,
          moduleId: form.ModuleName,
        }));
    }
    const formIds = getAllFormIds();

    // Build array of farms request data
    const data = [];
    const requestLimit = 1;
    farmloop: for (let i = 0; i < farms.length; i++) {
      for (let j = 0; j < farms[i].Houses.length; j++) {
        if (data.length >= requestLimit) break farmloop;

        const farm = farms[i];
        const house = farm.Houses[j];
        const pen1 = house.Pens.find((p) => p.PenNumber.toString() === "1");
        if (!house.Pens?.length || !pen1?.Placement?._DatePlaced?.normalised)
          continue;

        // Get start and end dates
        const dates = getDateRange(pen1.Placement._DatePlaced.normalised);
        if (!dates?.length) continue;

        const startDate = localDateToSQLDate(dates[dates.length - 1]); // YYYY-mm-dd format expected
        const endDate = localDateToSQLDate(dates[0]); // YYYY-mm-dd format expected

        data.push({
          farmCode: farm.FarmCode,
          houseNumber: house.HouseNumber,
          startDate,
          endDate,
        });
      }
    }

    const abortController = new AbortController();

    // Fetch form value data
    data.forEach((item) => {
      fetchAllFormValues(
        item.farmCode,
        item.houseNumber.toString(),
        formIds,
        abortController.signal
      );
    });

    return () => {
      abortController.abort();
    };
  }, [farms, isSignedIn, fetchAllFormValues, forms]);

  /**
   * Fetch schedules
   */
  useEffect(() => {
    if (!updateSchedules) return;

    const abortController = new AbortController();

    updateSchedules(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [updateSchedules]);

  /**
   * Set online/offline status
   */
  useEffect(() => {
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);
    // set initial state
    setOffline(!window.navigator.onLine);
    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  const onSignIn = (user: IUser) => {
    setIsSignedIn(true);
    setCookie("signedIn", "true", { expires: 365 }); // 1 year
  };

  /**
   * Triggered on sign out
   */
  const onSignOut = () => {
    clearBrowserCache();
    setIsSignedIn(false);
    deleteCookie("signedIn");
    setUser(undefined);
    setCustomListOptions({});
    setFarms([]);
    setSchedules([]);
    setMenus([]);
    setConfig({});
    setForms([]);
    setDashboards([]);
  };

  return (
    <AppDataContext.Provider
      value={{
        schedules,
        // user,
        // isSignedIn,
        // onSignIn,
        // onSignOut,
        // offline,
        fetchFormValues: fetchAllFormValues,
        forms,
        pageSubtitle,
        setPageSubtitle,
        pageTitle,
        setPageTitle,
        dashboards,
        refetchDashboards: fetchDashboards,
      }}
    >
      <AppConfigContext.Provider value={{ config, setConfig }}>
        <UserContext.Provider value={{ user, isSignedIn, onSignIn, onSignOut, offline }}>
          <FarmsContext.Provider value={{ farms }}>
            <MenusContext.Provider value={{ menus }}>
              {children}
            </MenusContext.Provider>
          </FarmsContext.Provider>
        </UserContext.Provider>
      </AppConfigContext.Provider>
    </AppDataContext.Provider>
  );
}

export const useMenus = () => {
  const context = useContext(MenusContext);

  if (!context) {
    throw new Error("useMenus must be used within a AppDataProvider");
  }

  return context;
};

export const useFarms = () => {
  const context = useContext(FarmsContext);

  if (!context) {
    throw new Error("useFarms must be used within a AppDataProvider");
  }

  return context;
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a AppDataProvider");
  }

  return context;
}

export const useAppConfig = () => {
  const context = useContext(AppConfigContext);

  if (!context) {
    throw new Error("useAppConfig must be used within a AppDataProvider");
  }

  return context;
}