import Card, { CardTitle } from "components/Card";
import Dashboard from "components/Dashboard";
import RecentVisits from "components/RecentVisits";
import UsefulLinks from "components/UsefulLinks";
import { AppDataContext, useFarms, useUser } from "context/AppDataProvider";
import { filterUserDashboardsByMenuId } from "helpers/dashboardUtilities";
import { useActiveMenu } from "hooks/useActiveMenu";
import { useContext, useEffect, useMemo, useState } from "react";

interface HomeProps {
  className?: string;
}

export default function Home({ className }: HomeProps) {
  const { setPageTitle, dashboards } = useContext(AppDataContext);
  const { user } = useUser();
  const { farms } = useFarms();
  const { activeMenu } = useActiveMenu();

  const [quoteOfTheDay, setQuoteOfTheDay] = useState<string>("");

  /**
   * Mount/unmount
   */
  useEffect(() => {
    function getInspirationalQuote() {
      const quotes = [
        '"Doing little things well is a step toward doing big things better." - Vincent Van Gogh',
        '"It has never been more important to control what is within our control." - Alan Gibson',
        '"Success is no accident." - Chris McCoubrey',
        '"The real risk is doing nothing." - Denis Waitley',
        '"Success isn\'t always about greatness. It\'s about consistency." - A. Wallace',
      ];
    
      return quotes[Math.floor(Math.random() * quotes.length)];
    }

    setQuoteOfTheDay(getInspirationalQuote());
  }, []);

  const dashboard = useMemo(() => {
    if (activeMenu?.ID === undefined) {
      return undefined;
    }

    const result = filterUserDashboardsByMenuId(
      dashboards,
      user,
      farms,
      activeMenu?.ID
    );

    return result?.length > 0 ? result[0] : undefined;
  }, [dashboards, farms, activeMenu?.ID, user]);

  /**
   * Set page title
   */
  useEffect(() => {
    if (user?.FullName !== undefined) {
      setPageTitle(`Welcome ${user.FullName}!`);
    }
  }, [setPageTitle, user?.FullName]);

  return (
    <main className="flex flex-col flex-grow p-4">
      <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2">
        <div className="col-span-full">
          <Card>
            <div className="text-2xl font-medium mb-2">Welcome{user?.FullName !== undefined ? ` ${user.FullName}!` : "..."}</div>
            <p className="text-gray-600 text-sm">{quoteOfTheDay}</p>
          </Card>
        </div>

        <div>
          <Card>
            <CardTitle>Useful links</CardTitle>
            <UsefulLinks key="useful-links" className="-mx-4" />
          </Card>
        </div>

        <div>
          <Card>
            <CardTitle>Recent visits</CardTitle>
            <RecentVisits key="recent-visits" className="-mx-4" />
          </Card>
        </div>

        {!!dashboard && <div className="col-span-full">
          <Dashboard dashboard={dashboard} />
        </div>}
      </div>
    </main>
  );
}
