import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import {
  isNull,
  isNullEmptyOrWhitespace,
  sortByRelevance,
} from "helpers/stringUtilities";
import { Input } from "./Input";
import { List, ListItem } from "components/core";
import useClickOutside from "hooks/useClickOutside";
import useCalcTrigger from "hooks/useCalcTrigger";

const SuggestionsListComponent = (props) => {
  if (props.showSuggestions) {
    if (props.userInput && props.filteredSuggestions.length) {
      return (
        <List
          theme="striped"
          size="small"
          className="absolute bg-white border-gray-200 shadow-md rounded-md border w-48 overflow-hidden z-20"
        >
          {props.filteredSuggestions.map((suggestion, index) => {
            return (
              <ListItem
                // className={`${className} px-2 py-1`}
                isSelected={index === props.activeSuggestion}
                key={`list-item-${suggestion.Id}`}
                onClick={(ev) => props.onClick(suggestion)}
                showClickIcon={false}
              >
                {suggestion.Text}
              </ListItem>
            );
          })}
        </List>
      );
    }

    return (
      <div className="border border-transparent text-xs text-gray-500">
        No suggestions available.
      </div>
    );
  }

  return null;
};

const Placeholder = (props) => {
  if (!props.showSuggestions || isNullEmptyOrWhitespace(props.userInput))
    return null;

  return (
    <div className="absolute inset-0 pointer-events-none p-3 opacity-20">
      {props.placeholder}
    </div>
  );
};

const AutoComplete = (props) => {
  useCalcTrigger(props.value, props.setValue);

  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(undefined);
  const [placeholder, setPlaceholder] = useState("");
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);

  const inputElement = useRef(undefined);

  useClickOutside([inputElement], onBlur);

  //#region Callbacks

  /**
   * Find matching suggestion by value property
   */
  const findMatchingSuggestionByValue = useCallback(
    (value) => {
      if (
        isNullEmptyOrWhitespace(props.suggestions) ||
        isNullEmptyOrWhitespace(value)
      )
        return;

      return props.suggestions.find(
        (s) => s.Value.toString() === value.toString()
      );
    },
    [props.suggestions]
  );

  /**
   * Find matching suggestion by text property
   */
  const findMatchingSuggestionByText = useCallback(
    (text) => {
      if (
        isNullEmptyOrWhitespace(props.suggestions) ||
        isNullEmptyOrWhitespace(text)
      )
        return;

      return props.suggestions.find(
        (s) => s.Text.toString() === text.toString()
      );
    },
    [props.suggestions]
  );

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      props.setValue(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //#endregion

  //#region Side-effects

  /**
   * Set user input from saved value
   */
  useEffect(() => {
    const matchingSuggestion = findMatchingSuggestionByValue(props.value);

    if (!isNullEmptyOrWhitespace(matchingSuggestion))
      setUserInput(matchingSuggestion.Text);
  }, [props.value, props.suggestions, findMatchingSuggestionByValue]);

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (touched || !props.defaultValue || !setInputValue) return;

    if (isNullEmptyOrWhitespace(props.value)) {
      setInputValue(props.defaultValue);
    }
  }, [touched, setInputValue, props.defaultValue, props.value]);

  /**
   * Set placeholder
   */
  useEffect(() => {
    if (
      isNullEmptyOrWhitespace(filteredSuggestions) ||
      isNullEmptyOrWhitespace(filteredSuggestions[activeSuggestion])
    ) {
      setPlaceholder("");
      return;
    }

    setPlaceholder(filteredSuggestions[activeSuggestion].Text);
  }, [activeSuggestion, filteredSuggestions]);

  //#endregion

  //#region Event handlers

  /**
   * Handle on change event
   * @param {Event} ev
   */
  const onChange = (ev) => {
    const { suggestions } = props;
    if (isNull(suggestions))
      throw new Error("Suggestions cannot be null or undefined.");
    const userInput = ev.currentTarget.value;

    // First, filter
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.Text.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    // Then, sort by relevance
    const relevanceSuggestions = sortByRelevance(
      filteredSuggestions,
      userInput,
      ["Text"]
    );

    setActiveSuggestion(0);
    setFilteredSuggestions(relevanceSuggestions);
    setShowSuggestions(true);
    setUserInput(ev.currentTarget.value);
  };

  /**
   * Handle on click event
   * @param {{ Id: Number, Text: String|Number, Value: String|Number}}
   */
  const onClick = (option, index) => {
    setUserInput(option.Text);
    props.setValue(option.Value);
    setShowSuggestions(false);
    setPlaceholder("");
  };

  function onBlur() {
    if (!focused) return; // Do nothing if not in focus

    const matchingSuggestion = findMatchingSuggestionByText(userInput);
    if (!isNullEmptyOrWhitespace(matchingSuggestion)) {
      // Set to matched suggestion
      setUserInput(matchingSuggestion.Text);
      props.setValue(matchingSuggestion.Value);
    } else if (!isNullEmptyOrWhitespace(userInput)) {
      // Set to first matched element
      setUserInput(filteredSuggestions[activeSuggestion]?.Text ?? "");
      props.setValue(filteredSuggestions[activeSuggestion]?.Value ?? null);
    } else {
      // Clear values
      setUserInput("");
      props.setValue(null);
    }

    // Reset state
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setPlaceholder("");
    setFocused(false);
    setTouched(true);
  }

  /**
   * Handle key down event
   * @param {Event} ev
   */
  const onKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      // User pressed the Return key, set input to active suggestion
      ev.preventDefault();

      setUserInput(filteredSuggestions[activeSuggestion]?.Text ?? "");
      props.setValue(filteredSuggestions[activeSuggestion]?.Value ?? null);
      setActiveSuggestion(0);
      setShowSuggestions(false);
    } else if (ev.keyCode === 38) {
      // User pressed the up arrow, decrement the index
      ev.preventDefault();

      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion((prevState) => prevState - 1);
    } else if (ev.keyCode === 40) {
      // User pressed the down arrow, increment the index
      ev.preventDefault();

      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion((prevState) => prevState + 1);
    }
  };

  //#endregion

  return (
    <div ref={inputElement} onFocus={() => setFocused(true)}>
      <div className="relative">
        <Input
          id={props.id}
          label={props.label}
          labelPosition={props.labelPosition}
          onKeyDown={onKeyDown}
          onChange={onChange}
          value={userInput}
          required={props.required}
          disableCalcTrigger={true} // Prevent triggering calculation parser again
        />
        <Placeholder
          userInput={userInput}
          showSuggestions={showSuggestions}
          placeholder={placeholder}
        />
      </div>
      <SuggestionsListComponent
        filteredSuggestions={filteredSuggestions}
        activeSuggestion={activeSuggestion}
        onClick={onClick}
        showSuggestions={showSuggestions}
        userInput={userInput}
      />
    </div>
  );
};

AutoComplete.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  setValue: PropTypes.func,
};

export { AutoComplete };
