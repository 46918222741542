import { Button, Input } from "components/core";
import { IChart } from "../types";

interface IEditChartColorsFormProps {
  chart: IChart;
  onClickSave: () => void;
  onChangeSettings: (key: string, value: any) => void;
}

const numOfColorFields = 5;

export default function EditChartColorsForm(props: IEditChartColorsFormProps) {
  function onChangeColor(index: number, color: string) {
    const colors = [...props.chart.settings.colors];
    colors[index] = color;
    props.onChangeSettings("colors", colors);
  }

  return (
    <div className="grid grid-cols-1 gap-6 py-4">
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-full">
            {Array.from(Array(numOfColorFields)).map((_, index) => (
              <div key={`color_${index}`} className="py-2">
                <Input
                  id="colors"
                  key="colors"
                  type="color"
                  label={`Color ${index + 1}`}
                  labelPosition="inset"
                  value={props.chart?.settings?.colors?.[index]}
                  setValue={(value: string) => onChangeColor(index, value)}
                  required={true}
                  disableCalcTrigger={true}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <Button
          type="button"
          size="small"
          theme="primary"
          onClick={() => props.onClickSave()}
        >
          Apply &amp; close
        </Button>
      </div>
    </div>
  );
}
