import { PlusCircleIcon } from "assets/icons";
import { MultiSelect, Select, Input, Label } from "components/core";
import { IFormValue } from "helpers/formUtilities";
import {
  IChartKeyOperand,
  IChartKeyOperandValue,
  IMetric,
  NUMERIC_OPERATORS,
} from "../types";
import OperandSummary from "./OperandSummary";

export interface IOperandsFormProps {
  selectedMetricTitle: string;
  metrics: IMetric[];
  selectedOperand: IChartKeyOperand;
  onChangeSelectedOperandValue: (key: string, value: any) => void;
}

export function OperandsForm({
  selectedMetricTitle,
  metrics,
  selectedOperand,
  onChangeSelectedOperandValue,
}: IOperandsFormProps) {
  const {
    operator: selectedOperator,
    values: selectedValues,
    color: selectedColor,
    tolerance: selectedTolerance,
    toleranceUnit: selectedToleranceUnit,
  } = selectedOperand;

  function onChangeOperandValue(index: number, value: IChartKeyOperandValue) {
    const newValues = (selectedValues ?? []).map((item) => item);
    newValues[index] = value;
    onChangeSelectedOperandValue("values", newValues);
  }

  const requiredNumOfValues =
    NUMERIC_OPERATORS[selectedOperator]?.requiredNumOfValues;

  // prettier-ignore
  const isConditionalOperator = [">=", "<=", ">", "<", "between", "=="].includes(selectedOperator);

  return (
    <div className="grid tablet:grid-cols-12 gap-2 items-center">
      <div className="col-span-full tablet:col-span-3">
        <Select
          id="metric-operator-select"
          key={`metric-operator-${selectedOperator}-select`}
          label="Operator"
          labelPosition="inset"
          value={selectedOperator}
          placeholder={undefined}
          setValue={(value) => {
            onChangeSelectedOperandValue("operator", value);
          }}
          listOptions={Object.entries(NUMERIC_OPERATORS).map(
            ([key, value]) => ({
              Id: key,
              Text: value.title,
              Value: key,
            })
          )}
          disableCalcTrigger={true}
          required={selectedOperand !== undefined}
        />
      </div>

      {selectedValues.length ? (
        <>
          {selectedValues.map(({ value, title, type }, index) =>
            type === "metric" ? (
              <div className="col-span-full tablet:col-span-3">
                <MultiSelect
                  id={`compare-metric-select-${index}`}
                  key={`compare-metric-select-${index}`}
                  label="Compare to metric..."
                  labelPosition="inset"
                  setValue={(value) => {
                    onChangeOperandValue(index, {
                      value: value.toString(),
                      title:
                        metrics.find((item) => item.id === value)?.title ??
                        "unknown",
                      type: "metric",
                    });
                  }}
                  value={value}
                  listOptions={metrics.map((item) => ({
                    Id: item.id,
                    Text: item.title,
                    Value: item.id,
                  }))}
                  showSearch={true}
                  // sortable={true}
                  limit={1}
                  required={true}
                  disableCalcTrigger={true}
                />
              </div>
            ) : (
              <div className="col-span-full tablet:col-span-3">
                <Input
                  id="compare-input"
                  key={`compare-value-input`}
                  label="Value"
                  labelPosition="inset"
                  value={value}
                  setValue={(value: IFormValue["Value"]) => {
                    const stringValue = value.toString();
                    onChangeOperandValue(index, {
                      value: stringValue,
                      title: stringValue,
                      type: "value",
                    });
                  }}
                  type="number"
                  required={true}
                  disableCalcTrigger={true}
                />
              </div>
            )
          )}

          {selectedValues.length === requiredNumOfValues &&
          isConditionalOperator ? (
            <>
              <div className="col-span-full tablet:col-span-4">
                <ValueAdjustmentInput 
                  selectedTolerance={selectedTolerance} 
                  selectedToleranceUnit={selectedToleranceUnit}
                  onChangeSelectedOperandValue={onChangeSelectedOperandValue}
                />
              </div>
              <div className="col-span-full tablet:col-span-2">
                <Input
                  id="colors"
                  key={`compare-color-input`}
                  type="color"
                  label="Colour"
                  labelPosition="inset"
                  value={selectedColor}
                  setValue={(value: IFormValue["Value"]) => {
                    onChangeSelectedOperandValue("color", value);
                  }}
                  disableCalcTrigger={true}
                  list="color-list"
                />
                <datalist id="color-list">
                  <option value="#16a34a" />
                  <option value="#dc2626" />
                  <option value="#ca8a04" />
                </datalist>
              </div>
            </>
          ) : null}

          <OperandSummary
            className="col-span-full text-xs text-gray-500"
            title="Hint: "
            operand={{
              operator: selectedOperator,
              tolerance: selectedTolerance,
              toleranceUnit: selectedToleranceUnit,
              values: selectedValues,
              color: selectedColor,
            }}
            chartKey={{
              id: "hint",
              title: selectedMetricTitle,
            }}
          />
        </>
      ) : null}

      {selectedValues?.length < requiredNumOfValues ? (
        <ButtonAddMetricValue
          onChangeOperandValue={onChangeOperandValue}
          selectedValues={selectedValues}
        />
      ) : null}
    </div>
  );
}

export interface IButtonAddMetricValueProps {
  onChangeOperandValue: (index: number, value: IChartKeyOperandValue) => void;
  selectedValues: IChartKeyOperandValue[];
}

function ButtonAddMetricValue({
  onChangeOperandValue,
  selectedValues,
}: IButtonAddMetricValueProps) {
  return (
    <div className="col-span-6 text-center inline-flex space-x-2">
      <div
        className="text-xs text-gray-500 hover:text-primary cursor-pointer inline-flex items-center"
        onClick={() => {
          onChangeOperandValue(selectedValues.length, {
            value: "",
            title: "",
            type: "metric",
          });
        }}
      >
        <PlusCircleIcon className="w-4 h-4 mr-1" />
        Add metric value
      </div>
      <div>or</div>
      <div
        className="text-xs text-gray-500 hover:text-primary cursor-pointer inline-flex items-center"
        onClick={() => {
          onChangeOperandValue(selectedValues.length, {
            value: "",
            title: "",
            type: "value",
          });
        }}
      >
        <PlusCircleIcon className="w-4 h-4 mr-1" />
        Add static value
      </div>
    </div>
  );
}

interface IValueAdjustmentInputProps {
  selectedTolerance?: number;
  selectedToleranceUnit?: string;
  onChangeSelectedOperandValue: (key: string, value: string) => void;
}

function ValueAdjustmentInput({ 
  selectedTolerance, 
  selectedToleranceUnit,
  onChangeSelectedOperandValue,
}: IValueAdjustmentInputProps) {
  return (
    <div className="relative mt-1 rounded-md shadow-sm">
      <Label
        id="tolerance-label"
        text="Value adj. (+)"
        required={false}
        position="inset"
        focused={undefined}
        hasValue={undefined}
        addonPosition={undefined}
        theme={undefined}
        erroneous={undefined}
        labelClasses={undefined}
      />
      <input
        id="tolerance-input"
        name="tolerance-input"
        type="number"
        className="block w-full tablet:text-sm rounded-md border focus:ring-4 focus:ring-offset-0  py-3 bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
        placeholder="0"
        value={selectedTolerance}
        onChange={(e) => {
          onChangeSelectedOperandValue("tolerance", e.target.value || "");
        }}
      />
      <div className="absolute inset-y-0 right-0 flex items-center">
        <label htmlFor="toleranceunit-select" className="sr-only">
          Tolerance unit
        </label>
        <select
          id="toleranceunit-select"
          name="toleranceunit-select"
          className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={selectedToleranceUnit}
          onChange={(e) => {
            onChangeSelectedOperandValue("toleranceUnit", e.target.value);
          }}
        >
          <option value="number">INT</option>
          <option value="percentage">%</option>
        </select>
      </div>
    </div>
  );
}
