import React from "react";

export function EditCircleIcon({ ...other }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M14.3487 2.27969C13.5858 2.09533 12.7978 2 12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C4.00035 5.85752 3.26375 6.95991 2.7612 8.17317C2.25866 9.38642 2 10.6868 2 12C2 13.3132 2.25866 14.6136 2.7612 15.8268C3.26375 17.0401 4.00035 18.1425 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 11.0151 21.8547 10.0451 21.576 9.11879L19.7375 9.96732C19.9103 10.6253 20 11.308 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C10.9494 20 9.90914 19.7931 8.93853 19.391C7.96793 18.989 7.08602 18.3997 6.34315 17.6569C5.60028 16.914 5.011 16.0321 4.60896 15.0615C4.20693 14.0909 4 13.0506 4 12C4 10.9494 4.20693 9.90914 4.60896 8.93853C5.011 7.96793 5.60028 7.08601 6.34315 6.34315C7.08601 5.60028 7.96793 5.011 8.93853 4.60896C9.90914 4.20693 10.9494 4 12 4C12.6103 4 13.2134 4.06973 13.7986 4.20479L14.3487 2.27969Z"
        stroke="none"
        fill="currentColor"
      />
      <path
        d="M17.586 3.586C17.7705 3.39498 17.9912 3.24262 18.2352 3.1378C18.4792 3.03298 18.7416 2.97781 19.0072 2.9755C19.2728 2.97319 19.5361 3.0238 19.7819 3.12436C20.0277 3.22492 20.251 3.37342 20.4388 3.56121C20.6266 3.749 20.7751 3.9723 20.8756 4.21809C20.9762 4.46388 21.0268 4.72724 21.0245 4.9928C21.0222 5.25836 20.967 5.5208 20.8622 5.76481C20.7574 6.00882 20.605 6.22951 20.414 6.414L11.828 15H9V12.172L17.586 3.586Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
