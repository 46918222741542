import { isNull } from "./stringUtilities";
import lodashCloneDeep from "lodash.clonedeep";

/**
 * Group array of objects by property
 * @param {Object[]} objectArray
 * @param {String} property
 * @returns { Object.<string, []>} Dictionary of grouped objects
 *
 * @example
 * const arr = [{ name: "Daniel", age: 20 }, { name: "John", age: 20 }, { name: "Paul", age: 22 }}]
 * const grouped = groupObjectsFieldsBy(arr, "age")
 * console.log(Object.entries(grouped).map(([key, _arr]) => `People aged ${key}: ${_arr.join(", ")}`))
 */
export function groupObjectsFieldsBy(objectArray, property) {
  if (!objectArray) return objectArray;

  return objectArray.reduce((acc, obj) => {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = { fields: [] };
    }
    acc[key].fields.push(obj);
    return acc;
  }, {});
}

/**
 * Group array of objects by property
 * @param {Object[]} objectArray
 * @param {String} property
 * @returns { Object.<string, []>} Dictionary of grouped objects
 *
 * @example
 * const arr = [{ name: "Daniel", age: 20 }, { name: "John", age: 20 }, { name: "Paul", age: 22 }}]
 * const grouped = groupObjectsBy(arr, "age")
 * console.log(Object.entries(grouped).map(([key, _arr]) => `People aged ${key}: ${_arr.join(", ")}`))
 */
export function groupObjectsBy(objectArray, property) {
  if (!objectArray) return objectArray;

  return objectArray.reduce((acc, obj) => {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export function mergeObjectsByProperty(array, property) {
  if (isNull(array)) return [];
  const newArray = new Map();

  array.forEach((item) => {
    const propertyValue = Object.entries(item).find(([key, value]) =>
      key.endsWith(`:${property}`)
    )[1];
    // Add in merged 'property' key
    const newItem = { ...item, [property]: propertyValue };
    // Remove duplicate 'property' keys
    for (const key in item) {
      if (key.endsWith(`:${property}`)) {
        delete item[key];
      }
    }
    newArray.has(propertyValue.value)
      ? newArray.set(propertyValue.value, {
          ...newItem,
          ...newArray.get(propertyValue.value),
        })
      : newArray.set(propertyValue.value, newItem);
  });

  return Array.from(newArray.values());
}

export function deepClone(items) {
  return lodashCloneDeep(items);
}
