import React from "react";

export default function Error404() {
  return (
    <div className="flex flex-col flex-grow justify-center py-4 px-4 tablet:text-center">
      <h1 className="text-3xl mb-3">404 Error</h1>
      <p className="mb-3">
        Opps... the page you are looking for was not found.
      </p>
      <p className="text-sm">
        Click the browser{" "}
        <em>
          <strong>back</strong>
        </em>{" "}
        button to return to the previous page, or the{" "}
        <em>
          <strong>navbar logo</strong>
        </em>{" "}
        to return back to the homepage.
      </p>
    </div>
  );
}
