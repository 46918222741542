import { Input, MultiSelect, Select } from "components/core";
import { IChartKeyEvent, IMetric } from "../types";

export interface IEventsFormProps {
  metrics: IMetric[];
  selectedEvent: IChartKeyEvent;
  onChangeSelectedEvent: (key: string, value: any) => void;
}

export function EventsForm({
  metrics,
  selectedEvent,
  onChangeSelectedEvent,
}: IEventsFormProps) {

  return (
    <div className="grid grid-cols-12 gap-2 items-center">
      <div className="col-span-4">
        <Select
          id="event-type-select"
          key={`event-type-select`}
          label="Type"
          labelPosition="inset"
          value={selectedEvent.type}
          placeholder={undefined}
          setValue={(value) => {
            onChangeSelectedEvent("type", value);
          }}
          listOptions={["onclick"].map(
            (value) => ({
              Id: value,
              Text: value,
              Value: value,
            })
          )}
          disableCalcTrigger={true}
          required={true}
        />
      </div>
      <div className="col-span-4">
        <Select
          id="event-func-select"
          key={`event-func-select`}
          label="Type"
          labelPosition="inset"
          value={selectedEvent.func}
          placeholder={undefined}
          setValue={(value) => {
            onChangeSelectedEvent("func", value);
          }}
          listOptions={["fetchRelated", "navigateTo"].map(
            (value) => ({
              Id: value,
              Text: value,
              Value: value,
            })
          )}
          disableCalcTrigger={true}
          required={true}
        />
      </div>
      <div className="col-span-4">
        <MultiSelect
          id={`event-args-select-`}
          key={`event-args-select-`}
          label="Arguments"
          labelPosition="inset"
          setValue={(value) => {
            onChangeSelectedEvent("args", value?.toString()?.split(",") ?? value);
          }}
          value={selectedEvent.args?.join(",")}
          listOptions={metrics.map((item) => ({
            Id: item.id,
            Text: item.title,
            Value: item.id,
          }))}
          showSearch={true}
          required={true}
          disableCalcTrigger={true}
        />
      </div>
      <div className="col-span-full">
        <Input
          id="event-title-input"
          key={`event-title-input`}
          label="Text"
          labelPosition="inset"
          // eslint-disable-next-line no-template-curly-in-string
          hint="Argument(s) can be used `${argument name}` to reference the selected metric(s) in the text."
          value={selectedEvent.title}
          placeholder="`FetchRelated` title... `NavigateTo` url path..."
          setValue={(value: string) => {
            onChangeSelectedEvent("title", value);
          }}
          disableCalcTrigger={true}
          required={true}
        />
      </div>
    </div>
  );
}