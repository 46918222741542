import { PlusCircleIcon } from "assets/icons";
import { Button } from "components/core";

const ButtonNew = ({ onClick, className, options, ...other }) => {

  return (
    <Button
      theme="primary"
      className={(className, "group")}
      onClick={() =>
        options?.length === 1 && onClick(options[0].FormType.toLowerCase(), options[0].FormName.toLowerCase())
      }
      options={
        options &&
        options.map((o) => ({
          id: o.FormName.toLowerCase(),
          text: o.FormTitle,
          onClick: () => onClick(o.FormType.toLowerCase(), o.FormName.toLowerCase()),
        }))
      }
      // shape="pill"
      showMoreOptionsIcon={false}
      icon={<PlusCircleIcon className="w-6 h-6 mr-2 text-white/50 group-hover:text-white" />}
      {...other}
    >
      New
    </Button>
  );
}

export default ButtonNew;