import React, { useState } from 'react'

export interface ISidebarContext {
  sidebarOpen: boolean,
  setSidebarOpen: (open: boolean) => void,
}

interface ISidebarProviderProps {
  children: React.ReactNode
}

const SidebarContext = React.createContext({} as ISidebarContext);

export default function SidebarProvider({ children }: ISidebarProviderProps) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <SidebarContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  )
}

export function useSidebar() {
  const context = React.useContext(SidebarContext);

  if (context === undefined) {
    throw new Error('useSidebar must be used within an AppDataProvider')
  }

  return context;
}