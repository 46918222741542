import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "..";
import classNames from "classnames";

export interface ModalProps {
  open: boolean;
  onOpen?: (open: boolean) => void;
  children: React.ReactNode;
  title: string | React.ReactNode;
  onSave?: () => void;
  showCancel?: boolean;
  className?: string;
}

export function Modal({
  open = false,
  onOpen,
  children,
  title,
  onSave,
  showCancel = true,
  className,
  ...other
}: ModalProps) {
  function handleOpen(open: boolean) {
    if (onOpen !== undefined) {
      onOpen(open);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={handleOpen}
      >
        <div className="flex items-start justify-center min-h-screen pt-56 px-4 text-center tablet:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden tablet:inline-block tablet:align-middle tablet:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
            enterTo="opacity-100 translate-y-0 tablet:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 tablet:scale-100"
            leaveTo="opacity-0 translate-y-4 tablet:translate-y-0 tablet:scale-95"
          >
            <div className={classNames("p-4 mt-4 inline-block align-bottom bg-white rounded-lg shadow transform transition-all tablet:my-8 tablet:align-middle desktop:max-w-4xl w-full", className)}>
              <div className="text-left">
                {!!title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 text-center"
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div>{children}</div>
              </div>
              {(showCancel || onSave) && (
                <div className="flex space-x-2">
                  {!!showCancel && (
                    <Button className="grow" onClick={() => handleOpen(false)}>Cancel</Button>
                  )}
                  {!!onSave && (
                    <Button className="grow" theme="primary" onClick={onSave}>
                      Save
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
