import React from "react";
import { InitialsAvatarSkeleton } from "../Avatars/InitialsAvatarSkeleton";

export default function ListItemSkeleton({
  showAvatar = true,
  size,
  className = "",
  ...other
}) {
  const classesArr = [className];

  // Size
  if (size === "small") {
    classesArr.push("px-4 py-2");
  } else {
    classesArr.push("px-4 py-4");
  }

  return (
    <li className={classesArr.join(" ")} {...other}>
      <div className="flex items-center">
        <div className="min-w-0 flex-1 flex items-center">
          {showAvatar && <InitialsAvatarSkeleton className="mr-3" />}
          <div className="flex-1">
            <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
            <div className="mt-1 h-2 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    </li>
  );
}
