import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useClickOutside(refs, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      let isOutside = false;
      if (refs instanceof Array) {
        isOutside = refs.every((ref) => {
          const _ref = ref?.current === undefined ? { current: ref } : ref;
          if (_ref?.current && !_ref.current.contains(event.target)) {
            return true;
          }

          return false;
        });
      } else {
        const ref = refs?.current === undefined ? { current: refs } : refs;
        // state as ref
        if (ref?.current && !ref.current.contains(event.target)) {
          isOutside = true;
        }
      }

      if (isOutside) {
        if (callback) callback(event);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, callback]);
}
