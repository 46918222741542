import React from "react";

export function InitialsAvatar({ initials, ...other }) {
  if (!initials) return null;

  return (
    <div {...other}>
      <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gradient-to-r from-primary via-primary-dark to-secondary">
        <span className="font-medium leading-none text-white uppercase text-sm text-center">{initials}</span>
      </span>
    </div>
  );
}
