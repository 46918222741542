import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";
import ReactDOM from "react-dom";
import classNames from "classnames";

/**
 *
 * @param {{[key:string]:any}} props
 * @returns
 */
function FlyoutMenu({
  buttonContent,
  buttonClassNames,
  buttonAs = "button",
  children,
  placement,
  ...other
}) {
  let [referenceElement, setReferenceElement] = useState(undefined);
  let [popperElement, setPopperElement] = useState(undefined);
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
  });

  return (
    <Popover {...other}>
      {({ open }) => (
        <>
          <Popover.Button
            key="popoverButton"
            as={buttonAs}
            ref={setReferenceElement}
            className={classNames("flex flex-1", buttonClassNames)}
          >
            {!!buttonContent && buttonContent({ open })}
          </Popover.Button>
          {ReactDOM.createPortal(
            <Popover.Panel
              ref={(ref) => setPopperElement(ref)}
              style={{ ...styles.popper, zIndex: 9999 }}
              className="mt-3 px-2 w-screen max-w-xs sm:px-0"
              {...attributes.popper}
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  {children}
                </div>
              </div>
            </Popover.Panel>,
            document.querySelector("#root")
          )}
        </>
      )}
    </Popover>
  );
}

FlyoutMenu.propTypes = {
  buttonContent: PropTypes.func,
  buttonAs: PropTypes.string,
  children: PropTypes.node,
  placement: PropTypes.oneOf([
    "top-end",
    "top-start",
    "top",
    "right-end",
    "right-start",
    "right",
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    undefined,
  ]),
};

export { FlyoutMenu };
