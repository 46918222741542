import React from "react";
import classNames from "classnames";
import Card from "./Card";

interface IPageHeaderProps {
  className?: string;
  children?: React.ReactNode;
}

export default function PageHeader({
  children = undefined,
  className = "",
}: IPageHeaderProps) {
  return (
    <header className={classNames(className)}>
      <Card>{children}</Card>
    </header>
  );
}
