import {
  Alert,
} from "components/core";
import PageHeader from "components/PageHeader";
import { AppDataContext, useUser } from "context/AppDataProvider";
import {
  IForm,
} from "helpers/formUtilities";
import { useContext, useMemo, useState } from "react";
import { useActiveMenu } from "hooks/useActiveMenu";
import ReportFormSelector from "components/report_form/ReportFormSelector";
import ReportForm from "components/report_form/ReportForm";

export default function ReportFormPage() {
  const { activeMenu } = useActiveMenu();
  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;

  const { offline } = useUser();
  const { forms } = useContext(AppDataContext) as any;

  const [selectedFormId, setSelectedFormId] = useState<string>("");

  const filteredForms = useMemo<IForm[]>(
    () =>
      forms.filter(
        (f: IForm) =>
          f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
          f.ModuleName?.toLowerCase() === module?.toLowerCase()
      ),
    [forms, moduleFeatureGroup, module]
  );

  if (offline) {
    return (
      <div className="flex-grow overflow-x-hidden">
        <div className="relative z-20">
          <PageHeader className="py-6 px-4 sm:px-6 lg:px-8">
            <div className="text-xl">Reports</div>
          </PageHeader>
        </div>
        <main className="flex flex-col flex-grow">
          <div className="flex flex-col flex-grow w-full max-w-7xl mx-auto py-6 px-6 desktop:px-8">
            <Alert theme="warning" className="mb-6">
              You are offline. Please connect to the internet to download
              reports.
            </Alert>
          </div>
        </main>
      </div>
    );
  }

  function handleSetReportId(id: string) {
    setSelectedFormId(id);
  }

  return (
    <div className="flex-grow overflow-x-hidden">
      <div className="relative z-20">
        {/* <Breadcrumb showHome={false} menuRequired={false} farmRequired={true} /> */}
        <PageHeader className="py-6 px-4 sm:px-6 lg:px-8">
          <div className="text-xl">Reports</div>
        </PageHeader>
      </div>
      <main className="flex flex-col flex-grow">
        <div className="flex flex-col flex-grow w-full max-w-7xl mx-auto py-6 px-6 desktop:px-8">
          <div className="space-y-4">
            <ReportFormSelector forms={filteredForms} selectedFormId={selectedFormId} onSelectReportId={handleSetReportId} />
            <ReportForm key={selectedFormId} forms={filteredForms} selectedFormId={selectedFormId} />
          </div>
        </div>
      </main>
    </div>
  );
}
