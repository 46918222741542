import React, { useState, createContext, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

export interface INotificationContext {
  notifications: INotification[];
  addNotification: (notification: INotification) => void;
  removeNotification: (id: string) => void;
  clearNotifications: () => void;
}

interface INotification {
  id?: string;
  key?: string;
  title: string;
  description?: string;
  icon?: React.ReactNode;
  theme?: "success" | "error" | "warning";
  onClose?: () => void;
  className?: string;
}

interface INotificationProviderProps {
  children: React.ReactNode;
}

const NotificationContext = createContext({} as INotificationContext);

export function NotificationProvider({ children }: INotificationProviderProps) {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const addNotification = useCallback((notification: INotification) => {
    const key = !notification.key ? uuidv4() : notification.key; // generate unique id
    setNotifications(prevState => [...prevState, { ...notification, key }]);
  }, []);

  const removeNotification = useCallback((id: string) => {
    setNotifications(prevState => [
      ...prevState.filter((notification) => notification.id !== id),
    ]);
  }, []);

  function clearNotifications() {
    if (!notifications || notifications.length < 1) return;

    setNotifications([]);
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
        clearNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = React.useContext(NotificationContext);

  if (context === undefined) {
    throw new Error("useNotifications must be used within a NotificationProvider");
  }

  return context;
}
