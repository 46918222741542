import React from "react";

export default function BreadcrumbItemSkeleton({ showDivider }) {
  return (
    <li className="flex whitespace-nowrap animate-pulse">
      <div className="flex items-center">
        <div className="space-y-2 mx-4 py-1 text-left">
          <div className="h-4 w-16 bg-gray-300 rounded"></div>
          <div className="h-2 w-24 bg-gray-300 rounded"></div>
        </div>
        {showDivider && (
          <svg
            className="shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
        )}
      </div>
    </li>
  );
}
