import { ImageGallery } from "components/core/Forms/Upload";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { useAppConfig } from "context/AppDataProvider";
import { useNotifications } from "context/NotificationProvider";
import {
  fetchMediaByID,
  getAbsoluteUrl,
  getMimeTypeFromUrl,
} from "helpers/mediaUtilities";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "components/Card";
import { Badge, Button } from "components/core";
import { convertHexToRGBA, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { IMediaItem } from "pages/media/ListPage";

interface Props {
  setPageTitle: (title: string | React.ReactNode) => void;
}

export default function MediaPreview({ setPageTitle }: Props) {
  const { id } = useParams();
  const { addNotification } = useNotifications();
  const { config } = useAppConfig();
  const navigate = useNavigate();

  const [data, setData] = useState<IMediaItem | undefined>(undefined);

  const abortControllerRef = useRef<AbortController | undefined>(undefined);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    if (id === undefined) {
      return;
    }

    fetchMediaByID(id, abortControllerRef.current.signal)
      .then((data) => {
        setData(data?.d?.[0] ?? null);
        setPageTitle(`Previewing - ${data?.d?.[0]?.filename}` ?? null);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
        addNotification({
          title: "Error",
          theme: "error",
          description: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function handleClickBack(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();

    navigate(-1);
  }

  if (data === undefined) {
    return <FieldsetSkeleton />;
  }

  return (
    <Card>
      <div className="grid grid-cols-1 gap-4">
        <ImageGallery
          className="col-span-full"
          images={[
            {
              url: getAbsoluteUrl(data.url, config.mediaUrl),
              filetype: getMimeTypeFromUrl(getAbsoluteUrl(data.url, config.mediaUrl)),
            },
          ]}
        />
        <Field label="Farm" value={data.farmname} />
        <Field label="Flock" value={data.flock} />
        <Field label="Name" value={data.filename} />
        <Field label="Description" value={data.description} />
        <Field label="Tag(s)" value={data?.tagsdata?.map((tag) => (
          <Badge
            icon={undefined}
            iconPosition={undefined}
            style={{
              backgroundColor:
                tag?.Color !== undefined
                  ? convertHexToRGBA(tag.Color, 0.05)
                  : null,
              color: tag?.Color !== undefined ? tag.Color : null,
              borderColor:
                tag?.Color !== undefined ? tag.Color : null,
            }}
          >
            {tag.Title}
          </Badge>
        ))} />
        <Field label="Created" value={data.createdat} />
        <Field label="Modified" value={data.modifiedat} />
        <div className="col-span-full">
          <Button
            className="justify-self-start"
            type="button"
            onClick={handleClickBack}
          >
            Back
          </Button>
        </div>
      </div>
    </Card>
  );
}

export function Field({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactNode;
}) {
  if (isNullEmptyOrWhitespace(value)) {
    return null;
  }

  return (
    <div className="col-span-full text-sm">
      <div className="font-medium">{label}</div>
      <div>{value}</div>
    </div>
  );
}
