import { useActiveMenu } from "hooks/useActiveMenu";
import { useParams } from "react-router-dom";
import DashboardBuilderPage from "pages/dashboards/builder";
import AuditReportPage from "pages/farms/AuditReportPage";
import FormsPage from "pages/farms/FormsPage";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import MediaUploadPage from "pages/media/FormsPage";

export function FormPage() {
  const { activeMenu } = useActiveMenu();
  const { view, id } = useParams();
  const activeMenuView = activeMenu?.View?.toLowerCase();
  const _view = view?.toLowerCase();

  if (activeMenuView === "dashboardbuilder") {
    return <DashboardBuilderPage />;
  }

  if (_view === "report" && id !== undefined) {
    return <AuditReportPage />;
  }

  if (activeMenuView === "medialist") {
    return <MediaUploadPage />;
  }

  if (activeMenuView === undefined) {
    // Page not yet loaded
    return <ListSkeleton />;
  }

  return <FormsPage />;
}
