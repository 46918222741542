import {
  CheckCircleIcon,
  PlusCircleIcon,
  ExclamationCircleIcon,
} from "assets/icons";
import { DATA_STATUS } from "constants.js";
import { dateDiffInDays } from "helpers/dateUtilities";

export default function ListItemIcon({ dataStatus, date, dateToday }) {
  if (
    dataStatus === DATA_STATUS.PENDING_INCOMPLETE ||
    dataStatus === DATA_STATUS.PENDING_COMPLETE
  ) {
    return <PlusCircleIcon className="w-10 h-10 mr-2 text-warning-300" />;
  } else if (dataStatus === DATA_STATUS.COMPLETE) {
    return <CheckCircleIcon className="w-10 h-10 mr-2 text-success-400" />;
  } else if (dateDiffInDays(date, dateToday) >= 1) {
    return <ExclamationCircleIcon className="w-10 h-10 mr-2 text-danger-400" />;
  } else {
    return <PlusCircleIcon className="w-10 h-10 mr-2 text-gray-300" />;
  }
}
